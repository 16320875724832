import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history }) {
  useEffect(() => {
    const routeChangeListner = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      routeChangeListner();
    };
  }, []);

  return null;
}

ScrollToTop.propTypes = {
  history: PropTypes.object
};

export default withRouter(ScrollToTop);
