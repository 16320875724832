import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { REPORT_TYPES } from 'src/utils/constants';
import ReportFilters from 'src/components/reports/ReportFilters';
import FilterSelectInput from 'src/common/FilterSelectInput';
import { getReportConfig } from 'src/components/reports/reportService';
import ScreenHeader from 'src/common/ScreenHeader';
import OtherReportsTable from 'src/components/otherReports/OtherReportsTable';
import { addOtherReportData } from 'src/redux/reportSlice';
import { useDispatch, useSelector } from 'react-redux';

const OtherReports = () => {
  const dispatch = useDispatch();
  const { otherReportList } = useSelector((state) => state.reports);
  const [reportName, setReportName] = useState(REPORT_TYPES[0].id);
  const [reportConfig, setReportConfig] = useState(null);

  useEffect(() => {
    const config = getReportConfig(reportName);
    setReportConfig(config);
  }, [reportName]);

  useEffect(() => () => {
    dispatch(addOtherReportData([])); // clear report data
  }, []);

  const handleGroupChange = (value) => {
    setReportName(value);
    if (otherReportList.length > 0) {
      dispatch(addOtherReportData([])); // clear report data
    }
  };

  return (
    <>
      <ScreenHeader title="Other Reports" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            hideAction
            showSearch={false}
            showGroup={false}
          >
            <FilterSelectInput
              sx={{ width: { sm: '33%', xs: '100%' }, }}
              label="Select Report Type"
              onInputChange={handleGroupChange}
              data={REPORT_TYPES}
              value={reportName}
            />
          </FMSToolbar>
          <Grid container spacing={3} sx={{ mt: 1 }}>
            <Grid item xs={12} sm={12}>
              {reportConfig && (
              <ReportFilters
                title={reportName}
                datePickerProps={reportConfig.dateConfig}
                isViewReport={reportConfig.viewReport}
                filterConfig={reportConfig.filterOptions}
                formModel={reportConfig.formConfig?.formModel}
                formValidation={reportConfig.formConfig?.validation}
                formInitValues={reportConfig.formConfig?.initialValues}
                downloadUrl={reportConfig.downloadUrl}
              />
              )}
            </Grid>
            <Grid item xs={12} sm={12}>
              {reportConfig?.viewReport && <OtherReportsTable />}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default OtherReports;
