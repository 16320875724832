import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allDrivers: [],
  totalCount: 0,
  driverList: [],
  driverDetails: {},
  loading: false,
};

export const getDriverList = createAsyncThunk(
  'driver/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getDriverList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreDrivers = createAsyncThunk(
  'driver/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getDriverList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllDrivers = createAsyncThunk(
  'driver/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllDrivers();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDriverDetails = createAsyncThunk(
  'driver/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getDriverDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateDriver = createAsyncThunk(
  'driver/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateDriverDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addDriver = createAsyncThunk(
  'driver/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createDriver(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatDriverData = (data) => {
  const formattedList = data.map((driver) => ({ ...driver, name: `${driver.first_name} ${driver.last_name}` }));
  return formattedList;
};

export const driverSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllDrivers.fulfilled, (state, action) => ({ ...state, allDrivers: formatDriverData(action.payload) }))
      .addCase(getDriverList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getDriverList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getDriverList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        driverList: formatDriverData(action.payload.results)
      }))
      .addCase(loadMoreDrivers.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreDrivers.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreDrivers.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        driverList: [...state.driverList, ...formatDriverData(action.payload.results)]
      }))
      .addCase(getDriverDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        driverDetails: action.payload
      }))
      .addCase(getDriverDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getDriverDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateDriver.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        driverDetails: action.payload,
        driverList: updateItemInArray(state.driverList, action.payload)
      }))
      .addCase(addDriver.fulfilled, (state, action) => {
        state.driverList.push(action.payload);
      });
  }
});

export default driverSlice.reducer;
