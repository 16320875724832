import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField
} from '@mui/material';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { X as CancelIcon } from 'react-feather';
import { DATE_FORMAT } from 'src/utils/constants';

function DateFilterField({
  label = 'Filter by Date', value, onDateChange, hideClose = false,
  onlyDate = true, onClearFilter, pickerProps, ...rest
}) {
  const PickerComponent = onlyDate ? DatePicker : DateTimePicker;
  const displayFormat = onlyDate ? DATE_FORMAT.date : DATE_FORMAT.dateTime;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <PickerComponent
        label={label}
        value={value}
        inputFormat={displayFormat}
        disableFuture
        onChange={onDateChange}
        {...pickerProps}
        renderInput={(params) => (
          <TextField
            {...params}
            {...rest}
            sx={{ mr: 3 }}
            onKeyDown={(e) => e.preventDefault()}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {params.InputProps.endAdornment?.props?.children}
                  {value && !hideClose && (
                    <IconButton onClick={onClearFilter} sx={{ ml: 1 }}>
                      <SvgIcon fontSize="small" color="action">
                        <CancelIcon />
                      </SvgIcon>
                    </IconButton>
                  )}
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}

DateFilterField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onlyDate: PropTypes.bool,
  hideClose: PropTypes.bool,
  onDateChange: PropTypes.func,
  onClearFilter: PropTypes.func,
  pickerProps: PropTypes.object
};

export default DateFilterField;
