import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { getTankDetails, updateTank } from 'src/redux/tankSlice';
import { CONFIRMATION_MSG } from 'src/utils/constants';
import InfoField, { GridRow } from 'src/common/InfoField';
import ROUTE from 'src/utils/routeConstants';
import useAuth from 'src/context/AuthContext';
import { getFormattedDate, getFuelQuantity, isAdminUser } from 'src/utils/getInitials';
import NewUser from './NewTank';

function TankDetails() {
  const { tankId } = useParams();
  const history = useHistory();
  const { getUser } = useAuth();
  const isAdmin = isAdminUser(getUser);
  const { loading, tankDetails } = useSelector((state) => state.tanks);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getTankDetails(tankId)).unwrap();
  }, []);

  const updateTankDetails = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setShowConfirmModal(false);
  };
  const onStatusChange = () => {
    if (isAdmin) {
      setShowConfirmModal(true);
    }
  };

  const updateStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const values = { ...tankDetails, automation: !tankDetails.automation };
      await dispatch(updateTank(values)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Tank status updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <Container maxWidth="lg">
      <NewUser
        open={openModal}
        initialValues={tankDetails}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading || isLoading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Tank Details"
                action={(
                  <Button variant="contained" onClick={() => history.replace(ROUTE.tanks)}>
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField name="Tank Name" value={tankDetails.name} />
                  <InfoField name="Tank Code" value={tankDetails.code} />
                  <InfoField name="Site Name" value={tankDetails.site_name} />
                </Grid>
                <GridRow>
                  <InfoField name="Created On" value={getFormattedDate(tankDetails.created_at)} />
                  <InfoField name="Last updated On" value={getFormattedDate(tankDetails.update_at)} />
                  <Box sx={{ width: '35%' }}>
                    <Typography color="textSecondary" variant="body1">
                      Automation Enabled
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={tankDetails.automation}
                            value={tankDetails.automation}
                            color={tankDetails.automation ? 'success' : 'default'}
                          />
                        )}
                        onChange={onStatusChange}
                        label={tankDetails.automation ? 'Enabled' : 'Disabled'}
                      />
                    </FormGroup>
                  </Box>
                </GridRow>
                <GridRow>
                  <InfoField name="Tank Type" value={tankDetails.type} />
                  <InfoField name="Fuel Type" value={tankDetails.article} />
                  <InfoField name="Sensor ID" value={tankDetails.sensor_id} />
                </GridRow>
                <GridRow>
                  <InfoField name="Max Level" value={`${tankDetails.max_level} mm`} />
                  <InfoField name="Min Level" value={`${tankDetails.min_level} mm`} />
                  <InfoField name="Current Level" value={`${tankDetails.current_level} mm`} />
                </GridRow>
                <GridRow>
                  <InfoField name="Maximum volume" value={getFuelQuantity(tankDetails.max_volume)} />
                  <InfoField name="Minimum volume" value={getFuelQuantity(tankDetails.min_volume)} />
                  <InfoField name="Current volume" value={getFuelQuantity(tankDetails.current_balance)} />
                </GridRow>
                <GridRow>
                  <InfoField name="Water Level" value={`${tankDetails.water_level} mm`} />
                  <InfoField name="Density" value={`${tankDetails.density} kg/m3`} />
                  <InfoField name="Temperature" value={`${tankDetails.temp} ℃`} />
                </GridRow>
                <GridRow>
                  <InfoField name="Alarm" value={tankDetails.alarm} />
                  <InfoField name="Dispenser's Connected" value={tankDetails.dispenser_ids ? tankDetails.dispenser_ids.length : 0} />
                  {/* <InfoField
                    name="Total operators"
                    value={tankDetails.operator_ids?.length || 0}
                  /> */}
                </GridRow>
              </CardContent>
              <CardActions>
                {isAdmin && (
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={updateTankDetails}
                >
                  Update Details
                </Button>
                )}
              </CardActions>
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        info={tankDetails.automation ? CONFIRMATION_MSG.disableAutomation : CONFIRMATION_MSG.enableAutomation}
        open={showConfirmModal}
        disable={tankDetails.automation}
        onClose={onModalClose}
        onSubmit={updateStatus}
      />
    </Container>
  );
}

export default TankDetails;
