import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';

export default function MeterConfigForm(props) {
  const {
    formField: {
      kfactor,
      articleUnit,
      articlePrice,
      article,
      pulseType,
      pulseDirection,
      throttleVolume,
      throttleStage,
      minFlowRate,
      maxFlowRate,
    }
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={article.name}
          label={article.label}
          data={article.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={articleUnit.name} label={articleUnit.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={articlePrice.name} label={articlePrice.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={pulseType.name}
          label={pulseType.label}
          data={pulseType.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={pulseDirection.name}
          label={pulseDirection.label}
          data={pulseDirection.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={throttleVolume.name} label={throttleVolume.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={throttleStage.name}
          label={throttleStage.label}
          data={throttleStage.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={minFlowRate.name} label={minFlowRate.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={maxFlowRate.name} label={maxFlowRate.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={kfactor.name} label={kfactor.label} fullWidth />
      </Grid>
    </Grid>
  );
}

MeterConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
