import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  TablePagination
} from '@mui/material';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { vehicleUtilizationTableHeaders, vehicleIgnitionTableHeaders } from 'src/utils/tableHeaders';
import useDidUpdate from 'src/hooks/useDidUpdate';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';
import { formatNumber } from 'src/utils/getInitials';
import { VEHICLE_LIST_TYPES } from '../dashboard/chartService';

const VehicleStatusListTable = ({
  chartName, vehicleData, refreshData, searchText, ...rest
}) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('registration_num');
  const [vehicleList, setVehicleList] = useState(vehicleData);
  const tableHeader = chartName === VEHICLE_LIST_TYPES.ignition ? vehicleIgnitionTableHeaders : vehicleUtilizationTableHeaders;

  useDidUpdate(() => {
    const filteredList = vehicleData.filter((vehicle) => vehicle.registration_num.toLowerCase().includes(searchText.toLowerCase()));
    setVehicleList(filteredList);
  }, [searchText]);

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getStatusColor = (status) => {
    let chipColor = 'success';
    if (status === 'Idle') {
      chipColor = 'warning';
    } else if (status === 'Breakdown') {
      chipColor = 'error';
    }
    return chipColor;
  };

  return (
    <Card {...rest}>
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              headCells={tableHeader}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {vehicleList
                .slice()
                .sort(getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((vehicle) => (
                  <TableRow hover key={vehicle.id}>
                    <TableCell>
                      <Box sx={{ alignItems: 'center', display: 'flex' }}>
                        <Typography color="textPrimary" variant="body1">
                          {vehicle.registration_num}
                        </Typography>
                      </Box>
                    </TableCell>
                    {chartName === VEHICLE_LIST_TYPES.utilization
                      ? (
                        <>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${formatNumber(vehicle.total_worked_hour)} Hr`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${formatNumber(vehicle.monthly_fixed_engine_hours)} Hr`}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography color="textPrimary" variant="body1">
                              {`${formatNumber(vehicle.utilisation)} %`}
                            </Typography>
                          </TableCell>
                        </>
                      ) : (
                        <TableCell>
                          <Chip
                            label={vehicle.status}
                            color={getStatusColor(vehicle.status)}
                            variant="outlined"
                          />
                        </TableCell>
                      )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        showFirstButton
        showLastButton
        count={vehicleList.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      />
    </Card>
  );
};

VehicleStatusListTable.propTypes = {
  chartName: PropTypes.string.isRequired,
  vehicleData: PropTypes.array.isRequired,
  refreshData: PropTypes.bool,
  searchText: PropTypes.string
};

export default VehicleStatusListTable;
