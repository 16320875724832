import { CONSUMER_VEHICLE_TYPES } from 'src/utils/constants';
import * as Yup from 'yup';

// report form model
export const reportFormModel = {
  formId: 'reportConfig',
  formField: {
    site: {
      name: 'site',
      label: 'Select Site',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    address: {
      name: 'address',
      label: 'Select Dispenser',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    vehicleGroup: {
      name: 'vehicle_group',
      label: 'Select Vehicle Group',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Select Vehicle',
      type: 'select',
      data: [{ id: 'all', name: 'All', registration_num: 'All' }]
    },
    startTime: {
      name: 'start_time__gte',
      label: 'Start Date',
      type: 'date',
      errorMsg: 'Start time is required!'
    },
    endTime: {
      name: 'start_time__lte',
      label: 'End Date',
      type: 'date',
      errorMsg: 'End time is required!'
    },
    assetType: {
      name: 'asset_type',
      label: 'Select Asset Type',
      type: 'select',
      data: [{ id: 'all', name: 'All' }, ...CONSUMER_VEHICLE_TYPES]
    },
  }
};

// Initial values
const {
  formField: {
    site,
    address,
    vehicleGroup,
    vehicleNumber,
    startTime,
    endTime,
    assetType,
  }
} = reportFormModel;

export const reportFormInitialValues = {
  [site.name]: 'all',
  [address.name]: 'all',
  [vehicleGroup.name]: 'all',
  [vehicleNumber.name]: 'all',
  [assetType.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const reportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});

export const reportFormConfig = {
  formModel: reportFormModel,
  initialValues: reportFormInitialValues,
  validation: reportValidationSchema,
};
