import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { GROUP_TYPES } from 'src/utils/constants';
import { addNewVehicle, updateVehicleDetails } from 'src/redux/vehicleSlice';
import { getAllSites } from 'src/redux/siteSlice';
import { getGroupList } from 'src/redux/groupSlice';
import { SwitchInput } from 'src/common/FormElements';
import InputField from 'src/common/formFields/InputField';
import FMSModal from 'src/common/FMSModal';
import SelectField from 'src/common/formFields/SelectField';
import { getAllTanks } from 'src/redux/tankSlice';
import {
  vehicleFormModel,
  vehicleFormInitialValues,
  vehicleValidationSchema
} from './vehicleFormModel';

const NewVehicle = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [vehicleGroupList, setVehicleGroupList] = useState([]);
  const { allSites } = useSelector((state) => state.sites);
  const { allTanks } = useSelector((state) => state.tanks);
  const formInitValues = initialValues || vehicleFormInitialValues;
  const {
    formId,
    formField: {
      vehicleGroup,
      site,
      department,
      vehicleCategory,
      transferTank,
      registrationNum,
      vehicleType,
      assetCode,
      assetType,
      tankCapacity,
      telemeticsEnabled,
      telemeticsCategory,
      serviceInterval,
      vehicleModel,
      fuelType,
      maxDiffAllowed,
      odometerReading,
      monthlyFixedEngineHours
    }
  } = vehicleFormModel;

  useEffect(async () => {
    if (open) {
      dispatch(getAllSites());
      dispatch(getAllTanks());
      const departments = await dispatch(
        getGroupList({ groupType: GROUP_TYPES[1].id })
      ).unwrap();
      setDepartmentList(departments);
      const vehicleGroups = await dispatch(
        getGroupList({ groupType: GROUP_TYPES[0].id })
      ).unwrap();
      setVehicleGroupList(vehicleGroups);
    }
  }, [open]);

  const onSubmit = async (values, actions) => {
    const formValues = { ...values };
    if (!values.telemetics_enabled) {
      formValues.telemetics_category = '';
    }
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateVehicleDetails(formValues)).unwrap();
      } else {
        await dispatch(addNewVehicle(formValues)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Vehicle details updated successfully!'
            : 'New vehicle has been created successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        {initialValues ? 'Update Vehicle Details' : 'Add New Vehicle'}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={vehicleValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={registrationNum.name}
                        label={registrationNum.label}
                          // upperCase
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={vehicleType.name}
                        label={vehicleType.label}
                        data={vehicleType.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={assetCode.name}
                        label={assetCode.label}
                        upperCase
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={tankCapacity.name}
                        label={tankCapacity.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={vehicleCategory.name}
                        label={vehicleCategory.label}
                        data={vehicleCategory.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={transferTank.name}
                        label={transferTank.label}
                        data={allTanks}
                        disabled={values[vehicleCategory.name] !== 'TRNSFR'}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={vehicleModel.name}
                        label={vehicleModel.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={site.name}
                        label={site.label}
                        data={allSites}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={fuelType.name}
                        label={fuelType.label}
                        data={fuelType.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={vehicleGroup.name}
                        label={vehicleGroup.label}
                        data={vehicleGroupList}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={department.name}
                        label={department.label}
                        data={departmentList}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SwitchInput
                        name={telemeticsEnabled.name}
                        label={telemeticsEnabled.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={telemeticsCategory.name}
                        label={telemeticsCategory.label}
                        data={telemeticsCategory.data}
                        disabled={!values[telemeticsEnabled.name]}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={odometerReading.name}
                        label={odometerReading.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={serviceInterval.name}
                        label={serviceInterval.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={maxDiffAllowed.name}
                        label={maxDiffAllowed.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={assetType.name}
                        label={assetType.label}
                        data={assetType.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={monthlyFixedEngineHours.name}
                        label={monthlyFixedEngineHours.label}
                        data={monthlyFixedEngineHours.data}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {initialValues ? 'Update Vehicle' : 'Add Vehicle'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

NewVehicle.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewVehicle;
