import { CONSUMER_VEHICLE_TYPES } from 'src/utils/constants';
import * as Yup from 'yup';

// report form model
export const breakdownReportFormModel = {
  formId: 'breakdownReportConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Select Vehicle',
      type: 'select',
      data: [{ id: 'all', name: 'All', registration_num: 'All' }]
    },
    assetType: {
      name: 'type',
      label: 'Select Asset Type',
      type: 'select',
      data: [{ id: 'all', name: 'All' }, ...CONSUMER_VEHICLE_TYPES]
    },
    startTime: {
      name: 'start_time__gte',
      label: 'Start Date',
      errorMsg: 'Start date is required!',
      type: 'date'
    },
    endTime: {
      name: 'start_time__lte',
      label: 'End Date',
      errorMsg: 'End date is required!',
      type: 'date'
    }
  }
};

// Initial values
const {
  formField: {
    vehicleNumber,
    assetType,
    startTime,
    endTime
  }
} = breakdownReportFormModel;

export const breakdownReportFormInitialValues = {
  [vehicleNumber.name]: 'all',
  [assetType.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const breakdownReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});

export const breakdownReportFormConfig = {
  formModel: breakdownReportFormModel,
  initialValues: breakdownReportFormInitialValues,
  validation: breakdownReportValidationSchema,
};
