import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { DATE_FORMAT, REPORT_FILTER_OPTIONS } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSites } from 'src/redux/siteSlice';
import ReportFilterModal from 'src/components/reports/ReportFilterModal';
import ReconciliationReportTable from 'src/components/reports/ReconciliationReportTable';
import {
  reconciliationReportFormModel,
  reconciliationReportFormInitialValues,
  reconciliationReportValidationSchema
} from 'src/components/reports/reconciliationReportFormModel';
import API_URL from 'src/api/apiConstants';
import DateFilterField from 'src/common/formFields/DateFilterField';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import ScreenHeader from 'src/common/ScreenHeader';

const ReconciliationReport = ({ location }) => {
  const dispatch = useDispatch();
  const { allSites } = useSelector((state) => state.sites);
  const [searchText, setSearchText] = useState('');
  const [selectedSite, setSelectedSite] = useState('all');
  const [openReportModal, setOpenReportModal] = useState(false);
  const [filterDate, setFilterDate] = useState(null);

  const downloadUrl = `${API_URL.reconciliationReport}?export=true`;
  const filterOptions = { ...REPORT_FILTER_OPTIONS, vehicle: false, vehicleGroup: false };

  const handleSearch = (str) => setSearchText(str);

  useEffect(() => {
    if (location.state?.fromDashboard) {
      dispatch(getAllSites());
    }
  }, []);

  const handleSiteChange = (site) => setSelectedSite(site);

  const handleExport = () => setOpenReportModal(true);
  const onReportModalClose = () => setOpenReportModal(false);

  const filterByDate = (newValue) => {
    const dateString = newValue ? newValue.format(DATE_FORMAT.unixDate) : undefined;
    setFilterDate(dateString);
  };

  const clearDateFilter = () => setFilterDate(null);

  return (
    <>
      <ScreenHeader title="Reconciliation" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Dispenser name"
            actionName="Export"
            actionIcon={<DownloadIcon />}
            onAction={handleExport}
            onSearch={handleSearch}
            showGroup
            groupData={[{ id: 'all', name: 'All' }, ...allSites]}
            groupType={selectedSite}
            groupLabel="Filter by Site"
            onGroupChange={handleSiteChange}
          >
            <DateFilterField value={filterDate} onDateChange={filterByDate} onClearFilter={clearDateFilter} />
          </FMSToolbar>
          <ReportFilterModal
            open={openReportModal}
            title="Reconciliation Report"
            filterConfig={filterOptions}
            formModel={reconciliationReportFormModel}
            onlyDate
            formValidation={reconciliationReportValidationSchema}
            formInitValues={reconciliationReportFormInitialValues}
            downloadUrl={downloadUrl}
            handleClose={onReportModalClose}
          />
          <Box sx={{ pt: 3 }}>
            <ReconciliationReportTable
              refreshData={location.state?.fromDashboard}
              searchText={searchText}
              siteFilter={selectedSite}
              dateFilter={filterDate}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

ReconciliationReport.propTypes = {
  location: PropTypes.object
};

export default ReconciliationReport;
