import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allSiteManagers: [],
  totalCount: 0,
  siteManagerList: [],
  siteManagerDetails: {},
  loading: false,
};

export const getSiteManagerList = createAsyncThunk(
  'siteManager/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getSiteManagerList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreSiteManagers = createAsyncThunk(
  'siteManager/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getSiteManagerList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllSiteManagers = createAsyncThunk(
  'siteManager/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllSiteManagers();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getSiteManagerDetails = createAsyncThunk(
  'siteManager/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getSiteManagerDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateSiteManager = createAsyncThunk(
  'siteManager/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateSiteManagerDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addSiteManager = createAsyncThunk(
  'siteManager/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createSiteManager(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatSiteManagerData = (data) => {
  const formattedList = data.map((siteManager) => ({ ...siteManager, name: `${siteManager.first_name} ${siteManager.last_name}` }));
  return formattedList;
};

export const siteManagerSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllSiteManagers.fulfilled, (state, action) => ({ ...state, allSiteManagers: formatSiteManagerData(action.payload) }))
      .addCase(getSiteManagerList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        siteManagerList: formatSiteManagerData(action.payload.results)
      }))
      .addCase(getSiteManagerList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSiteManagerList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreSiteManagers.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        siteManagerList: [...state.siteManagerList, ...formatSiteManagerData(action.payload.results)]
      }))
      .addCase(loadMoreSiteManagers.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreSiteManagers.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getSiteManagerDetails.fulfilled, (state, action) => ({ ...state, loading: false, siteManagerDetails: action.payload }))
      .addCase(getSiteManagerDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSiteManagerDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateSiteManager.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        siteManagerDetails: action.payload,
        siteManagerList: updateItemInArray(state.siteManagerList, action.payload)
      }))
      .addCase(addSiteManager.fulfilled, (state, action) => {
        state.siteManagerList.push(action.payload);
      });
  }
});

export default siteManagerSlice.reducer;
