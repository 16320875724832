import React from 'react';
import { Grid } from '@mui/material';
import InfoField, { GridRow } from 'src/common/InfoField';
import {
  getFormattedDate, getFuelPrice, getFuelQuantity, removeLeadingZeros
} from 'src/utils/getInitials';
import { REPORT_FILTER_OPTIONS, TRANSACTION_TYPES } from 'src/utils/constants';
import API_URL from 'src/api/apiConstants';

export const renderInwardTransactionTable = (transactionDetails) => (
  <>
    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
      <InfoField name="Site Name" value={transactionDetails.site_name} />
      <InfoField name="Source Tank" value={transactionDetails.tank_name} />
      <InfoField name="Output Tank" value={transactionDetails.received_tank_name} />
    </Grid>
    <GridRow>
      <InfoField
        name="Fuel Quantity"
        value={getFuelQuantity(transactionDetails.quantity)}
      />
      <InfoField
        name="Shortage Quantity"
        value={transactionDetails.shortage_quantity}
      />
      <InfoField
        name="Filled Quantity"
        value={transactionDetails.actual_filled}
      />
    </GridRow>
    <GridRow>
      <InfoField name="Supplier" value={transactionDetails.supplier} />
      <InfoField
        name="Price per litre"
        value={getFuelPrice(transactionDetails.price_per_litre)}
      />
      <InfoField
        name="Invoice Number"
        value={transactionDetails.invoice_number}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Delivery Vehicle Number"
        value={transactionDetails.delivery_vehicle_number}
      />
      <InfoField
        name="Order Date"
        value={getFormattedDate(transactionDetails.order_date)}
      />
      <InfoField
        name="Delivery Date"
        value={getFormattedDate(transactionDetails.delivery_date)}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Density During Loading"
        value={`${transactionDetails.density_during_loading || 0} kg/㎥`}
      />
      <InfoField
        name="Density During Unloading"
        value={`${transactionDetails.density_during_unloading || 0} kg/㎥`}
      />
      <InfoField
        name="Quantity Measured trough FLS"
        value={transactionDetails.delivered_measured_quantity_FLS}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Temperature During Loading"
        value={`${transactionDetails.temperature_during_loading || 0} ℃`}
      />
      <InfoField
        name="Temperature During Unloading"
        value={`${transactionDetails.temperature_during_unloading || 0} ℃`}
      />
      <InfoField
        name="Quantity Measured trough Meter"
        value={transactionDetails.delivered_measured_quantity_meter}
      />
    </GridRow>
  </>
);

export const renderSalesTransactionTable = (transactionDetails) => (
  <>
    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
      <InfoField name="Order ID" value={transactionDetails.order_id} />
      <InfoField name="Dispenser" value={transactionDetails.address} />
      <InfoField name="Site Name" value={transactionDetails.site_name} />
    </Grid>
    <GridRow>
      <InfoField
        name="Fuel Quantity"
        value={getFuelQuantity(transactionDetails.quantity)}
      />
      <InfoField
        name="Price Per Litre"
        value={getFuelPrice(transactionDetails.unit_price)}
      />
      <InfoField
        name="Total Amount"
        value={getFuelPrice(transactionDetails.amount)}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Vehicle Number"
        value={transactionDetails.vehicle_number}
      />
      <InfoField
        name="Delivery Date"
        value={getFormattedDate(transactionDetails.end_time)}
      />
      <InfoField
        name="Odometer Reading"
        value={transactionDetails.odometer_entry}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Vehicle Card"
        value={removeLeadingZeros(transactionDetails.card_num_1)}
      />
      <InfoField
        name="Operator Card"
        value={removeLeadingZeros(transactionDetails.card_num_2)}
      />
      <InfoField
        name="Nozzel Number"
        value={transactionDetails.nozzle_num}
      />
    </GridRow>
    <GridRow>
      <InfoField name="Fuelling Mode" value={transactionDetails.mode} />
      <InfoField
        name="Totalizer Start Reading"
        value={transactionDetails.totalizer_start_reading}
      />
      <InfoField
        name="Totalizer End Reading"
        value={transactionDetails.totalizer_end_reading}
      />
    </GridRow>
  </>
);

export const renderTransferTransactionTable = (transactionDetails) => (
  <>
    <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
      <InfoField name="Order ID" value={transactionDetails.order_id} />
      <InfoField
        name="Fuel Quantity"
        value={getFuelQuantity(transactionDetails.quantity)}
      />
      <InfoField
        name="Total Amount"
        value={getFuelPrice(transactionDetails.amount)}
      />
    </Grid>
    <GridRow>
      <InfoField
        name="Price Per Litre"
        value={getFuelPrice(transactionDetails.unit_price)}
      />
      <InfoField name="Site Name" value={transactionDetails.site_name} />
      <InfoField
        name="Delivery Date"
        value={getFormattedDate(transactionDetails.end_time)}
      />
    </GridRow>
    <GridRow>
      <InfoField name="Fuelling Mode" value={transactionDetails.mode} />
      <InfoField
        name="Vehicle Number"
        value={transactionDetails.vehicle_number}
      />
      <InfoField
        name="Odometer Reading"
        value={transactionDetails.odometer_entry}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Vehicle Card"
        value={removeLeadingZeros(transactionDetails.card_num_1)}
      />
      <InfoField
        name="Operator Card"
        value={removeLeadingZeros(transactionDetails.card_num_2)}
      />
      <InfoField
        name="Nozzel Number"
        value={transactionDetails.nozzle_num}
      />
    </GridRow>
    <GridRow>
      <InfoField
        name="Totalizer Start Reading"
        value={transactionDetails.totalizer_start_reading}
      />
      <InfoField
        name="Totalizer End Reading"
        value={transactionDetails.totalizer_end_reading}
      />
    </GridRow>
  </>
);

export const getReportDownloadURL = (transactionType) => {
  let reportAPIUrl = '';
  switch (transactionType) {
    case TRANSACTION_TYPES[0].id:
      reportAPIUrl = API_URL.salesTransactionReport;
      break;
    case TRANSACTION_TYPES[1].id:
      reportAPIUrl = API_URL.inwardTransactionReport;
      break;
    case TRANSACTION_TYPES[2].id:
      reportAPIUrl = API_URL.transferTransactionReport;
      break;
    default:
      reportAPIUrl = API_URL.salesTransactionReport;
      break;
  }
  return reportAPIUrl;
};

export const getReportFormTitle = (transactionType) => {
  let reportTitle = '';
  switch (transactionType) {
    case TRANSACTION_TYPES[0].id:
      reportTitle = 'Issued Transaction Report';
      break;
    case TRANSACTION_TYPES[1].id:
      reportTitle = 'Inward Transaction Report';
      break;
    case TRANSACTION_TYPES[2].id:
      reportTitle = 'Transfer Transaction Report';
      break;
    default:
      reportTitle = 'Issued Transaction Report';
      break;
  }
  return reportTitle;
};

export const getFilterOptions = (transactionType) => {
  let filterOptions = REPORT_FILTER_OPTIONS;
  if (transactionType === TRANSACTION_TYPES[0].id) {
    filterOptions = { ...REPORT_FILTER_OPTIONS, assetType: true };
  }
  if (transactionType === TRANSACTION_TYPES[1].id) {
    filterOptions = { site: true, tank: true };
  }
  return filterOptions;
};

export const getSearchPlaceholder = (transactionType) => {
  let placeholderText = 'Search Vehicle Number';
  if (transactionType === TRANSACTION_TYPES[1].id) {
    placeholderText = 'Search Invoice Number';
  }
  return placeholderText;
};
