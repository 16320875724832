import * as Yup from 'yup';
import STATES_LIST from 'src/utils/stateList.json';
import COUNTRY_LIST from 'src/utils/countryList.json';
import {
  ADDRESS_REGEX,
  CODE_REGEX,
  NAME_REGEX, PASSWORD_REGEX, PHONE_NUMBER_REGEX,
  PINCODE_REGEX, USER_NAME_REGEX
} from 'src/utils/constants';

// siteManager form model
export const siteManagerFormModel = {
  formId: 'siteManagerConfig',
  formField: {
    siteId: {
      name: 'site',
      label: 'Select site',
      requiredErrorMsg: 'Site field is required',
      type: 'select',
      data: []
    },
    empId: {
      name: 'emp_id',
      label: 'Employee ID',
      requiredErrorMsg: 'Employee ID is required',
      invalidErrorMsg: 'Employee ID is invalid',
      type: 'text',
    },
    firstName: {
      name: 'first_name',
      label: 'First Name',
      requiredErrorMsg: 'First Name is required',
      invalidErrorMsg: 'First Name is invalid',
      type: 'text',
    },
    lastName: {
      name: 'last_name',
      label: 'Last Name',
      requiredErrorMsg: 'Last Name is required',
      invalidErrorMsg: 'Last Name is invalid',
      type: 'text',
    },
    username: {
      name: 'username',
      label: 'Username',
      requiredErrorMsg: 'Username is required',
      invalidErrorMsg: 'Username is invalid',
      type: 'text',
    },
    password: {
      name: 'password',
      label: 'Password',
      requiredErrorMsg: 'Password is required',
      invalidErrorMsg: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character',
      type: 'password',
    },
    confirmPassword: {
      name: 'confirmPassword',
      label: 'Confirm Password',
      requiredErrorMsg: 'Confirm Password is required',
      invalidErrorMsg: 'Passwords must match',
      type: 'password',
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Email is invalid',
      type: 'text',
    },
    mobileNum: {
      name: 'mobile_num',
      label: 'Phone Number',
      requiredErrorMsg: 'Phone Number is required',
      invalidErrorMsg: 'Phone Number is invalid',
      type: 'text',
    },
    addressLine: {
      name: 'address',
      label: 'Address',
      requiredErrorMsg: 'Address is required',
      invalidErrorMsg: 'Address is invalid',
      type: 'text',
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required',
      invalidErrorMsg: 'City name is invalid',
      type: 'text',
    },
    state: {
      name: 'state',
      label: 'Select State',
      requiredErrorMsg: 'State is required',
      type: 'select',
      data: STATES_LIST
    },
    pinCode: {
      name: 'pin_code',
      label: 'Pincode',
      requiredErrorMsg: 'Pincode is required',
      invalidErrorMsg: 'Pincode is Invalid',
      type: 'text',
    },
    country: {
      name: 'country',
      label: 'Country',
      requiredErrorMsg: 'Country is required',
      type: 'select',
      data: COUNTRY_LIST
    },
  }
};

// Initial values
const {
  formField: {
    siteId,
    empId,
    firstName,
    lastName,
    username,
    password,
    confirmPassword,
    email,
    mobileNum,
    addressLine,
    city,
    state,
    pinCode,
    country,
  }
} = siteManagerFormModel;

export const siteManagerFormInitialValues = {
  [siteId.name]: '',
  [empId.name]: '',
  [firstName.name]: '',
  [lastName.name]: '',
  [username.name]: '',
  [password.name]: '',
  [confirmPassword.name]: '',
  [email.name]: '',
  [mobileNum.name]: '',
  [addressLine.name]: '',
  [city.name]: '',
  [state.name]: '',
  [pinCode.name]: '',
  [country.name]: 'IN',
};

// validation schema
export const getSiteManagerValidationSchema = (initValues) => Yup.object().shape({
  [siteId.name]: Yup.string().required(siteId.requiredErrorMsg),
  [empId.name]: Yup.string().matches(CODE_REGEX, empId.invalidErrorMsg)
    .required(empId.requiredErrorMsg),
  [firstName.name]: Yup.string().min(2).max(20).matches(NAME_REGEX, firstName.invalidErrorMsg)
    .required(firstName.requiredErrorMsg),
  [lastName.name]: Yup.string().max(20).matches(NAME_REGEX, lastName.invalidErrorMsg)
    .required(lastName.requiredErrorMsg),
  [username.name]: Yup.string().min(4).max(20).matches(USER_NAME_REGEX, username.invalidErrorMsg)
    .required(username.requiredErrorMsg),
  [email.name]: Yup.string().email(email.invalidErrorMsg).required(email.requiredErrorMsg),
  ...(!initValues && {
    [password.name]: Yup.string().matches(PASSWORD_REGEX, password.invalidErrorMsg)
      .required(password.requiredErrorMsg),
    [confirmPassword.name]: Yup.string().required(confirmPassword.requiredErrorMsg)
      .oneOf([Yup.ref(password.name), null], confirmPassword.invalidErrorMsg)
  }),
  [mobileNum.name]: Yup.string().matches(PHONE_NUMBER_REGEX, mobileNum.invalidErrorMsg)
    .required(mobileNum.requiredErrorMsg),
  [addressLine.name]: Yup.string().max(150).matches(ADDRESS_REGEX, addressLine.invalidErrorMsg),
  [city.name]: Yup.string().matches(NAME_REGEX, city.invalidErrorMsg)
    .required(city.requiredErrorMsg),
  [state.name]: Yup.string().required(state.requiredErrorMsg),
  [country.name]: Yup.string().required(country.requiredErrorMsg),
  [pinCode.name]: Yup.string().matches(PINCODE_REGEX, pinCode.invalidErrorMsg)
});
