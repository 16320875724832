import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';

const initialState = {
  loading: false,
  totalCount: 0,
  pendingApprovalsCount: 0,
  cardApprovalsList: [],
};

export const getCardApprovalsList = createAsyncThunk(
  'card-approvals/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getCardApprovalsList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreApprovals = createAsyncThunk(
  'card-approvals/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getCardApprovalsList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const requestCardLimitChange = createAsyncThunk(
  'card-approvals/change-limit', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.changeCardLimit(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const approveRejectCardLimit = createAsyncThunk(
  'card-approvals/approve-reject', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.approveRejectCardLimit(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const cancelCardLimitRequest = createAsyncThunk(
  'card-approvals/cancel-request', async (req, { rejectWithValue },) => {
    const { requestId, payload } = req;
    try {
      const res = await APIService.cancelCardLimitRequest(requestId, payload);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getApprovalsCount = createAsyncThunk(
  'card-approvals/approval-count', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.cardLimitApprovalsCount();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const updateItemStatus = (data, itemId) => data.map((item) => {
  if (item.id === itemId) {
    return { ...item, status: 'cancelled' };
  }
  return item;
});

export const cardApprovalSlice = createSlice({
  name: 'card-approvals',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getCardApprovalsList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        cardApprovalsList: action.payload.results
      }))
      .addCase(getCardApprovalsList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getCardApprovalsList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreApprovals.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        cardApprovalsList: action.payload.results
      }))
      .addCase(loadMoreApprovals.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreApprovals.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getApprovalsCount.fulfilled, (state, action) => ({
        ...state,
        pendingApprovalsCount: action.payload.count
      }))
      .addCase(cancelCardLimitRequest.fulfilled, (state, action) => ({
        ...state, cardApprovalsList: updateItemStatus(state.cardApprovalsList, action.payload.id)
      }));
  }
});

export default cardApprovalSlice.reducer;
