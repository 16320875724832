import {
  Avatar,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography
} from '@mui/material';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PropTypes from 'prop-types';
import { red } from '@mui/material/colors';
import { memo } from 'react';

const DashboardCard = ({
  title, data, icon, iconColor
}) => (
  <Card sx={{ height: '100%' }}>
    <CardContent>
      <Grid container sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="subtitle1">
            {title}
          </Typography>
          <Typography color="textPrimary" variant="h2">
            {data?.total}
          </Typography>
        </Grid>
        <Grid item>
          <Avatar
            sx={{
              backgroundColor: iconColor,
              height: 45,
              width: 45
            }}
          >
            {icon}
          </Avatar>
        </Grid>
      </Grid>
      {!data ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            pt: 1,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowDownwardIcon sx={{ color: red.A400 }} />
          <Typography sx={{ color: red.A400, mr: 1 }} variant="body1">
            {data.inactive}
          </Typography>
          <Typography color="textSecondary" variant="caption">
            Inactive
          </Typography>
        </Box>
      )}
    </CardContent>
  </Card>
);

DashboardCard.propTypes = {
  title: PropTypes.string,
  data: PropTypes.object,
  icon: PropTypes.object,
  iconColor: PropTypes.string
};

export default memo(DashboardCard);
