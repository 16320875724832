import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import FMSModal from 'src/common/FMSModal';
import { getFormattedDate } from 'src/utils/getInitials';
import { GridRow, InfoText } from 'src/common/InfoField';
import Loader from 'src/common/Loader';

function CardSyncStatusModal({
  open, onClose, loading, data
}) {
  return (
    <FMSModal
      open={open}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="logout-dialog-title"
      aria-describedby="logout-dialog-description"
    >
      <DialogTitle sx={{ fontSize: 16 }} id="logout-dialog-title">
        Card Sync Status
      </DialogTitle>
      <Divider />
      <DialogContent>
        {loading ? <Loader sx={{ width: { xs: 260, sm: 450 } }} />
          : (
            <>
              <GridRow style={{ mt: 0, mb: 3 }}>
                <InfoText name="Card UID" value={data?.uid || 'NA'} />
                <InfoText name="Vehicle Number" sx={{ ml: 2 }} value={data?.vehicle_reg_number || 'NA'} />
              </GridRow>
              {data?.sync_status?.map((item) => (
                <React.Fragment key={item.dispenser}>
                  <InfoText name="Dispenser Name" value={item.dispenser} />
                  <GridRow style={{ mt: 0, mb: 3 }}>
                    <InfoText name="Card Sync Status" value={item.sync_status || 'NA'} />
                    <InfoText
                      name="Last Sync Date"
                      sx={{ ml: 2 }}
                      value={getFormattedDate(item.synced_on)}
                    />
                  </GridRow>
                </React.Fragment>
              ))}
            </>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </FMSModal>
  );
}

CardSyncStatusModal.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default CardSyncStatusModal;
