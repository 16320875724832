import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { colors, TextField } from '@mui/material';

export default function InputField(props) {
  const {
    name, upperCase, disabled, ...rest
  } = props;
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;
  const { setFieldValue } = useFormikContext();

  function renderHelperText() {
    if (meta.touched && meta.error) {
      return meta.error;
    }
    return null;
  }

  const handleInputChange = (e) => {
    let value = e.target.value || '';
    if (upperCase) {
      value = value.toUpperCase();
    }
    setFieldValue(name, value);
  };

  return (
    <TextField
      type="text"
      {...field}
      {...rest}
      disabled={disabled}
      sx={{ backgroundColor: disabled ? colors.grey[200] : 'inherit' }}
      value={selectedValue == null ? '' : selectedValue}
      error={Boolean(meta.touched && meta.error)}
      helperText={renderHelperText()}
      onChange={handleInputChange}
    />
  );
}

InputField.propTypes = {
  name: PropTypes.string,
  upperCase: PropTypes.bool,
  disabled: PropTypes.bool,
};
