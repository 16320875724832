import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCardsList, getAllCards, getCardLinkedVehiclesList } from 'src/redux/cardSlice';
import { requestCardLimitChange, getCardApprovalsList } from 'src/redux/cardApprovalSlice';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import FMSModal from 'src/common/FMSModal';
import InputField from 'src/common/formFields/InputField';
import AutocompleteField from 'src/common/formFields/AutocompleteField';
import { getVehicleFuelLevel } from 'src/redux/vehicleSlice';
import {
  cardApprovalsFormModel,
  cardApprovalFormInitialValues,
  cardApprovalsValidationSchema
} from './cardApprovalsFormModel';

const NewCardApproval = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [disableFuelLevel, setDisableFuelLevel] = useState(true);
  const allActiveCards = useSelector(getActiveCardsList);
  const allCardLinkedVehicles = useSelector(getCardLinkedVehiclesList);

  const {
    formId,
    formField: {
      vehicleNumber,
      cardName,
      tankCapacity,
      tankFuelPercentage,
      requestedQuantity
    }
  } = cardApprovalsFormModel;

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        requested_quantity: Number(values.requested_quantity),
        tank_fuel_percentage: Number(values.tank_fuel_percentage),
      };
      await dispatch(requestCardLimitChange(payload)).unwrap();
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Card Limit update request sent successfully!'
        })
      );
      // reload approvals list
      dispatch(getCardApprovalsList({ limit: 10 }));
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const formikProps = useFormik({
    initialValues: cardApprovalFormInitialValues,
    validationSchema: cardApprovalsValidationSchema,
    onSubmit,
  });

  useEffect(async () => {
    if (open) {
      formikProps.resetForm();
      setFormError(null);
      setDisableFuelLevel(true);
      dispatch(getAllCards());
    }
  }, [open]);

  const fetchVehicleFuelLevelStatus = async (vehicleId) => {
    try {
      setFormLoading(true);
      const res = await dispatch(getVehicleFuelLevel(vehicleId)).unwrap();
      if (res?.fuel_level) {
        formikProps.setFieldValue(tankFuelPercentage.name, res.fuel_level);
        setDisableFuelLevel(true);
      }
    } catch (error) {
      const errorMsg = error?.error_message
        ? `${error.error_message}. Enter fuel level manually or try again!`
        : 'Unable to fetch fuel level from sensor. Enter fuel level manually or try again!';
      setFormError(errorMsg);
      formikProps.setFieldValue(tankFuelPercentage.name, '');
      setDisableFuelLevel(false);
    } finally {
      setFormLoading(false);
    }
  };

  const handleOptionChange = (cardId, type = 'card') => {
    if (cardId && (cardId !== formikProps.values.card_id || cardId !== formikProps.values.vehicle_number)) {
      setDisableFuelLevel(true);
      const cardDetails = allActiveCards.find((card) => card.id === cardId);
      const fieldName = type === 'card' ? vehicleNumber.name : cardName.name;
      formikProps.setFieldValue(fieldName, cardId);
      formikProps.setFieldValue(tankCapacity.name, cardDetails.tank_capacity);
      if (cardDetails.vehicle_id && cardDetails.telemetics_enabled) {
        fetchVehicleFuelLevelStatus(cardDetails.vehicle_id);
      } else {
        formikProps.setFieldValue(tankFuelPercentage.name, '');
        setDisableFuelLevel(false);
      }
    }
  };

  // hanlde card change
  useEffect(() => {
    handleOptionChange(formikProps.values.card_id);
  }, [formikProps.values.card_id]);

  // hanlde vehicle change
  useEffect(() => {
    handleOptionChange(formikProps.values.vehicle_number, 'vehicle');
  }, [formikProps.values.vehicle_number]);

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Request Card Limit
      </DialogTitle>
      <Divider />
      <FormikProvider value={formikProps}>
        {loading || formLoading ? (
          <Loader />
        ) : (
          <Form id={formId} onSubmit={formikProps.handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    name={vehicleNumber.name}
                    label={vehicleNumber.label}
                    options={allCardLinkedVehicles}
                    getOptionLabel={(option) => option.vehicle_number}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <AutocompleteField
                    name={cardName.name}
                    label={cardName.label}
                    options={allActiveCards}
                    getOptionLabel={(option) => option.uid}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={tankCapacity.name}
                    label={tankCapacity.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={tankFuelPercentage.name}
                    label={tankFuelPercentage.label}
                    InputProps={{
                      endAdornment: (<InputAdornment position="end">%</InputAdornment>),
                    }}
                    fullWidth
                    disabled={disableFuelLevel}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={requestedQuantity.name}
                    label={requestedQuantity.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            {formError && <Typography sx={{ textAlign: 'center' }} variant="subtitle1" color="error">{formError}</Typography>}
            <DialogActions sx={{ p: 2 }}>
              <Button
                variant="outlined"
                size="large"
                onClick={handleClose}
                disabled={formikProps.isSubmitting || formLoading}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={formikProps.isSubmitting || formLoading}
                type="submit"
                size="large"
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </FormikProvider>
    </FMSModal>
  );
};

NewCardApproval.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default NewCardApproval;
