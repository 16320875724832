import React, { useEffect, useState } from 'react';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  DialogContent,
  Divider,
  DialogActions
} from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Loader from 'src/common/Loader';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import theme from 'src/theme';
import { useDispatch } from 'react-redux';
import { getAllSites } from 'src/redux/siteSlice';
import { getAllTanks } from 'src/redux/tankSlice';
import {
  addNewDispenser,
  updateDispenserDetails
} from 'src/redux/dispenserSlice';
import FMSModal, { FMSModalTitle } from 'src/common/FMSModal';
import DispenserDetailsForm from './forms/DispenserDetailsForm';
import CounterConfigForm from './forms/CounterConfigForm';
import DispenserConfigForm from './forms/DispenserConfigForm';
import validationSchema from './formModel/dispenserValidationSchema';
import dispenserFormModel from './formModel/dispenserFormModel';
import formInitialValues from './formModel/dispenserInitialValues';
import MeterConfigForm from './forms/MeterConfigForm';
import TimerConfigForm from './forms/TimerConfigForm';
import AdditionalConfigForm from './forms/AdditionalConfigForm';

const { formId, formField } = dispenserFormModel;

function renderStepContent(step, initialValues) {
  switch (step) {
    case 0:
      return <DispenserDetailsForm formField={formField} initValues={!!initialValues} />;
    case 1:
      return <DispenserConfigForm formField={formField} />;
    case 2:
      return <CounterConfigForm formField={formField} />;
    case 3:
      return <MeterConfigForm formField={formField} />;
    case 4:
      return <TimerConfigForm formField={formField} />;
    case 5:
      return <AdditionalConfigForm formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

const NewDispenser = ({ open, handleClose, initialValues }) => {
  const steps = [
    'Dispenser Details',
    'Dispenser Configuration',
    'Counter Configuration',
    'Meter Configuration',
    'Timer Configuration',
    'Additional Configuration'
  ];
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const dispatch = useDispatch();
  const isLastStep = activeStep === steps.length - 1;
  const formInitValues = initialValues || formInitialValues;

  useEffect(() => {
    if (open) {
      dispatch(getAllSites());
      dispatch(getAllTanks());
    }
    setActiveStep(0); // reset form state
  }, [open]);

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const submitForm = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateDispenserDetails(values)).unwrap();
      } else {
        await dispatch(addNewDispenser(values)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Dispenser details updated successfully!'
            : 'New Dispenser has been created successfully!'
        })
      );
    } catch (error) {
      console.log('dispenser error', error);
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const handleSubmit = (values, actions) => {
    console.log(values, actions);
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getActionButton = () => {
    let actionBtn = 'Next';
    if (isLastStep) {
      if (initialValues) {
        actionBtn = 'Update Dispenser';
      } else {
        actionBtn = 'Create Dispenser';
      }
    }
    return actionBtn;
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <FMSModalTitle
        title={initialValues ? 'Update Dispenser details' : 'Add new Dispenser'}
        onCancel={handleClose}
      />
      <Divider />
      <DialogContent>
        {!loading && (
        <Stepper
          activeStep={activeStep}
          sx={{
            padding: theme.spacing(2, 0, 4),
            backgroundColor: 'rgba(256, 256, 256, 0.3)',
            borderRadius: 5
          }}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        )}
        <Formik
          initialValues={formInitValues}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              {loading ? (
                <Loader />
              ) : (
                <Form
                  id={formId}
                  sx={{
                    padding: theme.spacing(3),
                    background: 'transparent',
                    transition: 'all 0.5s ease'
                  }}
                >
                  {renderStepContent(activeStep, initialValues)}

                  <DialogActions sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleBack}
                    >
                      {activeStep === 0 ? 'Close' : 'Back'}
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      size="large"
                      variant="contained"
                    >
                      {getActionButton()}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </>
          )}
        </Formik>
      </DialogContent>
    </FMSModal>
  );
};

NewDispenser.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewDispenser;
