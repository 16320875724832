import dispenserFormModel from './dispenserFormModel';

const {
  formField: {
    dispenserMode,
    serialNumber,
    kfactor,
    articleUnit,
    article,
    articlePrice,
    pulseType,
    pulseDirection,
    throttleVolume,
    throttleStage,
    minFlowRate,
    maxFlowRate,
    maxFuelingTime,
    fuelBreakTime,
    fuelStartTime,
    odometerTimeout,
    addInputTimeout,
    pinEntryTimeout,
    nozzleTimeout,
    nozzleId,
    totalizerStartReading,
    densityStartReading,
    volumeDecimal,
    amountDecimal,
    unitPriceDecimal,
    atgConnection,
    atgType,
    taxPercent,
    printerField,
    locationField,
    cardReaderField,
    fuellingWithIDInput,
    backlight,
    dateTimeSyncField,
    dateTime,
    password1,
    password2,
    alarms,
    stationName,
    vehicleNumber,
    site,
    tankId,
    updatedField,
    shiftStartTime,
    shiftDuration,
    emptyFillingAllowed,
    dispenserMount,
    dpNo,
    dpInterface,
    dpAddress,
    sequentialDIspenserID,
    kilometerDifference,
    minDigitsEntry,
    onlineConnectionTimeout,
    maxQuantityPerTransaction
  }
} = dispenserFormModel;

export default {
  [dispenserMode.name]: 'Online Transactions Uploading',
  [serialNumber.name]: '',
  [kfactor.name]: '0',
  [articleUnit.name]: 'Litres',
  [article.name]: 'Diesel',
  [articlePrice.name]: '1',
  [pulseType.name]: 'Single',
  [pulseDirection.name]: 'Any Direction',
  [throttleVolume.name]: '1000',
  [throttleStage.name]: 'Dual Stage',
  [minFlowRate.name]: '10',
  [maxFlowRate.name]: '100',
  [maxFuelingTime.name]: '99999',
  [fuelBreakTime.name]: '60',
  [fuelStartTime.name]: '60',
  [odometerTimeout.name]: '60',
  [addInputTimeout.name]: '60',
  [pinEntryTimeout.name]: '60',
  [nozzleTimeout.name]: '0',
  [nozzleId.name]: '0',
  [totalizerStartReading.name]: '0',
  [densityStartReading.name]: '0',
  [volumeDecimal.name]: '2',
  [amountDecimal.name]: '2',
  [unitPriceDecimal.name]: '2',
  [atgConnection.name]: false,
  [atgType.name]: 'No ATG',
  [taxPercent.name]: '0',
  [printerField.name]: false,
  [locationField.name]: false,
  [cardReaderField.name]: false,
  [fuellingWithIDInput.name]: false,
  [backlight.name]: true,
  [dateTimeSyncField.name]: true,
  [dateTime.name]: '',
  [password1.name]: '',
  [password2.name]: '',
  [alarms.name]: '0',
  [stationName.name]: '',
  [vehicleNumber.name]: '',
  [updatedField.name]: '',
  [shiftStartTime.name]: '',
  [shiftDuration.name]: '',
  [emptyFillingAllowed.name]: '99',
  [dispenserMount.name]: 'Mobile',
  [dpNo.name]: '1',
  [dpInterface.name]: 'Pulse',
  [dpAddress.name]: '1',
  [sequentialDIspenserID.name]: 1,
  [kilometerDifference.name]: 1,
  [minDigitsEntry.name]: '0',
  [onlineConnectionTimeout.name]: 10,
  [maxQuantityPerTransaction.name]: 10,
  [site.name]: '',
  [tankId.name]: ''
};
