import { NUMBER_TYPE_ERROR_MSG } from 'src/utils/constants';
import * as Yup from 'yup';

export const breakDownReviewFormModel = {
  formId: 'breakdownReviewConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Select Vehicle',
      requiredErrorMsg: 'Vehicle is required!',
      type: 'text',
    },
    breakDownType: {
      name: 'type',
      label: 'BreakDown Type',
      requiredErrorMsg: 'BreakDown Type is required!',
      type: 'text',
    },
    description: {
      name: 'description',
      label: 'Description',
      requiredErrorMsg: 'BreakDown Description is required!',
      type: 'text',
    },
    repairCost: {
      name: 'expense',
      label: 'Repair Cost',
      requiredErrorMsg: 'Repair Cost is required!',
      type: 'text',
    },
    remarks: {
      name: 'remarks',
      label: 'Remarks',
      requiredErrorMsg: 'Remarks is required!',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    vehicleNumber,
    breakDownType,
    description,
    repairCost,
    remarks
  }
} = breakDownReviewFormModel;

export const breakDownReviewFormInitialValues = {
  [vehicleNumber.name]: '',
  [breakDownType.name]: '',
  [description.name]: '',
  [repairCost.name]: '',
  [remarks.name]: '',
};
export const breakDownReviewValidationSchema = Yup.object().shape({
  [repairCost.name]: Yup.number().required(repairCost.requiredErrorMsg).typeError(NUMBER_TYPE_ERROR_MSG),
  [remarks.name]: Yup.string().nullable().required(remarks.requiredErrorMsg),
});
