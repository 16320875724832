import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  FormHelperText,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useAuth from 'src/context/AuthContext';
import { APIService } from 'src/api/api';
import { useState } from 'react';
import InputField from 'src/common/formFields/InputField';
import { loginValidationSchema } from 'src/utils/getInitials';
import SelectField from 'src/common/formFields/SelectField';
import { USER_ROLES, USER_TYPES } from 'src/utils/constants';
import ScreenHeader from 'src/common/ScreenHeader';

export const LayoutWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  height: '100vh',
  background: 'linear-gradient(90deg, rgba(237,194,128,1) 10%, rgba(220,144,67,1) 72%)'
});

const Login = () => {
  const history = useHistory();
  const auth = useAuth();
  const [loginError, setLoginError] = useState('');
  const initValues = { email: '', password: '', role: 'admin' };

  const getLoggedInUserRole = (user, values) => {
    let loggedInUserRole = user.role || values.role;
    if (user.role === USER_ROLES.admin) {
      if (values.role !== USER_ROLES.admin) {
        throw new Error('Invalid login');
      }
      loggedInUserRole = USER_ROLES.admin;
    } else if (user.role === USER_ROLES.supervisor) {
      if (values.role !== USER_ROLES.requestor) {
        throw new Error('Invalid login');
      }
      loggedInUserRole = USER_ROLES.requestor;
    }
    return loggedInUserRole;
  };

  const login = async (values) => {
    setLoginError('');
    try {
      const res = await APIService.userLogin(values);
      if (!res.success) throw res;
      if (res.result.token) {
        const userRole = getLoggedInUserRole(res.result, values);
        auth.signin({ ...res.result, role: userRole });
        history.replace('app');
      }
    } catch (error) {
      setLoginError('Invalid credentials, Please try again!');
      console.log('Login error', error.message);
    }
  };

  return (
    <LayoutWrapper>
      <ScreenHeader title="Login" />
      <Box sx={{
        display: 'flex',
        margin: { sm: '0', md: 'auto' },
        flexDirection: 'column',
        justifyContent: 'center',
      }}
      >
        <Box
          sx={{
            display: { sm: 'block', md: 'flex' },
            width: '100%',
            boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
            margin: '0 auto'
          }}
        >
          <Box sx={{ flex: 3 }}>
            <Card variant="elevation">
              <img
                src="/static/images/callcomm_theme.png"
                alt="Fuel Management System"
                style={{
                  display: 'block', height: 500, width: '100%'
                }}
              />
            </Card>
          </Box>
          <Box sx={{ flex: 2 }}>
            <Card
              variant="elevation"
              sx={{
                py: 5,
                px: 6,
                overflowY: 'auto',
                maxHeight: 500,
                minHeight: 500
              }}
            >
              <Box sx={{ mb: 1 }}>
                <Typography
                  variant="h3"
                  sx={{
                    pb: 1,
                    color: 'black',
                    fontWeight: 800,
                    letterSpacing: 0.8
                  }}
                >
                  Sign in to Dashboard
                </Typography>
              </Box>
              <Formik
                initialValues={initValues}
                validationSchema={loginValidationSchema}
                onSubmit={login}
              >
                {({ handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <InputField
                      name="email"
                      label="Email"
                      margin="normal"
                      fullWidth
                      disabled={isSubmitting}
                    />
                    <InputField
                      name="password"
                      label="Password"
                      type="password"
                      margin="normal"
                      fullWidth
                      disabled={isSubmitting}
                    />
                    <Box sx={{ mt: 3 }}>
                      <SelectField
                        name="role"
                        label="Select Role"
                        value={initValues.role}
                        data={USER_TYPES}
                        fullWidth
                      />
                    </Box>
                    <FormHelperText error>{loginError}</FormHelperText>
                    <Box sx={{ py: 2, textAlign: 'center' }}>
                      {isSubmitting ? (
                        <CircularProgress />
                      ) : (
                        <Button
                          color="primary"
                          fullWidth
                          size="large"
                          type="submit"
                          sx={{ mt: 4, py: 1.5, background: '#EB6421' }}
                          variant="contained"
                        >
                          Sign in
                        </Button>
                      )}
                    </Box>
                  </form>
                )}
              </Formik>
            </Card>
          </Box>
        </Box>
      </Box>
    </LayoutWrapper>
  );
};

export default Login;
