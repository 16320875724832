import { useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getFormattedDate, getGroupName } from 'src/utils/getInitials';
import { GROUP_TYPES, TABLE_ROWS_PER_PAGE } from 'src/utils/constants';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { groupTableHeaders } from 'src/utils/tableHeaders';
import NewGroup from './NewGroup';

const GroupList = ({ groups }) => {
  const { groupType } = useSelector((state) => state.groups);
  const [openModal, setOpenModal] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({});
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const updateGroupInfo = (group) => {
    console.log(group);
    setSelectedGroup(group);
    setOpenModal(true);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <Card>
      <NewGroup open={openModal} initialValues={selectedGroup} handleClose={() => setOpenModal(false)} />
      <PerfectScrollbar>
        <Box sx={{ minWidth: 1050 }}>
          <Table>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              headCells={groupTableHeaders}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {groups.slice()
                .sort(getComparator(order, orderBy))
                .slice(page * limit, page * limit + limit)
                .map((group) => (
                  <TableRow
                    hover
                    key={group.id}
                  >
                    <TableCell>
                      <Box
                        sx={{ alignItems: 'center', display: 'flex' }}
                      >
                        <Typography
                          color="textPrimary"
                          variant="body1"
                        >
                          {group.id}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{group.name}</TableCell>
                    <TableCell>
                      {getGroupName(groupType, GROUP_TYPES)}
                    </TableCell>
                    <TableCell>
                      {getFormattedDate(group.created_at, 'DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" color="secondary" sx={{ mr: 1 }} onClick={() => updateGroupInfo(group)} size="small">Update</Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={groups.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      />
    </Card>
  );
};

GroupList.propTypes = {
  groups: PropTypes.array.isRequired,
};

export default GroupList;
