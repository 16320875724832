import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import Loader from 'src/common/Loader';
import { addDriver, updateDriver } from 'src/redux/driverSlice';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import { getAllSites } from 'src/redux/siteSlice';
import FMSModal from 'src/common/FMSModal';
import { filterVehiclesBySite, getAllVehicles } from 'src/redux/vehicleSlice';
import theme from 'src/theme';
import {
  driverFormInitialValues,
  driverFormModel,
  getDriverValidationSchema
} from './driverFormModel';

const NewDriver = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const { allSites } = useSelector((state) => state.sites);
  const { allVehicles, vehicleListBySite } = useSelector(
    (state) => state.vehicles
  );
  const [loading, setLoading] = useState(false);
  const mounted = useRef();
  const formInitValues = initialValues || driverFormInitialValues;
  const {
    formId,
    formField: {
      siteId,
      vehicleId,
      firstName,
      lastName,
      password,
      confirmPassword,
      username,
      email,
      mobileNum,
      empId,
      addressLine,
      city,
      state,
      country,
      pinCode
    }
  } = driverFormModel;

  useEffect(() => {
    if (open) {
      dispatch(getAllSites());
      dispatch(getAllVehicles());
    }
    mounted.current = false;
  }, [open]);

  // filter tank list based on site id on page load
  useEffect(() => {
    if (!mounted.current && initialValues && allVehicles.length > 0) {
      dispatch(filterVehiclesBySite(initialValues.site));
      mounted.current = true;
    }
  }, [allVehicles]);

  // filter tank list based on site id on site change
  const onSiteChange = (e, fn) => {
    const selectedSiteId = e.target.value;
    console.log('selectedSiteId', selectedSiteId);
    dispatch(filterVehiclesBySite(selectedSiteId));
    fn(e);
  };

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateDriver(values)).unwrap();
      } else {
        // pass username as employee ID
        const reqData = { ...values, username: values.emp_id };
        await dispatch(addDriver(reqData)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Driver details updated successfully!'
            : 'New driver has been created successfully!'
        })
      );
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        {initialValues ? 'Update Driver details' : 'Add new Driver'}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={getDriverValidationSchema(initialValues)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, handleChange }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={siteId.name}
                        label={siteId.label}
                        data={allSites}
                        onChange={(e) => onSiteChange(e, handleChange)}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={vehicleId.name}
                        label={vehicleId.label}
                        data={vehicleListBySite}
                        disabled={!values.site}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={firstName.name}
                        label={firstName.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={lastName.name}
                        label={lastName.label}
                        fullWidth
                      />
                    </Grid>
                    {!initialValues && (
                      <>
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name={password.name}
                            label={password.label}
                            type={password.type}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputField
                            name={confirmPassword.name}
                            label={confirmPassword.label}
                            type={confirmPassword.type}
                            fullWidth
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={username.name}
                        label={username.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={empId.name}
                        label={empId.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={email.name}
                        label={email.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={mobileNum.name}
                        label={mobileNum.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={addressLine.name}
                        label={addressLine.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={city.name}
                        label={city.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={state.name}
                        label={state.label}
                        data={state.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={country.name}
                        label={country.label}
                        data={country.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={pinCode.name}
                        label={pinCode.label}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {initialValues ? 'Update Driver' : 'Add Driver'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

NewDriver.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewDriver;
