import {
  Container, Grid, Button,
  Card, CardContent, CardHeader, Divider, Typography, Box, CardActions, FormGroup, FormControlLabel, Switch
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import Newsite from 'src/components/sites/NewSite';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { getSiteDetails, updateSiteDetails as updateSiteStatus } from 'src/redux/siteSlice';
import { CONFIRMATION_MSG } from 'src/utils/constants';
import InfoField, { GridRow } from 'src/common/InfoField';
import useAuth from 'src/context/AuthContext';
import ROUTE from 'src/utils/routeConstants';
import { getFormattedDate, getStateName, isAdminUser } from 'src/utils/getInitials';

function SiteDetails() {
  const { siteId } = useParams();
  const history = useHistory();
  const { getUser } = useAuth();
  const isAdmin = isAdminUser(getUser);
  const { loading, siteDetails } = useSelector((state) => state.sites);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getSiteDetails(siteId)).unwrap();
  }, []);

  const updateSiteDetails = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setShowConfirmModal(false);
  };
  const onStatusChange = () => {
    if (isAdmin) {
      setShowConfirmModal(true);
    }
  };

  const updateStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const values = { ...siteDetails, active: !siteDetails.active };
      await dispatch(updateSiteStatus(values)).unwrap();
      setIsLoading(false);
      dispatch(setSuccessInfo({
        successMsg: 'site status updated successfully!',
      }));
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <Container maxWidth="lg">
      <Newsite open={openModal} initialValues={siteDetails} handleClose={() => setOpenModal(false)} />
      <Grid container sx={{ mt: 2 }}>
        <Grid
          item
          xs={12}
        >
          {loading || isLoading ? <Loader /> : (
            <Card>
              <CardHeader
                title="Site Details"
                action={(
                  <Button variant="contained" onClick={() => history.replace(ROUTE.sites)}>
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField name="Site Name" value={siteDetails.name} />
                  <InfoField name="Site Code" value={siteDetails.code} />
                  <InfoField name="Site Address" value={`${siteDetails.city}, ${getStateName(siteDetails.state)}`} />
                </Grid>
                <GridRow>
                  <InfoField name="Created On" value={getFormattedDate(siteDetails.created_at)} />
                  <InfoField name="Last Updated On" value={getFormattedDate(siteDetails.updated_at)} />
                  <Box sx={{ width: '35%' }}>
                    <Typography
                      color="textSecondary"
                      variant="body1"
                    >
                      Status
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          (
                            <Switch
                              checked={siteDetails.active}
                              value={siteDetails.active}
                              color={siteDetails.active ? 'success' : 'default'}
                            />
                            )
                          }
                        onChange={onStatusChange}
                        label={siteDetails.active ? 'Active' : 'Inactive'}
                      />
                    </FormGroup>
                  </Box>
                </GridRow>
                <GridRow sx={{ mb: 2 }}>
                  <InfoField name="Total Dispensers" value={siteDetails.dispensers_count} />
                  <InfoField name="Total Tanks" value={siteDetails.tanks_count} />
                  <InfoField name="Total Vehicles" value={siteDetails.vehicle_count} />
                </GridRow>
                <GridRow>
                  <InfoField name="Total Supervisors" value={siteDetails.site_manager_count} />
                </GridRow>
              </CardContent>
              <CardActions>
                {isAdmin && (
                <Button sx={{ m: 2 }} variant="contained" color="secondary" onClick={updateSiteDetails}>
                  Update Site
                </Button>
                )}
              </CardActions>
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        info={siteDetails.active ? CONFIRMATION_MSG.disableSite : CONFIRMATION_MSG.enableSite}
        open={showConfirmModal}
        disable={siteDetails.active}
        onClose={onModalClose}
        onSubmit={updateStatus}
      />
    </Container>
  );
}

export default SiteDetails;
