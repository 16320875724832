import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, showRoute, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (showRoute ? <Component {...props} /> : (<Redirect to="/404" />))}
  />
);

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.func
  ]),
  showRoute: PropTypes.bool
};

export default ProtectedRoute;
