import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allApprovers: [],
  totalCount: 0,
  approverList: [],
  approverDetails: {},
  loading: false,
};

export const getApproverList = createAsyncThunk(
  'approver/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getApproverList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreApprovers = createAsyncThunk(
  'approver/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getApproverList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllApprovers = createAsyncThunk(
  'approver/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllApprovers();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getApproverDetails = createAsyncThunk(
  'approver/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getApproverDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateApprover = createAsyncThunk(
  'approver/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateApproverDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addApprover = createAsyncThunk(
  'approver/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createApprover(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatApproverData = (data) => {
  const formattedList = data.map((approver) => ({ ...approver, name: `${approver.first_name} ${approver.last_name}` }));
  return formattedList;
};

export const approverSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllApprovers.fulfilled, (state, action) => ({ ...state, allApprovers: formatApproverData(action.payload) }))
      .addCase(getApproverList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        approverList: formatApproverData(action.payload.results)
      }))
      .addCase(getApproverList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getApproverList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreApprovers.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        approverList: [...state.approverList, ...formatApproverData(action.payload.results)]
      }))
      .addCase(loadMoreApprovers.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreApprovers.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getApproverDetails.fulfilled, (state, action) => ({ ...state, loading: false, approverDetails: action.payload }))
      .addCase(getApproverDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getApproverDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateApprover.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        approverDetails: action.payload,
        approverList: updateItemInArray(state.approverList, action.payload)
      }))
      .addCase(addApprover.fulfilled, (state, action) => {
        state.approverList.push(action.payload);
      });
  }
});

export default approverSlice.reducer;
