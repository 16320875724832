import PropTypes from 'prop-types';
import {
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  InputLabel,
} from '@mui/material';

const FilterSelectInput = ({
  label, name = 'group', value,
  data, onInputChange, ...rest
}) => {
  const handleInputChange = (e) => {
    const selectedValue = e.target.value;
    onInputChange(selectedValue);
  };

  return (
    <FormControl sx={{ width: { xs: '100%', sm: '15%' }, mr: { xs: 1, sm: 3 } }} {...rest}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        input={<OutlinedInput label={label} />}
        labelId={`${name}-label`}
        value={value}
        onChange={handleInputChange}
      >
        {data.map((item) => (
          <MenuItem value={item.id} key={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

FilterSelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  data: PropTypes.array.isRequired,
  onInputChange: PropTypes.func
};

export default FilterSelectInput;
