import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, Tooltip, Typography } from '@mui/material';

const EnhancedTableCell = ({ title }) => (
  <TableCell>
    <Tooltip title={title || 'NA'} arrow>
      <Typography
        variant="body2"
        sx={{
          maxWidth: 160,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {title || 'NA'}
      </Typography>
    </Tooltip>
  </TableCell>
);

EnhancedTableCell.propTypes = {
  title: PropTypes.string,
};

export default EnhancedTableCell;
