import React, { useEffect, useState } from 'react';
import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  CardActions
} from '@mui/material';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import Newtransaction from 'src/components/transactions/NewTransaction';
import { getTransactionDetails } from 'src/redux/transactionSlice';
import ROUTE from 'src/utils/routeConstants';
import { TRANSACTION_TYPES, USER_ROLES } from 'src/utils/constants';
import { isAdminUser } from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';
import { renderInwardTransactionTable, renderSalesTransactionTable, renderTransferTransactionTable } from './transactionService';

function TransactionDetails() {
  const { transactionId } = useParams();
  const history = useHistory();
  const { loading, transactionType, transactionDetails } = useSelector(
    (state) => state.transactions
  );
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isApprover = user?.role === USER_ROLES.approver;

  useEffect(() => {
    dispatch(
      getTransactionDetails({ transactionType, transactionId })
    ).unwrap();
  }, []);

  const updateTransactionDetails = () => {
    setOpenModal(true);
  };

  const renderTableData = () => {
    if (transactionType === TRANSACTION_TYPES[0].id) {
      // Inward transaction details
      return renderSalesTransactionTable(transactionDetails);
    } if (transactionType === TRANSACTION_TYPES[1].id) {
      // Sales transaction details
      return renderInwardTransactionTable(transactionDetails);
    } if (transactionType === TRANSACTION_TYPES[2].id) {
      // Transfer transaction details
      return renderTransferTransactionTable(transactionDetails);
    }
    return <></>;
  };

  return (
    <Container maxWidth="lg">
      <Newtransaction
        open={openModal}
        initialValues={transactionDetails}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Transaction Details"
                action={(
                  <Button
                    variant="contained"
                    onClick={() => history.replace(ROUTE.transactions)}
                  >
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>{renderTableData()}</CardContent>
              {transactionType === TRANSACTION_TYPES[1].id && (isAdmin || isApprover) && (
                <CardActions>
                  <Button
                    sx={{ m: 2 }}
                    variant="contained"
                    color="secondary"
                    onClick={updateTransactionDetails}
                  >
                    Update Transaction
                  </Button>
                </CardActions>
              )}
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default TransactionDetails;
