import { Fragment, memo, useEffect } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  linearProgressClasses,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getTankList } from 'src/redux/tankSlice';
import SyncIcon from '@mui/icons-material/Sync';
import { getFuelQuantity } from 'src/utils/getInitials';
import Loader from 'src/common/Loader';
import styled from '@emotion/styled';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value }) => {
  let barColor = '#8bc34a';
  if (value < 60 && value > 30) {
    barColor = '#ffca28';
  } else if (value < 30) {
    barColor = '#e53935';
  }
  return {
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[200],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor,
    },
  };
});

const StockInfo = () => {
  const dispatch = useDispatch();
  const { loading, tankList } = useSelector((state) => state.tanks);

  const fetchTankData = () => {
    dispatch(getTankList({ limit: 5 }));
  };

  useEffect(() => {
    fetchTankData();
  }, []);

  const getBalanceValue = (tank) => {
    let balance = (tank.current_balance / tank.max_volume) * 100;
    if (balance > 100) {
      balance = 100;
    }
    return balance ? Number(balance?.toFixed(0)) : 0;
  };

  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader
        title="Stock Details"
        sx={{
          background: 'linear-gradient(180deg, rgb(66, 66, 74), rgb(25, 25, 25))',
          color: '#fff'
        }}
        action={(
          <Tooltip title="Sync">
            <IconButton sx={{ color: 'white' }} aria-label="sync" onClick={fetchTankData}>
              <SyncIcon />
            </IconButton>
          </Tooltip>
        )}
      />
      <Divider />
      <Box sx={{ height: 228, overflow: 'auto' }}>
        {loading ? <Loader />
          : (
            <List>
              {tankList.map((tank) => (
                <Fragment key={tank.id}>
                  <ListItem divider>
                    <ListItemText
                      primary={tank.name}
                      secondary={`${getBalanceValue(tank)} %`}
                    />
                    <Box sx={{ width: '60%' }}>
                      <ListItemText
                        sx={{ textAlign: 'left' }}
                        primary={getFuelQuantity(tank.current_balance)}
                        primaryTypographyProps={{ fontWeight: 500, color: '#333' }}
                      />
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box sx={{ width: '100%', mr: 1 }}>
                          <BorderLinearProgress variant="determinate" value={getBalanceValue(tank)} />
                        </Box>
                      </Box>
                    </Box>
                  </ListItem>
                </Fragment>
              ))}
            </List>
          )}
      </Box>
    </Card>
  );
};

export default memo(StockInfo);
