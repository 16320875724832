import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import { SwitchInput } from 'src/common/FormElements';

export default function TimerConfigForm(props) {
  const {
    formField: {
      maxFuelingTime,
      fuelBreakTime,
      fuelStartTime,
      odometerTimeout,
      addInputTimeout,
      pinEntryTimeout,
      nozzleTimeout,
      nozzleId,
      cardReaderField,
      fuellingWithIDInput
    }
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <InputField name={maxFuelingTime.name} label={maxFuelingTime.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={fuelBreakTime.name} label={fuelBreakTime.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={fuelStartTime.name} label={fuelStartTime.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={odometerTimeout.name} label={odometerTimeout.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={addInputTimeout.name} label={addInputTimeout.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={pinEntryTimeout.name} label={pinEntryTimeout.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={nozzleId.name} label={nozzleId.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={nozzleTimeout.name} label={nozzleTimeout.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={cardReaderField.name} label={cardReaderField.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={fuellingWithIDInput.name} label={fuellingWithIDInput.label} fullWidth />
      </Grid>
    </Grid>
  );
}

TimerConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
