import {
  Box,
  Container,
  Grid
} from '@mui/material';
import ScreenHeader from 'src/common/ScreenHeader';
import SettingsNotifications from 'src/components/settings/SettingsNotifications';

const Settings = () => (
  <>
    <ScreenHeader title="Settings" />
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item lg={10} md={8} xs={12}>
            <SettingsNotifications />
          </Grid>
        </Grid>
      </Container>
    </Box>
  </>
);

export default Settings;
