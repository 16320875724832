import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import { SwitchInput } from 'src/common/FormElements';
import SelectField from 'src/common/formFields/SelectField';
import DatePickerField from 'src/common/formFields/DatePickerField';

export default function AdditionalConfigForm(props) {
  const {
    formField: {
      atgConnection,
      atgType,
      taxPercent,
      printerField,
      locationField,
      dateTimeSyncField,
      dateTime,
      alarms,
      shiftStartTime,
      shiftDuration,
    }
  } = props;
  return (

    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField name={atgType.name} label={atgType.label} data={atgType.data} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={atgConnection.name} label={atgConnection.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={taxPercent.name} label={taxPercent.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={printerField.name} label={printerField.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField name={dateTime.name} label={dateTime.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={dateTimeSyncField.name} label={dateTimeSyncField.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={alarms.name} label={alarms.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={locationField.name} label={locationField.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={shiftStartTime.name} label={shiftStartTime.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={shiftDuration.name} label={shiftDuration.label} fullWidth />
      </Grid>
    </Grid>
  );
}

AdditionalConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
