import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import DateFilterField from 'src/common/formFields/DateFilterField';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import { addShiftDetails, updateShiftDetails } from 'src/redux/settingSlice';
import { DATE_FORMAT } from 'src/utils/constants';

const SettingsNotifications = (props) => {
  const dispatch = useDispatch();
  const { shiftDetails, loading } = useSelector((state) => state.settings);

  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    if (shiftDetails?.id) {
      const endDateTime = moment(shiftDetails.shift_starts_from).add(shiftDetails.shift_duration, 'hours');
      setStartTime(shiftDetails.shift_starts_from);
      setEndTime(endDateTime.format(DATE_FORMAT.unixDateTime));
    }
  }, [shiftDetails]);

  const handleStartTimeChange = (newValue) => {
    setStartTime(newValue.format(DATE_FORMAT.unixDateTime));
  };
  const handleEndTimeChange = (newValue) => {
    setEndTime(newValue.format(DATE_FORMAT.unixDateTime));
  };
  const updateShiftTimings = async () => {
    try {
      const shiftDuration = moment.duration(moment(endTime).diff(startTime)).asHours();
      const payload = {
        ...shiftDetails,
        shift_starts_from: startTime,
        shift_duration: shiftDuration
      };
      if (shiftDetails?.id) {
        await dispatch(updateShiftDetails(payload)).unwrap();
      } else {
        await dispatch(addShiftDetails(payload)).unwrap();
      }
      dispatch(
        setSuccessInfo({
          successMsg: 'Shift details updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      // handle error here
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <form {...props}>
      <Card>
        <CardHeader
          subheader="Manage app settings"
          title="Settings"
        />
        <Divider />
        <CardContent>
          {loading ? <Loader />
            : (
              <Grid container spacing={2}>
                <Grid item>
                  <Typography variant="h5" sx={{ py: 1, color: '#333' }}>Shift Timing</Typography>
                  <DateFilterField
                    label="Shift Start Time"
                    value={startTime}
                    onlyDate={false}
                    hideClose
                    onDateChange={handleStartTimeChange}
                  />
                  <DateFilterField
                    label="Shift End Time"
                    value={endTime}
                    onlyDate={false}
                    pickerProps={{
                      disableFuture: false
                    }}
                    hideClose
                    onDateChange={handleEndTimeChange}
                  />
                </Grid>
              </Grid>
            )}
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={updateShiftTimings}
          >
            Save
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default SettingsNotifications;
