import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { TRANSACTION_TYPES } from 'src/utils/constants';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  totalCount: 0,
  transactionList: [],
  transactionDetails: {},
  transactionType: TRANSACTION_TYPES[0].id,
  loading: false,
};

export const getTransactionList = createAsyncThunk(
  'transaction/list', async (req, { rejectWithValue }) => {
    const {
      transactionType, limit, pageNo, params
    } = req;
    try {
      const res = await APIService.getTransactionList(transactionType, limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreTransactionList = createAsyncThunk(
  'transaction/list/loadmore', async (req, { rejectWithValue }) => {
    const {
      transactionType, limit, pageNo, params
    } = req;
    try {
      const res = await APIService.getTransactionList(transactionType, limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getTransactionDetails = createAsyncThunk(
  'transaction/details', async (req, { rejectWithValue }) => {
    const { transactionType, transactionId } = req;
    try {
      const res = await APIService.getTransactionDetails(transactionType, transactionId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateTransactionDetails = createAsyncThunk(
  'transaction/updateDetails', async (req, { rejectWithValue }) => {
    const { transactionType, values } = req;
    try {
      const res = await APIService.updateTransactionDetails(transactionType, values.id, values);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewTransaction = createAsyncThunk(
  'transaction/create', async (req, { rejectWithValue },) => {
    const { transactionType, values } = req;
    try {
      const res = await APIService.createTransaction(transactionType, values);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    setTransactionType: (state, action) => ({ ...state, transactionList: [], transactionType: action.payload }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getTransactionList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        transactionList: action.payload.results.sort((a, b) => a.id - b.id),
        totalCount: action.payload.count
      }))
      .addCase(getTransactionList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getTransactionList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreTransactionList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        transactionList: action.payload.results.sort((a, b) => a.id - b.id),
        totalCount: action.payload.count
      }))
      .addCase(loadMoreTransactionList.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreTransactionList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getTransactionDetails.fulfilled, (state, action) => ({ ...state, loading: false, transactionDetails: action.payload }))
      .addCase(getTransactionDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getTransactionDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateTransactionDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        transactionDetails: action.payload,
        transactionList: updateItemInArray(state.transactionList, action.payload)
      }))
      .addCase(addNewTransaction.fulfilled, (state, action) => {
        state.transactionList.push(action.payload);
      });
  }
});

// Action creators are generated for each case reducer function
export const { setTransactionType } = transactionSlice.actions;

export default transactionSlice.reducer;
