import { configureStore, combineReducers } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import siteReducer from 'src/redux/siteSlice';
import modalReducer from 'src/redux/modalSlice';
import requestorReducer from 'src/redux/requestorSlice';
import vehicleReducer from 'src/redux/vehicleSlice';
import groupsReducer from 'src/redux/groupSlice';
import operatorReducer from 'src/redux/operatorSlice';
import approverReducer from 'src/redux/approverSlice';
import siteManagerReducer from 'src/redux/siteManagerSlice';
import tankReducer from 'src/redux/tankSlice';
import dispenserReducer from 'src/redux/dispenserSlice';
import cardReducer from 'src/redux/cardSlice';
import cardApprovalsReducer from 'src/redux/cardApprovalSlice';
import driverReducer from 'src/redux/driverSlice';
import transactionsReducer from 'src/redux/transactionSlice';
import reportsReducer from 'src/redux/reportSlice';
import vehicleBreakDownReducer from 'src/redux/vehicleBreakDownSlice';
import sensorTransactionReducer from 'src/redux/sensorTransactionSlice';
import settingsReducer from 'src/redux/settingSlice';
import { RESET_STORE } from 'src/utils/constants';

// List all reducers
const reducers = {
  sites: siteReducer,
  modals: modalReducer,
  requestors: requestorReducer,
  vehicles: vehicleReducer,
  groups: groupsReducer,
  operators: operatorReducer,
  approvers: approverReducer,
  siteManagers: siteManagerReducer,
  drivers: driverReducer,
  tanks: tankReducer,
  dispensers: dispenserReducer,
  cards: cardReducer,
  transactions: transactionsReducer,
  reports: reportsReducer,
  settings: settingsReducer,
  cardApprovals: cardApprovalsReducer,
  vehicleBreakDown: vehicleBreakDownReducer,
  sensorTransactions: sensorTransactionReducer
};

// Combine all reducers.
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  let globalStore = { ...state };
  // Clear all data in redux store to initial state.
  if (action.type === RESET_STORE) {
    globalStore = undefined;
  }
  return appReducer(globalStore, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    if (process.env.NODE_ENV === 'development') {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  }
});

export default store;
