import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import ROUTE from 'src/utils/routeConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getTankList, loadMoreTanks } from 'src/redux/tankSlice';
import Loader from 'src/common/Loader';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { tankTableHeaders } from 'src/utils/tableHeaders';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';
import { getFuelQuantity } from 'src/utils/getInitials';

const TankListTable = ({ refreshData, searchText, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { loading, tankList, totalCount } = useSelector((state) => state.tanks);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');

  useEffect(() => {
    if (tankList.length === 0 || refreshData) {
      dispatch(getTankList({ limit }));
    }
  }, []);

  useEffect(() => {
    if (mounted.current) {
      dispatch(getTankList({ limit, pageNo: 1, params: { name: searchText } }));
    }
    mounted.current = true;
  }, [searchText]);

  const loadMoreData = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > tankList.length) {
      dispatch(loadMoreTanks({ limit: newLimit, pageNo: newPage }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getTankList(newLimit));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    loadMoreData(newPage, limit);
  };

  const viewTankInfo = (tankId) => {
    history.push(`${ROUTE.tanks}/${tankId}`);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={tankTableHeaders}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {tankList
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((tank) => (
                      <TableRow hover key={tank.id}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {tank.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{tank.type}</TableCell>
                        <TableCell>{getFuelQuantity(tank.current_balance)}</TableCell>
                        <TableCell>
                          <Chip
                            label={tank.automation ? 'Enabled' : 'Disabled'}
                            color={tank.automation ? 'success' : 'error'}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => viewTankInfo(tank.id)}
                            size="small"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </Card>
      )}
    </>
  );
};

TankListTable.propTypes = {
  refreshData: PropTypes.bool,
  searchText: PropTypes.string
};

export default TankListTable;
