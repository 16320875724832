import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { SwitchInput } from 'src/common/FormElements';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import Loader from 'src/common/Loader';
import FMSModal from 'src/common/FMSModal';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import { getAllSites } from 'src/redux/siteSlice';
import { addTank, updateTank } from 'src/redux/tankSlice';
import theme from 'src/theme';
import {
  tankFormInitialValues,
  tankFormModel,
  tankValidationSchema
} from './tankFormModel';

const NewTank = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { allSites } = useSelector((state) => state.sites);
  const formInitValues = initialValues || tankFormInitialValues;
  const {
    formId,
    formField: {
      siteName,
      tankType,
      article,
      tankName,
      tankCode,
      sensorId,
      maxVolume,
      minVolume,
      maxLevel,
      minLevel,
      automation
    }
  } = tankFormModel;

  useEffect(async () => {
    if (open) {
      dispatch(getAllSites());
    }
  }, [open]);

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateTank(values)).unwrap();
      } else {
        await dispatch(addTank(values)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Tank details updated successfully!'
            : 'New tank has been created successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        {initialValues ? 'Update Tank Details' : 'Add New Tank'}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={tankValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={siteName.name}
                        label={siteName.label}
                        data={allSites}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={tankType.name}
                        label={tankType.label}
                        data={tankType.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={tankName.name}
                        label={tankName.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={tankCode.name}
                        label={tankCode.label}
                        upperCase
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={article.name}
                        label={article.label}
                        data={article.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={sensorId.name}
                        label={sensorId.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={maxVolume.name}
                        label={maxVolume.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={minVolume.name}
                        label={minVolume.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={maxLevel.name}
                        label={maxLevel.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={minLevel.name}
                        label={minLevel.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SwitchInput
                        name={automation.name}
                        label={automation.label}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {initialValues ? 'Update Tank' : 'Add Tank'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

NewTank.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewTank;
