import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import { filterTanksBySite } from 'src/redux/tankSlice';
import { useFormikContext } from 'formik';

export default function DispenserDetailsForm(props) {
  const {
    formField: {
      site,
      tankId,
      dispenserMode,
      serialNumber,
      password1,
      password2,
      stationName,
      vehicleNumber,
      updatedField
    },
    initValues
  } = props;
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const formikProps = useFormikContext();
  const { allSites } = useSelector((state) => state.sites);
  const { allTanks, tankListBySite } = useSelector((state) => state.tanks);

  // filter tank list based on the selected site id
  useEffect(() => {
    if (mounted.current && formikProps.values.site) {
      dispatch(filterTanksBySite(formikProps.values.site));
    }
    mounted.current = true;
  }, [formikProps.values.site, allTanks]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={site.name}
          label={site.label}
          data={allSites}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={tankId.name}
          label={tankId.label}
          data={tankListBySite}
          disabled={!formikProps.values.site}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={stationName.name}
          label={stationName.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={serialNumber.name}
          label={serialNumber.label}
          disabled={initValues}
          upperCase
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={dispenserMode.name}
          label={dispenserMode.label}
          data={dispenserMode.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={vehicleNumber.name}
          label={vehicleNumber.label}
          // upperCase
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={updatedField.name}
          label={updatedField.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={password1.name} label={password1.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={password2.name} label={password2.label} fullWidth />
      </Grid>
    </Grid>
  );
}

DispenserDetailsForm.propTypes = {
  formField: PropTypes.object.isRequired,
  initValues: PropTypes.bool,
};
