import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { formatCardDetails } from 'src/components/cards/formModel/cardFormUtils';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  totalCount: 0,
  cardList: [],
  allCards: [],
  cardDetails: {},
  loading: false,
};

export const getCardList = createAsyncThunk(
  'cards/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getCardList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreCards = createAsyncThunk(
  'cards/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getCardList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllCards = createAsyncThunk(
  'cards/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllCards();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getCardDetails = createAsyncThunk(
  'cards/details', async (cardId, { rejectWithValue }) => {
    try {
      const res = await APIService.getCardDetails(cardId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateCardDetails = createAsyncThunk(
  'cards/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateCardDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewCard = createAsyncThunk(
  'cards/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createCard(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// remove inactive cards from card list
export const getActiveCardsList = (state) => state.cards.allCards.filter((card) => card.active === true);

const getFormattedCardList = (data) => {
  const updatedCardList = data.map((card) => ({ ...card, ...card.vehicle_info }));
  return updatedCardList;
};

/**
 * Remove duplicate vehicle numbers from card list
 *
 * @param {Object} state
 * @return {Array} newVehicleList
 *
 */
export const getCardLinkedVehiclesList = (state) => {
  const newVehicleList = state.cards.allCards.filter(
    (card, index, self) => self.findIndex(
      (item) => item.vehicle_number === card.vehicle_number && item.active === true
    ) === index
  );
  return newVehicleList;
};

export const cardSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllCards.fulfilled, (state, action) => ({
        ...state,
        allCards: getFormattedCardList(action.payload),
      }))
      .addCase(getCardList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        cardList: action.payload.results
      }))
      .addCase(getCardList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getCardList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreCards.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        cardList: action.payload.results
      }))
      .addCase(loadMoreCards.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreCards.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getCardDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        cardDetails: formatCardDetails(action.payload)
      }))
      .addCase(getCardDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getCardDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateCardDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        cardDetails: formatCardDetails(action.payload),
        cardList: updateItemInArray(state.cardList, {
          ...action.payload,
          vehicle_number: action.payload?.vehicle_reg_number
        })
      }))
      .addCase(addNewCard.fulfilled, (state, action) => {
        state.cardList.push({
          ...action.payload,
          vehicle_number: action.payload?.vehicle_reg_number
        });
      });
  }
});

export default cardSlice.reducer;
