import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, TextField } from '@mui/material';
import { useField, useFormikContext } from 'formik';

const AutocompleteField = (props) => {
  const {
    name, label, options, ...selectProps
  } = props;
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;
  const { setFieldValue } = useFormikContext();

  const renderHelperText = () => {
    if (meta.touched && meta.error) {
      return meta.error;
    }
    return null;
  };

  const handleInputChange = (_, newValue) => {
    const fieldValue = newValue?.id || '';
    setFieldValue(name, fieldValue);
  };

  const autoCompletedValue = () => {
    let curValue = null;
    if (selectedValue) {
      curValue = options.find((option) => option.id === selectedValue);
    }
    return curValue;
  };

  return (
    <Autocomplete
      {...selectProps}
      options={options}
      onChange={handleInputChange}
      value={autoCompletedValue()}
      renderInput={(inputProps) => (
        <TextField
          {...inputProps}
          label={label}
          value={selectedValue == null ? '' : selectedValue}
          error={Boolean(meta.touched && meta.error)}
          helperText={renderHelperText()}
        />
      )}
    />
  );
};

AutocompleteField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.array
};

export default AutocompleteField;
