import transactionFormModel from './transactionFormModel';

const {
  formField: {
    orderDate,
    deliveryDate,
    quantity,
    deliveredDipQuantity,
    deliveredMeasuredQuantityFLS,
    deliveredMeasuredQuantityMeter,
    temperatureDuringLoading,
    temperatureDuringUnloading,
    pricePerLitre,
    supplier,
    invoiceNumber,
    remarks,
    spillageQuantity,
    shortageQuantity,
    deliveryVehicleNumber,
    densityDuringLoading,
    densityDuringUnloading,
    siteId,
    sourceTank,
    destTank,
  }
} = transactionFormModel;

export default {
  [orderDate.name]: '',
  [deliveryDate.name]: '',
  [quantity.name]: '',
  [deliveredDipQuantity.name]: '0',
  [deliveredMeasuredQuantityFLS.name]: '0',
  [deliveredMeasuredQuantityMeter.name]: '0',
  [temperatureDuringLoading.name]: '0',
  [temperatureDuringUnloading.name]: '0',
  [pricePerLitre.name]: '',
  [supplier.name]: '',
  [invoiceNumber.name]: '',
  [remarks.name]: '',
  [spillageQuantity.name]: '0',
  [shortageQuantity.name]: '0',
  [deliveryVehicleNumber.name]: '',
  [densityDuringLoading.name]: '0',
  [densityDuringUnloading.name]: '0',
  [siteId.name]: '',
  [sourceTank.name]: '',
  [destTank.name]: '',
};
