import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import SelectField from 'src/common/formFields/SelectField';
import DatePickerField from 'src/common/formFields/DatePickerField';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import {
  Form, FormikProvider, useFormik
} from 'formik';
import { BASEAPI } from 'src/api/api';
import Loader from 'src/common/Loader';
import { getAllSites } from 'src/redux/siteSlice';
import { filterVehiclesBySite, getAllVehicles, getWailonVehicleList } from 'src/redux/vehicleSlice';
import {
  filterDispenserBySite,
  getAllDispensers
} from 'src/redux/dispenserSlice';
import { getAllVehicleGroupList } from 'src/redux/groupSlice';
import { DATE_FORMAT, REPORT_FILTER_OPTIONS } from 'src/utils/constants';
import { getAllTanks, filterTanksBySite } from 'src/redux/tankSlice';
import AutocompleteField from 'src/common/formFields/AutocompleteField';
import { getAllCards } from 'src/redux/cardSlice';
import useDidUpdate from 'src/hooks/useDidUpdate';
import { addOtherReportData } from 'src/redux/reportSlice';
import { getFormattedReportURL } from './reportService';

function ReportFilters({
  title,
  downloadUrl,
  filterConfig = REPORT_FILTER_OPTIONS,
  formModel,
  isViewReport,
  datePickerProps,
  formValidation,
  formInitValues
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [viewReport, setViewReport] = useState(false);
  const { allSites } = useSelector((state) => state.sites);
  const { vehicleRegNumListBySite, wailonVehicleList } = useSelector((state) => state.vehicles);
  const { dispenserListBySite } = useSelector((state) => state.dispensers);
  const { allVehicleGroups } = useSelector((state) => state.groups);
  const { tankListBySite } = useSelector((state) => state.tanks);
  const { allCards } = useSelector((state) => state.cards);

  const {
    formId,
    formField: {
      site,
      tank,
      address,
      assetType,
      requestStatus,
      cardNumber,
      vehicleGroup,
      vehicleNumber,
      wailonVehicleNumber,
      startTime,
      endTime
    }
  } = formModel;

  useEffect(() => {
    setError(false);
    if (filterConfig.site) {
      dispatch(getAllSites());
    }
    if (filterConfig.vehicle) {
      dispatch(getAllVehicles());
    }
    if (filterConfig.wailonVehicle) {
      dispatch(getWailonVehicleList());
    }
    if (filterConfig.dispenser) {
      dispatch(getAllDispensers());
    }
    if (filterConfig.vehicleGroup) {
      dispatch(getAllVehicleGroupList());
    }
    if (filterConfig.tank) {
      dispatch(getAllTanks());
    }
    if (filterConfig.card) {
      dispatch(getAllCards());
    }
  }, [filterConfig]);

  const viewReportList = (data) => {
    const API_URL = downloadUrl.replace('export=true', 'export=false');
    const apiURL = getFormattedReportURL(API_URL, title, data, startTime, endTime);
    BASEAPI.get(apiURL)
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        const tableData = res.data.result?.results || res.data.result;
        if (tableData.length > 0) {
          dispatch(addOtherReportData(tableData));
        } else {
          setError('No data available! Try with different filters.');
        }
      })
      .catch((err) => {
        const errMsg = err.status === 204 ? 'No data available! Try with different filters.'
          : 'Error viewing the report. Try again later!';
        setError(errMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadReport = async (values) => {
    setLoading(true);
    setError(false);
    if (viewReport) {
      viewReportList(values);
      return;
    }
    const apiURL = getFormattedReportURL(downloadUrl, title, values, startTime, endTime);
    BASEAPI.get(apiURL, { responseType: 'blob', timeout: 20000 })
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        const fileName = `${title.toLowerCase()} ${moment().format(DATE_FORMAT.reportDateTime)}.xlsx`;
        saveAs(res.data, fileName);
      })
      .catch((err) => {
        const errMsg = err.status === 204 ? 'No data available! Try with different filters.'
          : 'Error downloading the report. Try again later!';
        setError(errMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const formikProps = useFormik({
    initialValues: formInitValues,
    validationSchema: formValidation,
    enableReinitialize: true,
    onSubmit: downloadReport,
  });

  const handleFormSubmit = (jsEvent) => {
    // This is a bit of a hack to copy the name and value of
    // the submitted action name into the Formik form
    const { name } = jsEvent.nativeEvent.submitter;
    const isView = name === 'view';
    setViewReport(isView);
    return formikProps.handleSubmit(jsEvent);
  };

  // filter tank list based on site id on site change
  const onSiteChange = (selectedSiteId) => {
    if (filterConfig.vehicle) {
      dispatch(filterVehiclesBySite(selectedSiteId));
      formikProps.setFieldValue(vehicleNumber.name, 'all');
    }
    if (filterConfig.dispenser) {
      dispatch(filterDispenserBySite(selectedSiteId));
      formikProps.setFieldValue(address.name, 'all');
    }
    if (filterConfig.tank) {
      dispatch(filterTanksBySite(selectedSiteId));
      formikProps.setFieldValue(tank.name, 'all');
    }
  };

  useDidUpdate(() => {
    onSiteChange(formikProps.values[site?.name]);
  }, [formikProps.values[site?.name]]);

  useDidUpdate(() => {
    formikProps.resetForm();
    if (startTime) { formikProps.setFieldValue(startTime.name, null); }
    if (endTime) { formikProps.setFieldValue(endTime.name, null); }
  }, [downloadUrl]);

  return (
    <Card>
      <Box sx={{
        px: 3,
        py: 2,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
      >
        <Typography variant="h5" sx={{ pr: 0.5 }}>Report Filters</Typography>
        <FilterIcon sx={{ fontSize: 18 }} />
      </Box>
      <Divider />
      <FormikProvider value={formikProps}>
        <Form id={formId} onSubmit={handleFormSubmit}>
          {loading ? (
            <Loader />
          ) : (
            <DialogContent sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                {filterConfig.site && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...site}
                    data={[...site.data, ...allSites]}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.dispenser && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...address}
                    data={[...address.data, ...dispenserListBySite]}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.tank && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...tank}
                    data={[...tank.data, ...tankListBySite]}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.card && (
                <Grid item xs={12} sm={4}>
                  <AutocompleteField
                    name={cardNumber.name}
                    label={cardNumber.label}
                    options={[...cardNumber.data, ...allCards]}
                    getOptionLabel={(option) => option.uid}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.wailonVehicle && (
                <Grid item xs={12} sm={4}>
                  <AutocompleteField
                    name={wailonVehicleNumber.name}
                    label={wailonVehicleNumber.label}
                    options={wailonVehicleList}
                    getOptionLabel={(option) => option?.registration_num}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.vehicle && (
                <Grid item xs={12} sm={4}>
                  <AutocompleteField
                    name={vehicleNumber.name}
                    label={vehicleNumber.label}
                    options={[...vehicleNumber.data, ...vehicleRegNumListBySite]}
                    getOptionLabel={(option) => option.registration_num}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.vehicleGroup && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...vehicleGroup}
                    data={[...vehicleGroup.data, ...allVehicleGroups]}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.assetType && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...assetType}
                    data={assetType.data}
                    fullWidth
                  />
                </Grid>
                )}
                {filterConfig.requestStatus && (
                <Grid item xs={12} sm={4}>
                  <SelectField
                    {...requestStatus}
                    fullWidth
                  />
                </Grid>
                )}
                {startTime && (
                <Grid item xs={12} sm={4}>
                  <DatePickerField
                    {...startTime}
                    {...datePickerProps}
                    disableFuture
                  />
                </Grid>
                )}
                {endTime && (
                <Grid item xs={12} sm={4}>
                  <DatePickerField
                    {...endTime}
                    {...datePickerProps}
                    disableFuture
                  />
                </Grid>
                )}
              </Grid>
            </DialogContent>
          )}
          {error && (
            <Typography
              variant="subtitle1"
              sx={{ color: 'red', pt: 2, textAlign: 'center' }}
            >
              {error || ''}
            </Typography>
          )}
          <DialogActions sx={{ m: 2 }}>
            {isViewReport && (
              <Button
                color="secondary"
                type="submit"
                name="view"
                disabled={formikProps.isSubmitting}
                size="large"
                variant="contained"
              >
                View Report
              </Button>
            )}
            <Button
              color="primary"
              type="submit"
              name="export"
              disabled={formikProps.isSubmitting}
              size="large"
              variant="contained"
            >
              Export
            </Button>
          </DialogActions>
        </Form>
      </FormikProvider>
    </Card>
  );
}

ReportFilters.propTypes = {
  filterConfig: PropTypes.object,
  datePickerProps: PropTypes.object,
  formModel: PropTypes.object.isRequired,
  formValidation: PropTypes.object.isRequired,
  formInitValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isViewReport: PropTypes.bool,
  downloadUrl: PropTypes.string.isRequired,
};

export default ReportFilters;
