import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
// import { SwitchInput } from 'src/common/FormElements';
import DatePickerField from 'src/common/formFields/DatePickerField';
import { useFormikContext } from 'formik';

export default function CardConfigForm(props) {
  const {
    formField: {
      fillLimitType,
      perFillingLimit,
      perDayLimit,
      limitQuantity,
      limitStartDate,
      limitEndDate
    }
  } = props;
  const { setFieldValue, initialValues, values } = useFormikContext();
  const [disableLimit, setDisableLimit] = useState(true);

  const resetAndDisableLimitDate = (value) => {
    const startDate = values[limitStartDate.name] || initialValues[limitStartDate.name];
    const endDate = values[limitEndDate.name] || initialValues[limitEndDate.name];
    if (value === 'Per Duration') {
      setDisableLimit(false);
      setFieldValue(limitStartDate.name, startDate);
      setFieldValue(limitEndDate.name, endDate);
    } else {
      setDisableLimit(true);
      setFieldValue(limitStartDate.name, null);
      setFieldValue(limitEndDate.name, null);
    }
  };

  useEffect(() => {
    resetAndDisableLimitDate(values[fillLimitType.name]);
  }, [values[fillLimitType.name]]);

  const onLimitTypeChange = (e) => {
    const selectedValue = e.target.value;
    setFieldValue(fillLimitType.name, selectedValue);
    resetAndDisableLimitDate(selectedValue);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={fillLimitType.name}
          label={fillLimitType.label}
          data={fillLimitType.data}
          fullWidth
          onChange={onLimitTypeChange}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={perFillingLimit.name}
          label={perFillingLimit.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={perDayLimit.name}
          label={perDayLimit.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={limitQuantity.name}
          label={limitQuantity.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={limitStartDate.name}
          label={limitStartDate.label}
          disabled={disableLimit}
          onlyDate
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={limitEndDate.name}
          label={limitEndDate.label}
          disabled={disableLimit}
          onlyDate
          fullWidth
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <SwitchInput name={attached.name} label={attached.label} fullWidth />
      </Grid> */}
    </Grid>
  );
}

CardConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
