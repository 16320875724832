const API_URL = {
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  login: 'login/',
  siteManagerLogin: 'auth-users/sign-in/site-manager/',
  approverLogin: 'auth-users/sign-in/approver/',
  logout: 'logout/',
  createSite: 'sites/add/',
  siteList: 'sites/',
  siteDetails: 'sites/detail/{siteId}/',
  deleteSite: 'sites/delete/{siteId}/',
  updateSite: 'sites/update/{siteId}/',
  createRequestor: 'auth-users/supervisors/',
  requestorList: 'auth-users/supervisors/',
  requestorDetails: 'auth-users/supervisors/{userId}/',
  deleteRequestor: 'auth-users/supervisors/{userId}/',
  updateRequestor: 'auth-users/supervisors/{userId}/',
  createGroup: 'vehicles/{groupName}/',
  groupList: 'vehicles/{groupName}/',
  getGroupUrl: (groupName, groupId) => `vehicles/${groupName}/${groupId}/`,
  createVehicle: 'vehicles/vehicle/',
  vehicleList: 'vehicles/vehicle/',
  vehicleDetails: 'vehicles/vehicle/{vehicleId}/',
  deleteVehicle: 'vehicles/vehicle/{vehicleId}/',
  updateVehicle: 'vehicles/vehicle/{vehicleId}/',
  createOperator: 'auth-users/operators/',
  operatorList: 'auth-users/operators/',
  operatorDetails: 'auth-users/operators/{userId}/',
  deleteOperator: 'auth-users/operators/{userId}/',
  updateOperator: 'auth-users/operators/{userId}/',
  createTank: 'tanks/tank/',
  tankList: 'tanks/tank/',
  tankDetails: 'tanks/tank/{tankId}/',
  deleteTank: 'tanks/tank/{tankId}/',
  updateTank: 'tanks/tank/{tankId}/',
  createDispenser: 'tanks/dispenser/',
  dispenserList: 'tanks/dispenser/',
  dispenserDetails: 'tanks/dispenser/{dispenserId}/',
  deleteDispenser: 'tanks/dispenser/{dispenserId}/',
  updateDispenser: 'tanks/dispenser/{dispenserId}/',
  resetDispenserCards: 'tanks/reset-card-mappers/{dispenserId}/',
  createCard: 'cards/add/',
  cardList: 'cards/',
  cardDetails: 'cards/detail/{cardId}/',
  deleteCard: 'cards/delete/{cardId}/',
  updateCard: 'cards/update/{cardId}/',
  createDriver: 'auth-users/drivers/',
  driverList: 'auth-users/drivers/',
  driverDetails: 'auth-users/drivers/{driverId}/',
  deleteDriver: 'auth-users/drivers/{driverId}/',
  updateDriver: 'auth-users/drivers/{driverId}/',
  createApprover: 'auth-users/approver/',
  approverList: 'auth-users/approver/',
  approverDetails: 'auth-users/approver/{userId}/',
  updateApprover: 'auth-users/approver/{userId}/',
  createSiteManager: 'auth-users/site-manager/',
  siteManagerList: 'auth-users/site-manager/',
  siteManagerDetails: 'auth-users/site-manager/{userId}/',
  updateSiteManager: 'auth-users/site-manager/{userId}/',
  createTransaction: 'transaction/{transactionType}/add/',
  transactionList: 'transaction/{transactionType}/list/',
  salesTransactionReport: 'transaction/iot/list/?export=true',
  inwardTransactionReport: 'transaction/inward/list/?export=true',
  transferTransactionReport: 'transaction/transfer/list/?export=true',
  transactionDetails: (transactionType, transactionId) => `transaction/${transactionType}/detail/${transactionId}/`,
  updateTransaction: (transactionType, transactionId) => `transaction/${transactionType}/update/${transactionId}/`,
  deleteTransaction: (transactionType, transactionId) => `transaction/${transactionType}/delete/${transactionId}/`,
  dashboardCount: 'dashboard/count/',
  transactionChart: 'dashboard/transaction-count/?site={siteId}',
  terminalWiseDispenseChart: 'dashboard/dispenser-fuel-issue-count/?site={siteId}',
  topFuelConsumptionChart: '/dashboard/consumer-vehicle-fuel-consumption/?days={days}',
  vehicleFuelConsumptionChart: 'dashboard/top-vehicle-fuel-consumption/?days={days}',
  allVehicles: 'vehicle-list/',
  allOperators: 'operator-list/',
  allDrivers: 'driver-list/',
  allSites: 'site-list/',
  allTanks: 'tank-list/',
  allCards: 'card-list/',
  allRequestors: 'supervisor-list/',
  allApprovers: 'approver-list/',
  allSiteManagers: 'site-manager-list/',
  allDispensers: 'dispenser-list/',
  milageReport: 'report/mileage/',
  reconciliationReport: 'report/reconciliation/',
  vehicleGroupWiseReport: 'transaction/iot/group-transaction-list/?export=true',
  cardLimitHistory: 'cards/limit-request-list/',
  changeCardLimit: 'cards/limit-request/',
  approveCardLimit: 'cards/limit-request-approve-reject/',
  cardLimitRequestCount: 'cards/limit-request-count/',
  cancelCardLimitRequest: 'cards/limit-request-delete/{requestId}/',
  getAllVehicleGroup: 'vehicle-group-list/',
  getAllDepartmentGroup: 'vehicle-department-list/',
  resetPassword: 'reset-password/',
  breakDownList: 'vehicles/breakdown-list/',
  createBreakDown: 'vehicles/create-breakdown/',
  approveBreakDown: 'vehicles/breakdown-complete-reject/',
  breakdownReport: 'vehicles/breakdown-list/?export=true',
  vehicleStatus: 'dashboard/vehicle-ignition-status-count/',
  vehicleUtilizationChart: 'dashboard/vehicle-performance/',
  shift: 'sites/shift/',
  updateShift: 'sites/shift/{shiftID}/',
  // Wailon API's
  getVehicleFuelLevel: 'vehicles/get-fuel-level/{vehicleID}/',
  getWailonTransactions: 'transaction/wailon/list/',
  sensorTransactionReport: 'transaction/wailon/list/?export=true',
  vehicleSummaryReport: 'report/vehicle-summary/{vehicleId}/?export=true',
  wailonVehicleList: 'wailon-vehicle-list/',
  vehicleUtilizationReport: 'report/vehicle-utilisation-report/?export=true',
  groupWiseComparisonReport: 'report/group-wise-vehicle-consumption/?export=true'
};

export default API_URL;
