import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import ROUTE from 'src/utils/routeConstants';
import { getDispenserList, loadMoreDispensers } from 'src/redux/dispenserSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import { dispenserTableHeaders } from 'src/utils/tableHeaders';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';

const DispenserListTable = ({ refreshData, searchText, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { loading, dispenserList, totalCount } = useSelector((state) => state.dispensers);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('station_name');

  useEffect(() => {
    if (dispenserList.length === 0 || refreshData) {
      dispatch(getDispenserList({ limit }));
    }
  }, []);

  useEffect(() => {
    if (mounted.current) {
      dispatch(getDispenserList({ limit, pageNo: 1, params: { station_name: searchText } }));
    }
    mounted.current = true;
  }, [searchText]);

  const loadMoreData = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > dispenserList.length) {
      dispatch(loadMoreDispensers({ limit: newLimit, pageNo: newPage }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getDispenserList(newLimit));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    loadMoreData(newPage, limit);
  };

  const viewDispenserInfo = (dispenserId) => {
    history.push(`${ROUTE.dispensers}/${dispenserId}`);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={dispenserTableHeaders}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {dispenserList
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((dispenser) => (
                      <TableRow hover key={dispenser.id}>
                        <TableCell>
                          <Box sx={{ alignItems: 'center', display: 'flex' }}>
                            <Typography color="textPrimary" variant="body1">
                              {dispenser.station_name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{dispenser.address}</TableCell>
                        <TableCell>{dispenser.site_name}</TableCell>
                        <TableCell>{dispenser.mode}</TableCell>
                        <TableCell>{dispenser.article}</TableCell>
                        <TableCell>
                          <Chip
                            label={dispenser.status ? 'Active' : 'Inactive'}
                            color={dispenser.status ? 'success' : 'error'}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => viewDispenserInfo(dispenser.id)}
                            size="small"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </Card>
      )}
    </>
  );
};

DispenserListTable.propTypes = {
  refreshData: PropTypes.bool,
  searchText: PropTypes.string
};

export default DispenserListTable;
