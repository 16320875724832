import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getReconciliationList, loadMoreReconciliationList } from 'src/redux/reportSlice';
import Loader from 'src/common/Loader';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { reconciliationReportTableHeaders } from 'src/utils/tableHeaders';
import { getFormattedDate, getFuelQuantity, getReconciliationQueryParams } from 'src/utils/getInitials';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';

const ReconciliationReportTable = ({
  refreshData,
  searchText,
  siteFilter,
  dateFilter,
  ...rest
}) => {
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { loading, reconciliationList, totalCount } = useSelector(
    (state) => state.reports
  );
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('date');

  useEffect(() => {
    if (reconciliationList.length === 0 || refreshData) {
      dispatch(getReconciliationList({ limit }));
    }
  }, []);

  useEffect(() => {
    if (mounted.current) {
      dispatch(
        getReconciliationList({
          limit,
          params: getReconciliationQueryParams(searchText, siteFilter, dateFilter)
        })
      );
    }
    mounted.current = true;
  }, [searchText, siteFilter, dateFilter]);

  const loadMoreItems = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > reconciliationList.length) {
      dispatch(loadMoreReconciliationList({
        limit: newLimit,
        pageNo: newPage,
        params: getReconciliationQueryParams(searchText, siteFilter, dateFilter)
      }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getReconciliationList({
      limit: newLimit,
      params: getReconciliationQueryParams(searchText, siteFilter, dateFilter)
    }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    loadMoreItems(newPage, limit);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={reconciliationReportTableHeaders}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {reconciliationList
                    .slice()
                    .sort(getComparator(order, orderBy))
                    // .slice(page * limit, page * limit + limit)
                    .map((item) => (
                      <TableRow hover key={item.id}>
                        <TableCell>
                          <Box sx={{ alignItems: 'center', display: 'flex' }}>
                            <Typography color="textPrimary" variant="body1">
                              {item.id}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{item.station_name}</TableCell>
                        <TableCell>{item.site_name}</TableCell>
                        <TableCell>
                          {item.date
                            ? getFormattedDate(item.date, 'DD/MM/YYYY')
                            : 'NA'}
                        </TableCell>
                        <TableCell>{getFuelQuantity(item.opening_value)}</TableCell>
                        <TableCell>{getFuelQuantity(item.inward)}</TableCell>
                        <TableCell>{getFuelQuantity(item.transfer)}</TableCell>
                        <TableCell>{getFuelQuantity(item.sales)}</TableCell>
                        <TableCell>{getFuelQuantity(item.stock)}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            showFirstButton
            showLastButton
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </Card>
      )}
    </>
  );
};

ReconciliationReportTable.propTypes = {
  refreshData: PropTypes.bool,
  searchText: PropTypes.string,
  dateFilter: PropTypes.string,
  stationFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  siteFilter: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default ReconciliationReportTable;
