import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  vehicleBreakDownList: [],
  totalCount: 0,
  loading: false,
};

export const getVehicleBreakDownList = createAsyncThunk(
  'vehicles/breakdown/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getVehicleBreakDownList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreVehicleBreakDownList = createAsyncThunk(
  'vehicle/breakdown/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getVehicleBreakDownList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const createVehicleBreakDown = createAsyncThunk(
  'vehicles/breakdown/create', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.createVehicleBreakDown(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const reviewVehicleBreakDown = createAsyncThunk(
  'vehicles/breakdown/review', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.approveVehicleBreakDown(data);
      if (!res.success) throw res;
      return data;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const vehicleBreakDownSlice = createSlice({
  name: 'vehicleBreakDown',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getVehicleBreakDownList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getVehicleBreakDownList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getVehicleBreakDownList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        vehicleBreakDownList: action.payload.results,
      }))
      .addCase(createVehicleBreakDown.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        vehicleBreakDownList: [...state.vehicleBreakDownList, action.payload]
      }))
      .addCase(reviewVehicleBreakDown.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        vehicleBreakDownList: updateItemInArray(state.vehicleBreakDownList, action.payload)
      }));
  }
});

export default vehicleBreakDownSlice.reducer;
