/* eslint-disable no-param-reassign */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import {
  Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel
} from '@mui/material';

const MultiCheckboxField = (props) => {
  const {
    data, name, label, handelOnChange, ...rest
  } = props;
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const { touched, error } = meta;
  const isError = touched && error;
  const { value } = field;

  useEffect(() => {
    // reset data on load
    data.forEach((el, index) => {
      if (value.includes(String(index))) {
        el.checked = true;
      } else {
        el.checked = false;
      }
    });
  }, []);

  const handleChange = (e, index) => {
    data[index].checked = !!e.target.checked;
    const selectedValues = data.filter((el) => el.checked).map((el) => el.id);
    formikProps.setFieldValue(name, selectedValues);
    if (handelOnChange) handelOnChange(e);
  };

  return (
    <FormControl {...rest} component="fieldset" variant="standard">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {data.map((category, index) => (
          <FormControlLabel
            key={category.id}
            label={category.name}
            value={category.id}
            checked={value.includes(category.id)}
            onChange={(e) => handleChange(e, index)}
            control={<Checkbox />}
          />
        ))}
      </FormGroup>
      {isError && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
};

MultiCheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handelOnChange: PropTypes.func
};

export default MultiCheckboxField;
