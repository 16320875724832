import { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Redirect, Route, Switch
} from 'react-router-dom';
import SiteList from 'src/pages/SiteList';
import Dashboard from 'src/pages/Dashboard';
import Tanks from 'src/pages/Tanks';
import Cards from 'src/pages/Cards';
import OtherReports from 'src/pages/OtherReports';
import Profile from 'src/pages/Profile';
import ScrollToTop from 'src/common/ScrollToTop';
import SuccessModal from 'src/common/SuccessModal';
import Vehicles from 'src/pages/Vehicles';
import Groups from 'src/pages/Groups';
import Operators from 'src/pages/Operators';
import DashboardNavbar, { SIDE_NAV_WIDTH } from 'src/components/DashboardNavbar';
import DashboardSidebar from 'src/components/DashboardSidebar';
import SiteDetails from 'src/components/sites/SiteDetails';
import VehicleDetails from 'src/components/vehicles/VehicleDetails';
import OperatorDetails from 'src/components/operators/OperatorDetails';
import TankDetails from 'src/components/tanks/TankDetails';
import ROUTE from 'src/utils/routeConstants';
import Dispensers from 'src/pages/Dispensers';
import DispenserDetails from 'src/components/dispensers/DispenserDetails';
import CardDetails from 'src/components/cards/CardDetails';
import Drivers from 'src/pages/Drivers';
import DriverDetails from 'src/components/drivers/DriverDetails';
import Transactions from 'src/pages/Transactions';
import TransactionDetails from 'src/components/transactions/TransactionDetails';
import CardApprovals from 'src/pages/CardApprovals';
import ReconciliationReport from 'src/pages/ReconciliationReport';
import useAuth from 'src/context/AuthContext';
import { isAdminUser } from 'src/utils/getInitials';
import Requestors from 'src/pages/Requestors';
import RequestorDetails from 'src/components/requestors/RequestorDetails';
import SiteManagers from 'src/pages/SiteManager';
import SiteManagerDetails from 'src/components/siteManager/SiteManagerDetails';
import Approvers from 'src/pages/Approvers';
import ApproverDetails from 'src/components/approvers/ApproverDetails';
import { USER_ROLES } from 'src/utils/constants';
import ProtectedRoute from 'src/common/ProtectedRoute';
import VehicleBreakdown from 'src/pages/VehicleBreakdown';
// import SensorTransactions from 'src/pages/SensorTransactions';
import Settings from 'src/pages/Settings';
import VehicleStatusList from 'src/pages/VehicleStatusList';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingLeft: SIDE_NAV_WIDTH
  }
}));

const MainLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  flexDirection: 'column',
  width: '100%'
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isSupervisor = user?.role === USER_ROLES.siteManager;
  const isRequestor = user?.role === USER_ROLES.requestor;
  const isApprover = user?.role === USER_ROLES.approver;

  return (
    <>
      <DashboardNavbar onNavOpen={() => setMobileNavOpen(true)} />
      <DashboardSidebar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <SuccessModal />
      <MainLayoutRoot>
        <MainLayoutContainer>
          <ScrollToTop />
          <Switch>
            <Route exact path={ROUTE.app} render={() => <Redirect to="/app/dashboard" />} />
            <Route exact path={ROUTE.dashboard} component={Dashboard} />
            <Route exact path={ROUTE.account} component={Profile} />
            <Route exact path={ROUTE.settings} component={Settings} />
            <Route exact path={ROUTE.vehicleStatusList} component={VehicleStatusList} />
            <Route exact path={ROUTE.sites} component={SiteList} />
            <Route exact path={ROUTE.siteDetails} component={SiteDetails} />
            <Route exact path={ROUTE.profile} component={Profile} />
            <Route exact path={ROUTE.vehicleBreakdown} component={VehicleBreakdown} />
            <Route path={ROUTE.reports}>
              <Route exact path={ROUTE.transactions} component={Transactions} />
              <Route exact path={ROUTE.transactionDetails} component={TransactionDetails} />
              <Route exact path={ROUTE.reconciliationReport} component={ReconciliationReport} />
              {/* <Route exact path={ROUTE.wailonTransactions} component={SensorTransactions} /> */}
              <Route exact path={ROUTE.otherReports} component={OtherReports} />
            </Route>
            <ProtectedRoute showRoute={isAdmin || isSupervisor} path={ROUTE.vehicleManagement}>
              <Route exact path={ROUTE.cards} component={Cards} />
              <Route exact path={ROUTE.cardDetails} component={CardDetails} />
              <Route exact path={ROUTE.groups} component={Groups} />
              <Route exact path={ROUTE.vehicles} component={Vehicles} />
              <Route exact path={ROUTE.vehicleDetails} component={VehicleDetails} />
            </ProtectedRoute>
            <ProtectedRoute showRoute={isApprover || isAdmin} exact path={ROUTE.cardApprovals} component={CardApprovals} />
            <ProtectedRoute showRoute={isRequestor} exact path={ROUTE.cardRequests} component={CardApprovals} />
            <ProtectedRoute showRoute={!isRequestor} exact path={ROUTE.tanks} component={Tanks} />
            <ProtectedRoute showRoute={!isRequestor} exact path={ROUTE.tankDetails} component={TankDetails} />
            <ProtectedRoute showRoute={isAdmin} exact path={ROUTE.dispensers} component={Dispensers} />
            <ProtectedRoute showRoute={isAdmin} exact path={ROUTE.dispenserDetails} component={DispenserDetails} />
            <ProtectedRoute showRoute={isAdmin} path={ROUTE.userManagement}>
              <Route exact path={ROUTE.approvers} component={Approvers} />
              <Route exact path={ROUTE.approverDetails} component={ApproverDetails} />
              <Route exact path={ROUTE.requestors} component={Requestors} />
              <Route exact path={ROUTE.requestorDetails} component={RequestorDetails} />
              <Route exact path={ROUTE.siteManagers} component={SiteManagers} />
              <Route exact path={ROUTE.siteManagerDetails} component={SiteManagerDetails} />
              <Route exact path={ROUTE.operators} component={Operators} />
              <Route exact path={ROUTE.operatorDetails} component={OperatorDetails} />
              <Route exact path={ROUTE.drivers} component={Drivers} />
              <Route exact path={ROUTE.driverDetails} component={DriverDetails} />
            </ProtectedRoute>
          </Switch>
        </MainLayoutContainer>
      </MainLayoutRoot>
    </>
  );
};

export default DashboardLayout;
