/* eslint-disable react/prop-types */
import React, { useContext, createContext, useState } from 'react';
import { clearLocalData, getLocalData, storeLocalData } from 'src/utils/getInitials';
import { useHistory } from 'react-router-dom';
import { APIService, setAPIAuthHeader } from 'src/api/api';
import { RESET_STORE, STORAGE_KEY } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getShiftDetails } from 'src/redux/settingSlice';

const authContext = createContext();

function useProvideAuth() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const { shiftDetails } = useSelector((state) => state.settings);

  const signin = (data) => {
    const userData = { ...data.user, role: data.role };
    setUser(userData);
    storeLocalData(STORAGE_KEY.user, userData);
    setAPIAuthHeader(data.token);
    storeLocalData(STORAGE_KEY.token, data.token, true);
  };

  const preloadSettings = () => {
    if (!shiftDetails?.id) {
      dispatch(getShiftDetails());
    }
  };

  const isAuth = () => {
    const token = getLocalData(STORAGE_KEY.token, false, true);
    if (token) {
      setAPIAuthHeader(token);
      preloadSettings();
    }
    return token;
  };

  const getUser = () => {
    let userInfo = user;
    if (!user) {
      userInfo = getLocalData(STORAGE_KEY.user, true);
    }
    return userInfo;
  };

  const signout = async () => {
    try {
      const body = { is_mobile: false };
      await APIService.userLogout(body);
      setUser(null);
      clearLocalData();
      dispatch({ type: RESET_STORE });
      history.replace('login');
    } catch (error) {
      console.log('logout error');
    }
  };

  return {
    getUser,
    isAuth,
    signin,
    signout
  };
}

export function AuthProvider({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function useAuth() {
  return useContext(authContext);
}
