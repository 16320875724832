import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { colors, Stack, TextField } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers';
import { DATE_FORMAT } from 'src/utils/constants';
import { useSelector } from 'react-redux';
import moment from 'moment';

export default function DatePickerField({
  label, name, disabled, dateFormat, onlyDate = false, ...rest
}) {
  const { shiftDetails } = useSelector((state) => state.settings);
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;
  let dateFormatSting = onlyDate ? DATE_FORMAT.unixDate : DATE_FORMAT.unixDateTime;
  if (dateFormat) {
    dateFormatSting = dateFormat;
  }
  const displayFormat = onlyDate ? DATE_FORMAT.date : DATE_FORMAT.dateTime;

  useEffect(() => {
    if (shiftDetails?.shift_starts_from && !selectedValue) {
      if (name.includes('gte')) {
        const startTime = moment(shiftDetails.shift_starts_from);
        const startDate = moment().hour(startTime.hour()).minute(startTime.minute()).seconds(0);
        const dateString = startDate.format(dateFormatSting);
        formikProps.setFieldValue(name, dateString, true);
      } else if (name.includes('lte')) {
        const startTime = moment(shiftDetails.shift_starts_from);
        const startDate = moment().hour(startTime.hour()).minute(startTime.minute()).seconds(0);
        const endDate = startDate.add(shiftDetails.shift_duration, 'hours');
        const dateString = endDate.format(dateFormatSting);
        formikProps.setFieldValue(name, dateString, true);
      }
    }
  }, []);

  const onDateChange = (date) => {
    try {
      const dateString = date.format(dateFormatSting);
      formikProps.setFieldValue(name, dateString, true);
    } catch (e) {
      formikProps.setFieldValue(name, null, true);
    }
  };

  function renderHelperText() {
    if (meta.touched && meta.error) {
      return meta.error;
    }
    return null;
  }

  const PickerComponent = onlyDate ? DatePicker : DateTimePicker;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Stack spacing={3}>
        <PickerComponent
          {...field}
          {...rest}
          onChange={onDateChange}
          label={label}
          inputFormat={displayFormat}
          value={selectedValue}
          readOnly={disabled}
          renderInput={(props) => (
            <TextField
              {...props}
              sx={{ backgroundColor: disabled ? colors.grey[200] : 'inherit' }}
              disabled={disabled}
              error={Boolean(meta.touched && meta.error)}
              helperText={renderHelperText()}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}

DatePickerField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  dateFormat: PropTypes.string,
  disabled: PropTypes.bool,
  onlyDate: PropTypes.bool
};
