import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { getRequestorDetails, updateRequestor } from 'src/redux/requestorSlice';
import { getStateName } from 'src/utils/getInitials';
import { CONFIRMATION_MSG } from 'src/utils/constants';
import InfoField from 'src/common/InfoField';
import ROUTE from 'src/utils/routeConstants';
import PasswordResetModal from 'src/common/PasswordResetModal';
import NewRequestor from './NewRequestor';

function RequestorDetails() {
  const { userId } = useParams();
  const history = useHistory();
  const { loading, requestorDetails } = useSelector((state) => state.requestors);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassResetModal, setShowPassResetModal] = useState(false);

  useEffect(() => {
    dispatch(getRequestorDetails(userId)).unwrap();
  }, []);

  const updateRequestorDetails = () => {
    setOpenModal(true);
  };

  const onModalClose = () => setShowConfirmModal(false);

  const onStatusChange = () => setShowConfirmModal(true);

  const resetUserPassword = () => setShowPassResetModal(true);
  const onPassResetModalClose = () => setShowPassResetModal(false);

  const updateStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const values = { ...requestorDetails, is_active: !requestorDetails.is_active };
      await dispatch(updateRequestor(values)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Requestor status updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <Container maxWidth="lg">
      <NewRequestor
        open={openModal}
        initialValues={requestorDetails}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading || isLoading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Requestor Details"
                action={(
                  <Button variant="contained" onClick={() => history.replace(ROUTE.requestors)}>
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField name="Employee ID" value={requestorDetails.emp_id} />
                  <InfoField name="User Name" value={`${requestorDetails.first_name} ${requestorDetails.last_name}`} />
                  <InfoField name="Site name" value={requestorDetails.site_name} />
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 4 }}>
                  <InfoField name="Email" value={requestorDetails.email} />
                  <InfoField
                    name="Address"
                    value={`${requestorDetails.city}, ${getStateName(requestorDetails.state)}`}
                  />
                  <InfoField
                    name="Phone Number"
                    value={requestorDetails.mobile_num}
                  />
                </Grid>
                <Grid sx={{ display: 'flex', flexDirection: 'row', mt: 4 }}>
                  <Box sx={{ width: '35%' }}>
                    <Typography color="textSecondary" variant="body1">
                      Status
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={requestorDetails.is_active}
                            value={requestorDetails.is_active}
                            color={requestorDetails.is_active ? 'success' : 'default'}
                          />
                        )}
                        onChange={onStatusChange}
                        label={requestorDetails.is_active ? 'Active' : 'Inactive'}
                      />
                    </FormGroup>
                  </Box>
                </Grid>

              </CardContent>
              <CardActions>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={updateRequestorDetails}
                >
                  Update Details
                </Button>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="warning"
                  onClick={resetUserPassword}
                >
                  Reset Password
                </Button>
              </CardActions>
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <PasswordResetModal
        open={showPassResetModal}
        userEmail={requestorDetails?.email}
        onClose={onPassResetModalClose}
      />
      <ConfirmationDialog
        info={requestorDetails.is_active ? CONFIRMATION_MSG.disableRequestor : CONFIRMATION_MSG.enableRequestor}
        open={showConfirmModal}
        disable={requestorDetails.is_active}
        onClose={onModalClose}
        onSubmit={updateStatus}
      />
    </Container>
  );
}

export default RequestorDetails;
