import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import { useSelector } from 'react-redux';

export default function CardDetailsForm(props) {
  const {
    formField: {
      vehicleId,
      dispenserId,
      operatorId,
      driverId,
      cardType,
      cardInterface,
      cardUID,
      cardCategory,
      fuelType
    },
    initValues
  } = props;
  const { allVehicles } = useSelector((state) => state.vehicles);
  const { allDispensers } = useSelector((state) => state.dispensers);
  const { allOperators } = useSelector((state) => state.operators);
  const { allDrivers } = useSelector((state) => state.drivers);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={vehicleId.name}
          label={vehicleId.label}
          data={allVehicles}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={dispenserId.name}
          label={dispenserId.label}
          data={allDispensers}
          fullWidth
          multiple
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={operatorId.name}
          label={operatorId.label}
          data={allOperators}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={driverId.name}
          label={driverId.label}
          data={allDrivers}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={cardType.name}
          label={cardType.label}
          data={cardType.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={cardInterface.name}
          label={cardInterface.label}
          data={cardInterface.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={cardCategory.name}
          label={cardCategory.label}
          data={cardCategory.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={fuelType.name}
          label={fuelType.label}
          data={fuelType.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField disabled={initValues} name={cardUID.name} label={cardUID.label} fullWidth upperCase />
      </Grid>
    </Grid>
  );
}

CardDetailsForm.propTypes = {
  formField: PropTypes.object.isRequired,
  initValues: PropTypes.bool,
};
