import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';

export default function DispenserConfigForm(props) {
  const {
    formField: {
      dispenserMount,
      dpNo,
      dpInterface,
      dpAddress,
      sequentialDIspenserID,
      kilometerDifference,
      minDigitsEntry,
      onlineConnectionTimeout,
      maxQuantityPerTransaction
    }
  } = props;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={dispenserMount.name}
          label={dispenserMount.label}
          data={dispenserMount.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={dpInterface.name}
          label={dpInterface.label}
          data={dpInterface.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={dpNo.name} label={dpNo.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={dpAddress.name} label={dpAddress.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={sequentialDIspenserID.name}
          label={sequentialDIspenserID.label}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={kilometerDifference.name} label={kilometerDifference.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={minDigitsEntry.name}
          label={minDigitsEntry.label}
          data={minDigitsEntry.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={onlineConnectionTimeout.name} label={onlineConnectionTimeout.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={maxQuantityPerTransaction.name} label={maxQuantityPerTransaction.label} fullWidth />
      </Grid>
    </Grid>
  );
}

DispenserConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
