import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import {
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
  OutlinedInput,
  colors,
} from '@mui/material';

function SelectField(props) {
  const {
    name,
    label,
    data = [],
    disabled,
    multiple = false,
    onChange,
    ...rest
  } = props;
  const [field, meta] = useField(name);
  const { value: selectedValue } = field;
  const { touched, error } = meta;
  const isError = touched && error;

  function renderHelperText() {
    if (isError) {
      return <FormHelperText>{error}</FormHelperText>;
    }
    return null;
  }

  return (
    <FormControl
      {...rest}
      error={Boolean(isError)}
      sx={{ background: disabled ? colors.grey[200] : 'inherit' }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        {...field}
        input={<OutlinedInput label={label} />}
        disabled={disabled}
        value={selectedValue || ''}
        {...(onChange ? { onChange } : {})} // To override onChange in components
        multiple={multiple}
      >
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      {renderHelperText()}
    </FormControl>
  );
}

SelectField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
  data: PropTypes.array.isRequired
};

export default SelectField;
