import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import Loader from 'src/common/Loader';
import { APIService } from 'src/api/api';
import { useSelector } from 'react-redux';
import ReactECharts from 'src/common/ReactECharts';
import { chartInputStyles, echartOptions } from './chartConfig';

const ConsumptionChart = (props) => {
  const [loading, setLoading] = useState(true);
  const { allSites } = useSelector((state) => state.sites);
  const [selectedSite, setSelectedSite] = useState('all');
  const [consumptionData, setConsumptionData] = useState(null);
  const siteData = [{ id: 'all', name: 'All Sites' }, ...allSites];

  const formatToEChartData = (data) => {
    const dataSeries = data.datasets.map((item) => ({
      name: item.label,
      type: 'bar',
      stack: 'total',
      label: { show: true },
      emphasis: { focus: 'series' },
      data: item.data.map((e) => e?.toFixed(0)),
    }));
    const chartData = {
      ...echartOptions,
      yAxis: {
        type: 'category',
        data: data.labels
      },
      series: dataSeries
    };
    setConsumptionData(chartData);
  };

  const fetchChartData = async (id) => {
    try {
      const siteId = id === 'all' ? '' : id;
      const res = await APIService.getTerminalWiseFuelDispensed(siteId);
      if (!res.success) throw res;
      formatToEChartData(res.result.data);
    } catch (error) {
      console.log('terminal wise consumption error', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(selectedSite);
  }, []);

  const handleSiteChange = (e) => {
    const siteId = e.target.value;
    setSelectedSite(siteId);
    setLoading(true);
    fetchChartData(siteId);
  };

  return (
    <Card {...props}>
      <CardHeader
        title="Terminal-wise Fuel Dispensed"
        sx={{
          background: 'linear-gradient(180deg, rgb(66, 66, 74), rgb(25, 25, 25))',
          color: '#fff'
        }}
        action={(
          <Select
            name="site"
            variant="standard"
            onChange={handleSiteChange}
            value={selectedSite}
            sx={chartInputStyles}
          >
            {siteData.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Divider />
      <CardContent sx={{ px: 0 }}>
        <Box sx={{ height: 400, position: 'relative' }}>
          {loading || !consumptionData ? (
            <Loader />
          ) : (
            <ReactECharts options={consumptionData} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(ConsumptionChart);
