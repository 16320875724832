import {
  ENTITY_NAME_REGEX, INVOICE_NUM_REGEX, NUMBER_TYPE_ERROR_MSG,
  VEHICLE_NUMBER_REGEX
} from 'src/utils/constants';
import { getMaxLengthErrorMsg, getMaxLimitErrorMsg } from 'src/utils/getInitials';
import * as Yup from 'yup';
import cardsFormModel from './transactionFormModel';

const {
  formField: {
    siteId,
    destTank,
    orderDate,
    deliveryDate,
    quantity,
    supplier,
    pricePerLitre,
    invoiceNumber,
    remarks,
    deliveryVehicleNumber,
    deliveredDipQuantity,
    deliveredMeasuredQuantityFLS,
    deliveredMeasuredQuantityMeter,
    temperatureDuringLoading,
    temperatureDuringUnloading,
    spillageQuantity,
    shortageQuantity,
    densityDuringLoading,
    densityDuringUnloading,
  }
} = cardsFormModel;

export default [
  Yup.object().shape({
    [siteId.name]: Yup.string().required(siteId.requiredErrorMsg),
    [destTank.name]: Yup.string().required(destTank.requiredErrorMsg),
    [supplier.name]: Yup.string().required(supplier.requiredErrorMsg),
    [quantity.name]: Yup.number().max(99999, getMaxLimitErrorMsg(quantity, 99999))
      .typeError(NUMBER_TYPE_ERROR_MSG).required(quantity.requiredErrorMsg),
    [pricePerLitre.name]: Yup.number().max(9999, getMaxLimitErrorMsg(pricePerLitre, 9999))
      .typeError(NUMBER_TYPE_ERROR_MSG).required(pricePerLitre.requiredErrorMsg),
    [invoiceNumber.name]: Yup.string().matches(INVOICE_NUM_REGEX, invoiceNumber.invalidErrorMsg)
      .required(invoiceNumber.requiredErrorMsg),
    [orderDate.name]: Yup.date().required(orderDate.requiredErrorMsg),
    [deliveryDate.name]: Yup.date().min(
      Yup.ref(orderDate.name), deliveryDate.invalidErrorMsg
    ).required(deliveryDate.requiredErrorMsg),
    [remarks.name]: Yup.string().matches(ENTITY_NAME_REGEX, remarks.invalidErrorMsg),
    [deliveryVehicleNumber.name]: Yup.string().max(50, getMaxLengthErrorMsg(deliveryVehicleNumber, 50)).matches(VEHICLE_NUMBER_REGEX, deliveryVehicleNumber.invalidErrorMsg)
      .required(deliveryVehicleNumber.requiredErrorMsg),
  }),
  Yup.object().shape({
    [deliveredDipQuantity.name]: Yup.number().max(99999, getMaxLimitErrorMsg(deliveredDipQuantity, 99999))
      .required(deliveredDipQuantity.requiredErrorMsg),
    [deliveredMeasuredQuantityFLS.name]: Yup.number().max(99999, getMaxLimitErrorMsg(deliveredMeasuredQuantityFLS, 99999))
      .required(deliveredMeasuredQuantityFLS.requiredErrorMsg),
    [deliveredMeasuredQuantityMeter.name]: Yup.number().max(99999, getMaxLimitErrorMsg(deliveredMeasuredQuantityMeter, 99999))
      .required(deliveredMeasuredQuantityMeter.requiredErrorMsg),
    [temperatureDuringLoading.name]: Yup.number().max(999, getMaxLimitErrorMsg(temperatureDuringLoading, 99999))
      .required(temperatureDuringLoading.requiredErrorMsg),
    [temperatureDuringUnloading.name]: Yup.number().max(999, getMaxLimitErrorMsg(temperatureDuringUnloading, 99999))
      .required(temperatureDuringUnloading.requiredErrorMsg),
    [spillageQuantity.name]: Yup.number().max(99999, getMaxLimitErrorMsg(spillageQuantity, 99999))
      .required(spillageQuantity.requiredErrorMsg),
    [shortageQuantity.name]: Yup.number().max(99999, getMaxLimitErrorMsg(shortageQuantity, 99999))
      .required(shortageQuantity.requiredErrorMsg),
    [densityDuringLoading.name]: Yup.number().max(999, getMaxLimitErrorMsg(densityDuringLoading, 99999))
      .required(densityDuringLoading.requiredErrorMsg),
    [densityDuringUnloading.name]: Yup.number().max(999, getMaxLimitErrorMsg(densityDuringUnloading, 99999))
      .required(densityDuringUnloading.requiredErrorMsg),
  }),
];
