import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, FormControl, FormControlLabel, Radio, Switch
} from '@mui/material';
import { useField } from 'formik';
import { renderHelperText } from 'src/utils/getInitials';

export const CheckBoxInput = (props) => {
  const { label, rest } = props;
  const [field, meta] = useField(props);

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.checked}
        checked={field.checked}
        control={<Checkbox {...field} color={field.checked ? 'success' : 'default'} />}
        label={label}
      />
      {renderHelperText(meta)}
    </FormControl>
  );
};

CheckBoxInput.propTypes = {
  label: PropTypes.string,
  rest: PropTypes.object,
};

export const RadioInput = (props) => {
  const { label, rest } = props;
  const [field, meta] = useField(props);
  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={<Radio {...field} color={field.value ? 'success' : 'default'} />}
        label={label}
      />
      {renderHelperText(meta)}
    </FormControl>
  );
};

RadioInput.propTypes = {
  label: PropTypes.string,
  rest: PropTypes.object,
};

export const SwitchInput = (props) => {
  const { label, rest } = props;
  const [field, meta] = useField(props);

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={
          <Switch {...field} color={field.value ? 'success' : 'default'} />
        }
        label={label}
      />
      {renderHelperText(meta)}
    </FormControl>
  );
};

SwitchInput.propTypes = {
  label: PropTypes.string,
  rest: PropTypes.object,
};
