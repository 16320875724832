import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import RequestorListTable from 'src/components/requestors/RequestorListTable';
import NewRequestor from 'src/components/requestors/NewRequestor';
import ScreenHeader from 'src/common/ScreenHeader';

const Requestors = ({ location }) => {
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (str) => setSearchText(str);

  return (
    <>
      <ScreenHeader title="Requestors" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Requestor Name"
            actionName="Add Requestor"
            onSearch={handleSearch}
            onAction={() => setOpenModal(true)}
          />
          <NewRequestor open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <RequestorListTable refreshData={location.state?.fromDashboard} searchText={searchText} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

Requestors.propTypes = {
  location: PropTypes.object
};

export default Requestors;
