import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

function ScreenHeader({ title }) {
  return (
    <Helmet>
      <title>{`${title} | Callcomm FMS`}</title>
    </Helmet>
  );
}

ScreenHeader.propTypes = {
  title: PropTypes.string.isRequired
};

export default ScreenHeader;
