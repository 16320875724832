import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';

export default function AdditionalDetailsForm(props) {
  const {
    formField: {
      deliveredDipQuantity,
      deliveredMeasuredQuantityFLS,
      deliveredMeasuredQuantityMeter,
      temperatureDuringLoading,
      temperatureDuringUnloading,
      spillageQuantity,
      shortageQuantity,
      densityDuringLoading,
      densityDuringUnloading,
    }
  } = props;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <InputField name={deliveredDipQuantity.name} label={deliveredDipQuantity.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={deliveredMeasuredQuantityFLS.name} label={deliveredMeasuredQuantityFLS.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={deliveredMeasuredQuantityMeter.name} label={deliveredMeasuredQuantityMeter.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={temperatureDuringLoading.name} label={temperatureDuringLoading.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={temperatureDuringUnloading.name} label={temperatureDuringUnloading.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={spillageQuantity.name} label={spillageQuantity.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={shortageQuantity.name} label={shortageQuantity.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={densityDuringLoading.name} label={densityDuringLoading.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={densityDuringUnloading.name} label={densityDuringUnloading.label} fullWidth />
      </Grid>
    </Grid>
  );
}

AdditionalDetailsForm.propTypes = {
  formField: PropTypes.object.isRequired
};
