import cardsFormModel from './cardsFormModel';

const {
  formField: {
    cardType,
    cardInterface,
    cardUID,
    cardCategory,
    fuelType,
    manualInputs,
    additionInfo,
    odometerInfo,
    vehicleRegNum,
    pinSelect,
    fillLimitType,
    perFillingLimit,
    perDayLimit,
    limitQuantity,
    limitStartDate,
    limitEndDate,
    attached,
    productTable,
    pinNumber,
    pinErrorCounter,
    vehicleId,
    dispenserId,
    driverId,
    operatorId
  }
} = cardsFormModel;

export default {
  [cardType.name]: 'Positive',
  [cardInterface.name]: 'LF',
  [cardUID.name]: '',
  [cardCategory.name]: 'Vehicle Card',
  [fuelType.name]: 'Diesel',
  [manualInputs.name]: [],
  [additionInfo.name]: '0',
  [odometerInfo.name]: 'Disable',
  [vehicleRegNum.name]: '0',
  [pinSelect.name]: false,
  [`${additionInfo.name}_check`]: false,
  [`${vehicleRegNum.name}_check`]: false,
  [fillLimitType.name]: 'No Limit',
  [perFillingLimit.name]: '9999',
  [perDayLimit.name]: '9999',
  [limitQuantity.name]: '999',
  [limitStartDate.name]: null,
  [limitEndDate.name]: null,
  [attached.name]: false,
  [productTable.name]: [],
  [pinNumber.name]: '0000',
  [pinErrorCounter.name]: '9',
  [vehicleId.name]: null,
  [dispenserId.name]: [],
  [driverId.name]: null,
  [operatorId.name]: null,
};
