import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelCardLimitRequest } from 'src/redux/cardApprovalSlice';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import FMSModal from 'src/common/FMSModal';
import InputField from 'src/common/formFields/InputField';

import {
  cardLimitCancelFormModel,
  cardLimitCancelFormInitialValues,
  cardLimitCancelValidationSchema
} from './cardLimitCancelFormModel';

const CancelApprovalForm = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    formId,
    formField: { remarks }
  } = cardLimitCancelFormModel;

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const reqBody = { requestId: data.id, payload: values };
      await dispatch(cancelCardLimitRequest(reqBody)).unwrap();
      handleClose();
      dispatch(
        setSuccessInfo({
          successMsg: 'Card Limit request cancelled successfully!'
        })
      );
    } catch (error) {
      console.log('error', error);
      actions.setSubmitting(false);
      // handle error here
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    } finally {
      setLoading(false);
    }
  };

  const formikProps = useFormik({
    initialValues: cardLimitCancelFormInitialValues,
    validationSchema: cardLimitCancelValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    if (open) {
      formikProps.resetForm();
    }
  }, [open]);

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Cancel Card Limit Request
      </DialogTitle>
      <Divider />
      <FormikProvider value={formikProps}>
        {loading ? (
          <Loader />
        ) : (
          <Form id={formId} onSubmit={formikProps.handleSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={remarks.name}
                    label={remarks.label}
                    multiline
                    rows={2}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button variant="outlined" size="large" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                id="approved"
                name="approved"
                color="primary"
                disabled={formikProps.isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </FormikProvider>
    </FMSModal>
  );
};

CancelApprovalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default CancelApprovalForm;
