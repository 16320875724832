import * as Yup from 'yup';
import { ENTITY_NAME_REGEX } from 'src/utils/constants';

// group form model
export const groupFormModel = {
  formId: 'groupConfig',
  formField: {
    grpType: {
      name: 'group_type',
      label: 'Group Type',
      type: 'select',
    },
    groupName: {
      name: 'name',
      label: 'Group Name',
      requiredErrorMsg: 'Group Name is required',
      invalidErrorMsg: 'Group Name is invalid',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    grpType,
    groupName
  }
} = groupFormModel;

export const groupFormInitialValues = {
  [grpType.name]: '',
  [groupName.name]: '',
};

// validation schema
export const groupValidationSchema = Yup.object().shape({
  [groupName.name]: Yup.string().matches(ENTITY_NAME_REGEX, groupName.invalidErrorMsg)
    .required(groupName.requiredErrorMsg),
});
