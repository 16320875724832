import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allOperators: [],
  totalCount: 0,
  operatorList: [],
  operatorDetails: {},
  loading: false,
};

export const getOperatorList = createAsyncThunk(
  'operator/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getOperatorList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreOperators = createAsyncThunk(
  'operator/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getOperatorList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllOperators = createAsyncThunk(
  'operator/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllOperators();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getOperatorDetails = createAsyncThunk(
  'operator/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getOperatorDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateOperator = createAsyncThunk(
  'operator/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateOperatorDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addOperator = createAsyncThunk(
  'operator/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createOperator(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatOperatorData = (data) => {
  const formattedList = data.map((operator) => ({ ...operator, name: `${operator.first_name} ${operator.last_name}` }));
  return formattedList;
};

export const operatorSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllOperators.fulfilled, (state, action) => ({ ...state, allOperators: formatOperatorData(action.payload) }))
      .addCase(getOperatorList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        operatorList: formatOperatorData(action.payload.results)
      }))
      .addCase(getOperatorList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getOperatorList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreOperators.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        operatorList: [...state.operatorList, ...formatOperatorData(action.payload.results)]
      }))
      .addCase(loadMoreOperators.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreOperators.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getOperatorDetails.fulfilled, (state, action) => ({ ...state, loading: false, operatorDetails: action.payload }))
      .addCase(getOperatorDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getOperatorDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateOperator.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        operatorDetails: action.payload,
        operatorList: updateItemInArray(state.operatorList, action.payload)
      }))
      .addCase(addOperator.fulfilled, (state, action) => {
        state.operatorList.push(action.payload);
      });
  }
});

export default operatorSlice.reducer;
