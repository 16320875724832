import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
} from '@mui/material';
import { memo, useEffect, useState } from 'react';
import Loader from 'src/common/Loader';
import { APIService } from 'src/api/api';
import ReactECharts from 'src/common/ReactECharts';
import { DATE_FILTER_LIST, chartInputStyles, getVehicleUsageChartConfig } from './chartConfig';

const VehicleGroupConsumptionChart = (props) => {
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(DATE_FILTER_LIST[0].id);
  const [usageData, setUsageData] = useState(null);

  const formatToEChartData = (data) => {
    const chartDataArray = Object.keys(data) || [];
    const dataSeries = chartDataArray.map((item) => {
      const itemName = item === 'external_quantity' ? 'Vendor Vehicles' : 'Company Vehicles';
      const value = data[item] || 0;
      return {
        name: itemName,
        value: value.toFixed(0),
      };
    });
    const config = { radius: ['80%'] };
    const chartData = getVehicleUsageChartConfig(dataSeries, config);
    setUsageData(chartData);
  };

  const fetchChartData = async (date) => {
    try {
      const res = await APIService.getTopFuelConsumption(date);
      if (!res.success) throw res;
      formatToEChartData(res.result);
    } catch (error) {
      console.log('dashboard sales error', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(selectedDate);
  }, []);

  const handleDateChange = (e) => {
    const selectedTimeline = e.target.value;
    setSelectedDate(selectedTimeline);
    setLoading(true);
    fetchChartData(selectedTimeline);
  };

  return (
    <Card {...props}>
      <CardHeader
        title="Group-wise Fuel Consumption"
        sx={{
          background: 'linear-gradient(180deg, rgb(66, 66, 74), rgb(25, 25, 25))',
          color: '#fff'
        }}
        action={(
          <Select
            name="date"
            variant="standard"
            onChange={handleDateChange}
            value={selectedDate}
            sx={chartInputStyles}
          >
            {DATE_FILTER_LIST.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Divider />
      <CardContent sx={{ px: 0 }}>
        <Box sx={{ height: 400, position: 'relative' }}>
          {loading || !usageData ? (
            <Loader />
          ) : (
            <ReactECharts options={usageData} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(VehicleGroupConsumptionChart);
