import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { GROUP_TYPES } from 'src/utils/constants';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  groupList: [],
  allVehicleGroups: [],
  allDepartmentGroups: [],
  groupType: GROUP_TYPES[0].id,
  loading: false,
};

export const getGroupList = createAsyncThunk(
  'group/list', async (req, { rejectWithValue }) => {
    const { groupType, params } = req;
    try {
      const res = await APIService.getGroupList(groupType, params);
      if (!res.success) throw res;
      return res.result.results;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllVehicleGroupList = createAsyncThunk(
  'group/all-vehicle-group-list', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllVehicleGroups();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllDepartmentGroupList = createAsyncThunk(
  'group/all-department-group-list', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllDepartmentGroups();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateGroupDetails = createAsyncThunk(
  'group/updateDetails', async (req, { rejectWithValue }) => {
    const { groupType, values } = req;
    try {
      const res = await APIService.updateGroupDetails(groupType, values.id, values);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewGroup = createAsyncThunk(
  'group/create', async (req, { rejectWithValue },) => {
    const { groupType, values } = req;
    try {
      const res = await APIService.createGroup(groupType, values);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const groupSlice = createSlice({
  name: 'groups',
  initialState,
  reducers: {
    setGroup: (state, action) => ({ ...state, groupList: [], groupType: action.payload }),
    updateGroupItem: (state, action) => ({ ...state, groupList: updateItemInArray(state.groupList, action.payload) }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getGroupList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groupList: action.payload.sort((a, b) => a.id - b.id)
      }))
      .addCase(getGroupList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getGroupList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateGroupDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        groupList: updateItemInArray(state.groupList, action.payload)
      }))
      .addCase(getAllVehicleGroupList.fulfilled, (state, action) => ({
        ...state,
        allVehicleGroups: action.payload
      }))
      .addCase(getAllDepartmentGroupList.fulfilled, (state, action) => ({
        ...state,
        allDepartmentGroups: action.payload
      }))
      .addCase(addNewGroup.fulfilled, (state, action) => {
        state.groupList.push(action.payload);
      });
  }
});

// Action creators are generated for each case reducer function
export const { setGroup, updateGroupItem } = groupSlice.actions;

export default groupSlice.reducer;
