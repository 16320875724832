import {
  Box,
  Button,
  Card,
  SvgIcon,
  CardContent,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  Hidden
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Search as SearchIcon, X as CancelIcon } from 'react-feather';
import AddIcon from '@mui/icons-material/AddOutlined';
import FilterSelectInput from './FilterSelectInput';

const FMSToolbar = ({
  placeholder,
  onSearch,
  showSearch = true,
  actionName,
  onAction,
  groupLabel,
  groupType,
  groupData,
  showGroup,
  onGroupChange,
  hideAction,
  actionLoading,
  children,
  actionStyle,
  actionIcon = <AddIcon />
}) => {
  const [searchText, setSearchText] = useState('');
  const [curSearch, setCurSearch] = useState('');

  const onSearchChange = (e) => {
    const searchStr = e.target.value;
    setSearchText(searchStr);
    if (searchStr === '' && curSearch) {
      onSearch('');
    }
  };

  const onClearSearch = () => {
    setSearchText('');
    if (curSearch) {
      setCurSearch('');
      onSearch('');
    }
  };

  const handleSearch = () => {
    setCurSearch(searchText);
    onSearch(searchText);
  };

  const handleGroupChange = (value) => {
    setCurSearch('');
    setSearchText('');
    onGroupChange(value);
  };

  const renderSearchBar = () => (
    <TextField
      sx={{
        width: { md: '30%', sm: 'auto', xs: '100%' },
        mr: { xs: 1, sm: 3 },
        mt: { xs: 2, sm: 0 }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleSearch}>
              <SvgIcon fontSize="small" color="action">
                <SearchIcon />
              </SvgIcon>
            </IconButton>
            <IconButton onClick={onClearSearch}>
              <SvgIcon fontSize="small" color="action">
                <CancelIcon />
              </SvgIcon>
            </IconButton>
          </InputAdornment>
        )
      }}
      placeholder={placeholder}
      value={searchText}
      variant="outlined"
      onChange={onSearchChange}
    />
  );

  return (
    <Box>
      <Card>
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Hidden smDown>
              {showSearch && renderSearchBar()}
              {showGroup && (
                <FilterSelectInput
                  label={groupLabel}
                  onInputChange={handleGroupChange}
                  data={groupData}
                  value={groupType}
                />
              )}
              {children}
            </Hidden>
            <Hidden smUp>
              {showGroup && (
                <FilterSelectInput
                  label={groupLabel}
                  onInputChange={handleGroupChange}
                  data={groupData}
                  value={groupType}
                />
              )}
            </Hidden>
            {!hideAction && (
              <Box sx={{ ml: 'auto', ...actionStyle }}>
                {!actionLoading ? (
                  <Button
                    color="primary"
                    size="large"
                    sx={{ px: 2 }}
                    variant="contained"
                    startIcon={actionIcon}
                    onClick={onAction}
                  >
                    {actionName}
                  </Button>
                ) : (
                  <CircularProgress />
                )}
              </Box>
            )}
          </Box>
          <Hidden smUp>
            {showSearch && renderSearchBar()}
            {children}
          </Hidden>
        </CardContent>
      </Card>
    </Box>
  );
};

FMSToolbar.propTypes = {
  placeholder: PropTypes.string,
  actionName: PropTypes.string,
  groupLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  actionIcon: PropTypes.element,
  groupType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  groupData: PropTypes.array,
  showGroup: PropTypes.bool,
  showSearch: PropTypes.bool,
  actionLoading: PropTypes.bool,
  hideAction: PropTypes.bool,
  onSearch: PropTypes.func,
  onAction: PropTypes.func,
  onGroupChange: PropTypes.func,
  actionStyle: PropTypes.object,
};

export default FMSToolbar;
