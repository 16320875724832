import PropTypes from 'prop-types';
import { Box, Button, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { useState } from 'react';
import BreakdownForm from 'src/components/breakdown/BreakdownForm';
import BreakdownList from 'src/components/breakdown/BreakdownList';
import ReportFilterModal from 'src/components/reports/ReportFilterModal';
import { getReportConfig } from 'src/components/reports/reportService';
import { breakdownReportFormInitialValues, breakdownReportFormModel, breakdownReportValidationSchema } from 'src/components/breakdown/breakdownReportFormModel';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import ScreenHeader from 'src/common/ScreenHeader';

const VehicleBreakdown = ({ location }) => {
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openReportModal, setOpenReportModal] = useState(false);

  const handleSearch = (str) => setSearchText(str);
  const downloadReport = () => setOpenReportModal(true);
  const onReportModalClose = () => setOpenReportModal(false);
  const reportConfig = getReportConfig('breakdownReport');

  return (
    <>
      <ScreenHeader title="Vehicle Breakdown" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Vehicle Number"
            actionName="Export"
            actionIcon={<DownloadIcon />}
            onSearch={handleSearch}
            onAction={downloadReport}
            actionStyle={{ ml: 2 }}
          >
            <Button
              sx={{ ml: 'auto' }}
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Add Breakdown
            </Button>
          </FMSToolbar>
          <ReportFilterModal
            open={openReportModal}
            title="Vehicle Breakdown Report"
            formModel={breakdownReportFormModel}
            formValidation={breakdownReportValidationSchema}
            formInitValues={breakdownReportFormInitialValues}
            filterConfig={reportConfig.filterOptions}
            downloadUrl={reportConfig.downloadUrl}
            dateFormat={reportConfig.dateFormat}
            handleClose={onReportModalClose}
          />
          <BreakdownForm open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <BreakdownList refreshData={location.state?.fromDashboard} searchText={searchText} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

VehicleBreakdown.propTypes = {
  location: PropTypes.object
};

export default VehicleBreakdown;
