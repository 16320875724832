import {
  PieChart as DashboardIcon,
  Users as UserIcon,
  Monitor as ReportIcon,
  Database as DatabaseIcon,
  // Grid as GroupIcon,
  Truck as TruckIcon,
  CreditCard as CardIcon,
  Zap as DispenserIcon,
  Map as MapIcon,
  Calendar as ApprovalIcon
} from 'react-feather';
import { USER_ROLES } from './constants';

const ROUTE = {
  login: '/login',
  register: '/register',
  app: '/app',
  dashboard: '/app/dashboard',
  account: '/app/account',
  sites: '/app/sites',
  siteDetails: '/app/sites/:siteId',
  tanks: '/app/tanks',
  tankDetails: '/app/tanks/:tankId',
  profile: '/app/profile',
  settings: '/app/settings',
  cards: '/app/vehicles/cards',
  cardDetails: '/app/vehicles/cards/:cardId',
  cardApprovals: '/app/card-approvals',
  cardRequests: '/app/card-requests',
  reports: '/app/reports',
  transactions: '/app/reports/transactions',
  transactionDetails: '/app/reports/transactions/:transactionId',
  otherReports: '/app/reports/others',
  reconciliationReport: '/app/reports/reconciliation',
  vehicleManagement: '/app/vehicles',
  vehicles: '/app/vehicles/vehicle-list',
  vehicleStatusList: '/app/vehicle-status-list',
  vehicleDetails: '/app/vehicles/vehicle-list/:vehicleId',
  vehicleBreakdown: '/app/vehicle-breakdown',
  wailonTransactions: '/app/reports/sensor-transactions',
  groups: '/app/vehicles/groups/',
  dispensers: '/app/dispensers',
  dispenserDetails: '/app/dispensers/:dispenserId',
  userManagement: '/app/users',
  requestors: '/app/users/requestors',
  requestorDetails: '/app/users/requestors/:userId',
  operators: '/app/users/operators',
  operatorDetails: '/app/users/operators/:userId',
  drivers: '/app/users/vehicle-drivers',
  driverDetails: '/app/users/vehicle-drivers/:driverId',
  approvers: '/app/users/approvers',
  approverDetails: '/app/users/approvers/:userId',
  siteManagers: '/app/users/site-supervisor',
  siteManagerDetails: '/app/users/site-supervisor/:userId',
};

export const getAppRoutes = (user) => {
  const isAdmin = user?.role === USER_ROLES.admin;
  const isSupervisor = user?.role === USER_ROLES.siteManager;
  const isRequestor = user?.role === USER_ROLES.requestor;
  const isApprover = user?.role === USER_ROLES.approver;

  const APP_DRAWER_ROUTES = [
    {
      href: ROUTE.dashboard,
      icon: DashboardIcon,
      title: 'Dashboard'
    },
    {
      href: ROUTE.sites,
      icon: MapIcon,
      title: 'Sites'
    },
    {
      href: ROUTE.cardApprovals,
      icon: ApprovalIcon,
      title: 'Approvals',
      hidden: isSupervisor || isRequestor,
      badge: true,
    },
    {
      href: ROUTE.vehicleBreakdown,
      title: 'Breakdown',
      icon: CardIcon,
    },
    {
      href: ROUTE.vehicleManagement,
      icon: TruckIcon,
      hidden: isRequestor || isApprover,
      title: 'Vehicle Management',
      subMenu: [
        {
          href: ROUTE.cards,
          title: 'Cards',
        },
        {
          href: ROUTE.groups,
          title: 'Groups',
        },
        {
          href: ROUTE.vehicles,
          title: 'Vehicles',
        },
      ],
    },
    {
      href: ROUTE.reports,
      icon: ReportIcon,
      title: 'Reports',
      subMenu: [
        {
          href: ROUTE.transactions,
          title: 'Transactions',
        },
        {
          href: ROUTE.reconciliationReport,
          title: 'Reconciliation',
        },
        // {
        //   href: ROUTE.wailonTransactions,
        //   title: 'Sensor Transactions',
        // },
        {
          href: ROUTE.otherReports,
          title: 'Other Reports',
        },
      ]
    },
    {
      href: ROUTE.tanks,
      icon: DatabaseIcon,
      title: 'Tanks',
      hidden: isRequestor,
    },
    {
      href: ROUTE.dispensers,
      icon: DispenserIcon,
      title: 'Dispensers',
      hidden: !isAdmin,
    },
    {
      href: ROUTE.cardRequests,
      icon: CardIcon,
      title: 'Card Requests',
      hidden: !isRequestor,
    },
    {
      href: ROUTE.userManagement,
      icon: UserIcon,
      hidden: !isAdmin,
      title: 'User Management',
      subMenu: [
        {
          href: ROUTE.approvers,
          title: 'Approvers',
        },
        {
          href: ROUTE.operators,
          title: 'Operators',
        },
        {
          href: ROUTE.requestors,
          title: 'Requesters',
        },
        {
          href: ROUTE.siteManagers,
          title: 'Site Supervisors',
        },
        // {
        //   href: ROUTE.drivers,
        //   title: 'Vehicle Drivers',
        // }
      ]
    },
  ];
  return APP_DRAWER_ROUTES;
};

export default ROUTE;
