import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  siteList: [],
  allSites: [],
  totalCount: 0,
  siteDetails: {},
  loading: false,
};

export const getSiteList = createAsyncThunk(
  'sites/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getSiteList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreSiteList = createAsyncThunk(
  'sites/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo } = req;
    try {
      const res = await APIService.getSiteList(limit, pageNo);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllSites = createAsyncThunk(
  'sites/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllSites();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getSiteDetails = createAsyncThunk(
  'sites/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getSiteDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateSiteDetails = createAsyncThunk(
  'sites/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateSiteDetails(data.id, data);
      if (!res.success) throw res;
      return { ...data, ...res.result };
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewSite = createAsyncThunk(
  'sites/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createSite(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const siteSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllSites.fulfilled, (state, action) => ({ ...state, allSites: action.payload }))
      .addCase(getSiteList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        siteList: action.payload.results
      }))
      .addCase(getSiteList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSiteList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreSiteList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        siteList: [...state.siteList, ...action.payload.results]
      }))
      .addCase(loadMoreSiteList.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreSiteList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getSiteDetails.fulfilled, (state, action) => ({ ...state, loading: false, siteDetails: action.payload }))
      .addCase(getSiteDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSiteDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateSiteDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        siteDetails: action.payload,
        siteList: updateItemInArray(state.siteList, action.payload)
      }))
      .addCase(addNewSite.fulfilled, (state, action) => {
        state.siteList.push(action.payload);
      });
  }
});

export default siteSlice.reducer;
