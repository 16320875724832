import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import theme from 'src/theme';

// Modal component with backdrop disable implementation
function FMSModal({ children, onClose, ...rest }) {
  return (
    <Dialog
      {...rest}
      onClose={(e, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
          return;
        }
        onClose();
      }}
    >
      {children}
    </Dialog>
  );
}

FMSModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
    PropTypes.object
  ]),
  onClose: PropTypes.func
};

export default FMSModal;

export const FMSModalTitle = ({ title, onCancel }) => (
  <DialogTitle
    sx={{
      fontSize: 18,
      background: theme.palette.grey[800],
      color: theme.palette.common.white
    }}
  >
    {title}
    <IconButton
      aria-label="close"
      onClick={onCancel}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: () => theme.palette.grey[300]
      }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
);

FMSModalTitle.propTypes = {
  title: PropTypes.string.isRequired,
  onCancel: PropTypes.func
};
