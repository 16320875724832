import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { CONFIRMATION_MSG } from 'src/utils/constants';
import InfoField, { GridRow } from 'src/common/InfoField';
import ROUTE from 'src/utils/routeConstants';
import {
  clearDispenserCards,
  getDispenserDetails,
  updateDispenserDetails
} from 'src/redux/dispenserSlice';
import { getFormattedDate, getFuelPrice, isAdminUser } from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';
import NewDispenser from './NewDispenser';

function DispenserDetails() {
  const { dispenserId } = useParams();
  const history = useHistory();
  const { getUser } = useAuth();
  const isAdmin = isAdminUser(getUser);
  const dispatch = useDispatch();
  const { loading, dispenserDetails } = useSelector(
    (state) => state.dispensers
  );
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalInfo, setModalInfo] = useState(CONFIRMATION_MSG.disableDispenser);
  const [actionName, setActionName] = useState(null);
  const ACTION_TYPES = { update: 'UPDATE', reset: 'RESET' };

  useEffect(() => {
    dispatch(getDispenserDetails(dispenserId)).unwrap();
  }, []);

  const openUpdateForm = () => setOpenModal(true);

  const openConfirmationModal = (actionType) => {
    if (actionType === ACTION_TYPES.reset) {
      setActionName(actionType);
      setModalInfo(CONFIRMATION_MSG.resetCards);
    } else {
      const info = dispenserDetails.status
        ? CONFIRMATION_MSG.disableDispenser
        : CONFIRMATION_MSG.enableDispenser;
      setActionName(null);
      setModalInfo(info);
    }
    setShowConfirmModal(true);
  };

  const onModalClose = () => setShowConfirmModal(false);

  const updateDispenserStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const values = { ...dispenserDetails, status: !dispenserDetails.status };
      await dispatch(updateDispenserDetails(values)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Dispenser status updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const resetCards = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      await dispatch(clearDispenserCards(dispenserDetails.id)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Dispenser cards reset successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const handleModalSubmit = () => {
    if (actionName) {
      resetCards();
    } else {
      updateDispenserStatus();
    }
  };

  return (
    <Container maxWidth="lg">
      <NewDispenser
        open={openModal}
        initialValues={dispenserDetails}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading || isLoading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Dispenser Details"
                action={(
                  <Button
                    variant="contained"
                    onClick={() => history.replace(ROUTE.dispensers)}
                  >
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField
                    name="Dispenser Name"
                    value={dispenserDetails.station_name}
                  />
                  <InfoField
                    name="Dispenser Address"
                    value={dispenserDetails.address}
                  />
                  <InfoField
                    name="Dispenser Mode"
                    value={dispenserDetails.mode}
                  />
                </Grid>
                <GridRow>
                  <InfoField
                    name="Article Type"
                    value={dispenserDetails.article}
                  />
                  <InfoField
                    name="Article Unit"
                    value={dispenserDetails.article_unit}
                  />
                  <InfoField
                    name="Article Price"
                    value={getFuelPrice(dispenserDetails.price)}
                  />
                </GridRow>
                <GridRow>
                  <InfoField
                    name="Created On"
                    value={getFormattedDate(dispenserDetails.created_at)}
                  />
                  <InfoField
                    name="Last Updated On"
                    value={getFormattedDate(dispenserDetails.update_at)}
                  />
                  <Box sx={{ width: '35%' }}>
                    <Typography color="textSecondary" variant="body1">
                      Status
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={dispenserDetails.status}
                            value={dispenserDetails.status}
                            color={
                              dispenserDetails.status ? 'success' : 'default'
                            }
                          />
                        )}
                        onChange={() => openConfirmationModal(ACTION_TYPES.update)}
                        label={dispenserDetails.status ? 'Active' : 'Inactive'}
                      />
                    </FormGroup>
                  </Box>
                </GridRow>
                <GridRow>
                  <InfoField
                    name="Site Name"
                    value={dispenserDetails.site_name}
                  />
                  <InfoField
                    name="Dispenser Mount"
                    value={dispenserDetails.dispenser_mount}
                  />
                  <InfoField
                    name="DP Interface"
                    value={dispenserDetails.dp_interface}
                  />
                </GridRow>
                <GridRow>
                  <InfoField
                    name="ATG Type"
                    value={dispenserDetails.atg_type}
                  />
                  <InfoField
                    name="ATG Connection"
                    value={dispenserDetails.atg_connection ? 'ON' : 'OFF'}
                  />
                  <InfoField
                    name="Throttle Stage"
                    value={dispenserDetails.throttle_stage}
                  />
                </GridRow>
                <GridRow>
                  <InfoField
                    name="Throttle Volume"
                    value={dispenserDetails.throttle_volume}
                  />
                  <InfoField
                    name="Pulse Type"
                    value={dispenserDetails.pulse_type}
                  />
                  <InfoField
                    name="Pulse Direction"
                    value={dispenserDetails.pulse_direction}
                  />
                </GridRow>
                <GridRow>
                  <InfoField
                    name="Backlight"
                    value={dispenserDetails.back_light ? 'ON' : 'OFF'}
                  />
                  <InfoField
                    name="Card reader"
                    value={dispenserDetails.card_reader ? 'ON' : 'OFF'}
                  />
                  <InfoField
                    name="Date/time sync"
                    value={dispenserDetails.date_time_sync ? 'ON' : 'OFF'}
                  />
                </GridRow>
                <GridRow>
                  <InfoField
                    name="Fuel start time"
                    value={dispenserDetails.fuel_start_time}
                  />
                  <InfoField
                    name="Fuel break time"
                    value={dispenserDetails.fuel_break_time}
                  />
                  <InfoField
                    name="Empty filling allowed"
                    value={dispenserDetails.empty_fill_allowed}
                  />
                </GridRow>
                <GridRow>
                  <InfoField name="kfactor" value={dispenserDetails.kfactor} />
                  <InfoField
                    name="Max flow rate"
                    value={dispenserDetails.max_flow_rate}
                  />
                  <InfoField
                    name="Min flow rate"
                    value={dispenserDetails.min_flow_rate}
                  />
                </GridRow>
                <GridRow>
                  <InfoField name="Alarm" value={dispenserDetails.alarm} />
                  <InfoField
                    name="Sync Status"
                    value={dispenserDetails.sync_status}
                  />
                  <InfoField
                    name="Last Synced on"
                    value={getFormattedDate(dispenserDetails.synced_on)}
                  />
                </GridRow>
              </CardContent>
              {isAdmin && (
              <CardActions>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={openUpdateForm}
                >
                  Update Details
                </Button>
                {!dispenserDetails.reset_cards && (
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="warning"
                  onClick={() => openConfirmationModal(ACTION_TYPES.reset)}
                >
                  Reset Cards
                </Button>
                )}
              </CardActions>
              )}
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        info={modalInfo}
        open={showConfirmModal}
        disable={dispenserDetails.status}
        actionName={actionName}
        onClose={onModalClose}
        onSubmit={handleModalSubmit}
      />
    </Container>
  );
}

export default DispenserDetails;
