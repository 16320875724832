import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import FMSModal from 'src/common/FMSModal';
import InputField from 'src/common/formFields/InputField';
import Loader from 'src/common/Loader';
import { addNewGroup, updateGroupDetails } from 'src/redux/groupSlice';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import theme from 'src/theme';
import { GROUP_TYPES } from 'src/utils/constants';
import { getGroupName } from 'src/utils/getInitials';
import { groupFormInitialValues, groupFormModel, groupValidationSchema } from './groupFormModel';

const NewGroup = ({
  open,
  handleClose,
  initialValues
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { groupType } = useSelector((state) => state.groups);
  const formInitValues = initialValues || groupFormInitialValues;
  const {
    formId,
    formField: {
      grpType,
      groupName
    }
  } = groupFormModel;

  const onSubmit = async (values) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateGroupDetails({ groupType, values })).unwrap();
      } else {
        await dispatch(addNewGroup({ groupType, values })).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(setSuccessInfo({
        successMsg: initialValues ? 'group details updated successfully!' : 'New group has been created successfully!'
      }));
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      handleClose();
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} fullWidth>
      <DialogTitle sx={{
        fontSize: 18,
        background: theme.palette.grey[800],
        color: theme.palette.common.white
      }}
      >
        {initialValues ? 'Update Group Details' : 'Add New Group'}
      </DialogTitle>
      <Divider />
      {loading ? <Loader /> : (
        <Formik
          initialValues={formInitValues}
          validationSchema={groupValidationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form id={formId}>
              <DialogContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name={grpType.name}
                      label={grpType.label}
                      defaultValue={getGroupName(groupType, GROUP_TYPES)}
                      disabled
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputField name={groupName.name} label={groupName.label} fullWidth />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions sx={{ p: 2 }}>
                <Button variant="outlined" size="large" onClick={handleClose}>Cancel</Button>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  size="large"
                  variant="contained"
                >
                  {initialValues ? 'Update Group' : 'Create Group'}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      )}
    </FMSModal>
  );
};

NewGroup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewGroup;
