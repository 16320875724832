import { FormHelperText } from '@mui/material';
import { AES, enc } from 'crypto-js';
import moment from 'moment';
import * as Yup from 'yup';
import { BASEAPI } from 'src/api/api';
import STATE_LIST from 'src/utils/stateList.json';
import {
  DATE_FORMAT, NUMBER_TYPE_ERROR_MSG, USER_ROLES, USER_TYPES
} from './constants';

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .max(80)
    .required('Email is required'),
  password: Yup.string().max(40).required('Password is required')
});

export const getAvatarName = (name = '') => name
  .replace(/\s+/, ' ')
  .split(' ')
  .slice(0, 2)
  .map((v) => v && v[0].toUpperCase())
  .join('');

const encryptData = (data) => {
  let value = data;
  if (typeof data !== 'object') {
    value = JSON.stringify(data);
  }
  const ciphertext = AES.encrypt(
    value,
    process.env.REACT_APP_SECRET_KEY
  ).toString();
  return ciphertext;
};

const decryptData = (ciphertext) => {
  const bytes = AES.decrypt(ciphertext, process.env.REACT_APP_SECRET_KEY);
  const originalText = bytes.toString(enc.Utf8);
  return originalText.replace(/["]+/g, ''); // to remove double quotes ;
};

export const storeLocalData = (key, data, encrypt) => {
  let dataToStore = data;
  if (typeof data === 'object') {
    dataToStore = JSON.stringify(data);
  }
  if (encrypt) {
    dataToStore = encryptData(dataToStore);
  }
  // console.log('data to store', key, dataToStore);
  localStorage.setItem(key, dataToStore);
};

export const getLocalData = (key, parse, decrypt) => {
  let dataToFetch = localStorage.getItem(key);
  if (!dataToFetch) return null;
  if (decrypt) {
    dataToFetch = decryptData(dataToFetch);
  }
  if (parse) {
    dataToFetch = JSON.parse(dataToFetch);
  }
  // console.log('data To fetch', key, dataToFetch);
  return dataToFetch;
};

export const clearLocalData = () => {
  localStorage.clear();
  delete BASEAPI.defaults.headers.common.Authorization;
};

export const getStateName = (stateId) => {
  const selectedState = STATE_LIST.find((state) => state.id === stateId);
  return selectedState ? selectedState.name : '';
};

export const updateItemInArray = (dataList, item) => {
  const updatedDataList = dataList.map((data) => {
    if (data.id === item.id) {
      return item;
    }
    return data;
  });
  return updatedDataList;
};

export const renderHelperText = (meta) => {
  const { touched, error } = meta;
  if (touched && error) {
    return <FormHelperText>{error}</FormHelperText>;
  }
  return null;
};

export const getGroupName = (groupType, data) => {
  const selectedGroup = data.find((group) => group.id === groupType);
  return selectedGroup.name || 'NA';
};

export const getMinLimitErrorMsg = (field, limit) => {
  const errorMsg = `${field.label} must be more than or equal to ${limit}`;
  return errorMsg;
};

export const getMaxLimitErrorMsg = (field, limit) => {
  const errorMsg = `${field.label} must be less than or equal to ${limit}`;
  return errorMsg;
};

export const getMaxLengthErrorMsg = (field, limit) => {
  const errorMsg = `${field.label} must be at most ${limit} characters`;
  return errorMsg;
};

export const getRangeErrorValidation = (field, min, max) => Yup.number()
  .typeError(NUMBER_TYPE_ERROR_MSG)
  .min(min, getMinLimitErrorMsg(field, min))
  .max(max, getMaxLimitErrorMsg(field, max))
  .required(field.requiredErrorMsg);

export const getFormattedDate = (timestamp, format = DATE_FORMAT.dateTimeString) => {
  if (timestamp) {
    return moment(timestamp).format(format);
  }
  return 'NA';
};

export const getMilageType = (vehicleType) => {
  const milageType = vehicleType === 'Movable' ? 'KM/L' : 'L/Hr';
  return milageType;
};

export const getMilageUnit = (vehicleType) => {
  const milageType = vehicleType === 'Movable' ? 'KM' : 'Hr';
  return milageType;
};

export const getFuelQuantity = (value) => {
  let fuelQty = '0 L';
  if (value) {
    fuelQty = `${value.toFixed(2)} L`;
  }
  return fuelQty;
};

export const getFuelPrice = (value) => {
  let fuelPrice = '0 Rs';
  if (value) {
    fuelPrice = `${value.toFixed(2)} Rs`;
  }
  return fuelPrice;
};

export const isAdminUser = (getUser) => {
  const user = getUser();
  const isAdmin = user?.role === USER_ROLES.admin;
  return isAdmin;
};

const getDateTimeFilterValue = (dateValue) => {
  let filterDate = { lte: null, gte: null };
  if (dateValue) {
    filterDate = {
      lte: `${dateValue}T23:59:59`, // end time of the date
      gte: `${dateValue}T00:00:00` // start time of the date
    };
  }
  return filterDate;
};

export const getTransactionQueryParams = (isSales, searchValue, dateValue) => {
  const filterDate = getDateTimeFilterValue(dateValue);
  const queryParams = isSales
    ? { vehicle_number: searchValue, start_time__lte: filterDate.lte, start_time__gte: filterDate.gte }
    : { invoice_number: searchValue, order_date__lte: filterDate.lte, order_date__gte: filterDate.gte };
  return queryParams;
};

export const getSensorTransactionQueryParams = (searchValue, dateValue) => {
  const filterDate = getDateTimeFilterValue(dateValue);
  const queryParams = {
    vehicle__registration_num: searchValue,
    filled_time__gte: filterDate.gte,
    filled_time__lte: filterDate.lte
  };
  return queryParams;
};

export const getMilageReportQueryParams = (searchText, siteFilter, groupFilter, dateValue) => {
  const selectedSite = siteFilter === 'all' ? null : siteFilter;
  const selectedGroup = groupFilter === 'all' ? null : groupFilter;
  const filterDate = getDateTimeFilterValue(dateValue);
  const queryParams = {
    vehicle_number: searchText,
    site: selectedSite,
    vehicle_group: selectedGroup,
    start_time__gte: filterDate.gte,
    start_time__lte: filterDate.lte
  };
  return queryParams;
};

export const getReconciliationQueryParams = (searchText, siteFilter, dateValue) => {
  const selectedSite = siteFilter === 'all' ? null : siteFilter;
  const queryParams = {
    dispenser__station_name: searchText,
    dispenser__site: selectedSite,
    date__gte: dateValue,
    date__lte: dateValue
  };
  return queryParams;
};

export const getCardLimitRequestQueryParams = (searchText, dateValue) => {
  const filterDate = getDateTimeFilterValue(dateValue);
  const queryParams = {
    search: searchText,
    requested_on__gte: filterDate.gte,
    requested_on__lte: filterDate.lte
  };
  return queryParams;
};

export const camelCaseString = (value) => value?.replace(/^./, (str) => str?.toUpperCase());

export const removeLeadingZeros = (value) => value?.replace(/^0+/, '') || 'NA';

export const getUserRole = (role) => {
  const userRole = USER_TYPES.find((item) => item.id === role);
  return userRole?.name || '';
};

export const formatNumber = (value) => {
  let formattedValue = 0;
  if (value) {
    formattedValue = value.toFixed(2);
  }
  return formattedValue;
};
