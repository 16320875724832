import * as Yup from 'yup';

// card approvals form model
export const cardLimitCancelFormModel = {
  formId: 'cardLimitCancelConfig',
  formField: {
    remarks: {
      name: 'remarks',
      label: 'Cancel Reason',
      requiredErrorMsg: 'Cancel Reason is required',
      invalidErrorMsg: 'Invalid Cancel Reason',
      type: 'text'
    }
  }
};

// Initial values
const {
  formField: {
    remarks
  }
} = cardLimitCancelFormModel;

export const cardLimitCancelFormInitialValues = {
  [remarks.name]: ''
};

// validation schema
export const cardLimitCancelValidationSchema = Yup.object().shape({
  [remarks.name]: Yup.string().required(remarks.requiredErrorMsg),
});
