import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  tankList: [],
  allTanks: [],
  totalCount: 0,
  tankListBySite: [],
  bowserListBySite: [],
  tankDetails: {},
  loading: false,
};

export const getTankList = createAsyncThunk(
  'tank/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getTankList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreTanks = createAsyncThunk(
  'tank/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo } = req;
    try {
      const res = await APIService.getTankList(limit, pageNo);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllTanks = createAsyncThunk(
  'tank/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllTanks();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getTankDetails = createAsyncThunk(
  'tank/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getTankDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateTank = createAsyncThunk(
  'tank/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateTankDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addTank = createAsyncThunk(
  'tank/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createTank(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const tankSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    filterTanksBySite: (state, action) => ({
      ...state,
      tankListBySite: action.payload === 'all' ? state.allTanks
        : state.allTanks.filter((tank) => tank.site === action.payload),
      bowserListBySite: state.allTanks.filter((tank) => (tank.site === action.payload && tank.type === 'Movable'))
    }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllTanks.fulfilled, (state, action) => ({ ...state, allTanks: action.payload, tankListBySite: action.payload }))
      .addCase(getTankList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        tankList: action.payload.results,
      }))
      .addCase(getTankList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getTankList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreTanks.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        tankList: [...state.tankList, ...action.payload.results],
      }))
      .addCase(loadMoreTanks.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreTanks.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getTankDetails.fulfilled, (state, action) => ({ ...state, loading: false, tankDetails: action.payload }))
      .addCase(getTankDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getTankDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateTank.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        tankDetails: action.payload,
        tankList: updateItemInArray(state.tankList, action.payload)
      }))
      .addCase(addTank.fulfilled, (state, action) => {
        state.tankList.push(action.payload);
      });
  }
});

// Action creators are generated for each case reducer function
export const { filterTanksBySite } = tankSlice.actions;

export default tankSlice.reducer;
