export const STORAGE_KEY = {
  token: 'fms_auth_token',
  user: 'fms_user'
};

export const RESET_STORE = 'store/reset';

export const CONFIRMATION_MSG = {
  enableSite:
    "Enabling the site will enable all the dispenser's under this site. Are you sure, you want to enable the site?",
  disableSite:
    "Disabling the site will disable all the dispenser's under this site. Are you sure, you want to disable the site?",
  enableSiteManager: 'Are you sure, you want to enable the supervisor?',
  disableSiteManager: 'Are you sure, you want to disable the supervisor?',
  enableApprover: 'Are you sure, you want to enable the approver?',
  disableApprover: 'Are you sure, you want to disable the approver?',
  enableRequestor: 'Are you sure, you want to enable the requestor?',
  disableRequestor: 'Are you sure, you want to disable the requestor?',
  enableOperator: 'Are you sure, you want to enable the operator?',
  disableOperator: 'Are you sure, you want to disable the operator?',
  enableAutomation: 'Are you sure, you want to enable automation?',
  disableAutomation: 'Are you sure, you want to disable automation?',
  enableCard: 'Are you sure, you want to enable this card?',
  disableCard: 'Are you sure, you want to disable this card?',
  enableDispenser: 'Are you sure, you want to enable this dispenser?',
  disableDispenser: 'Are you sure, you want to disable this dispenser?',
  enableDriver: 'Are you sure, you want to enable the driver?',
  disableDriver: 'Are you sure, you want to disable the driver?',
  enableVehicle: 'Are you sure, you want to enable the vehicle?',
  disableVehicle: 'Are you sure, you want to disable the vehicle?',
  resetCards:
    'This operation will reset all the cards in the Dispenser. Are you sure you want to reset?'
};

export const USER_TYPES = [
  { id: 'admin', name: 'Admin' },
  { id: 'approver', name: 'Approver' },
  { id: 'requestor', name: 'Requestor' },
  { id: 'site-manager', name: 'Site Supervisor' },
];

export const USER_ROLES = {
  admin: 'admin',
  supervisor: 'supervisor',
  operator: 'operator',
  driver: 'driver',
  requestor: 'requestor',
  approver: 'approver',
  siteManager: 'site-manager'
};

export const VEHICLE_CATEGORY = [
  { id: 'LMV', name: 'LMV' },
  { id: 'LCV', name: 'LCV' },
  { id: 'TA', name: 'TA' },
  { id: 'THA', name: 'THA' },
  { id: 'HCM', name: 'HCM' },
  { id: 'EME', name: 'EME' },
  { id: 'MXE', name: 'MXE' },
  { id: 'HGMV', name: 'HGMV' },
  { id: 'HPMV', name: 'HPMV' },
  { id: 'TRNSFR', name: 'TRNSFR' }
];
export const VEHICLE_TYPE = [
  { id: 'Movable', name: 'Movable' },
  { id: 'Non Movable', name: 'Non Movable' },
  { id: 'Semi Movable', name: 'Semi Movable' }
];
export const CONSUMER_VEHICLE_TYPES = [
  { id: 'Company', name: 'Company Vehicles' },
  { id: 'Vendor', name: 'Vendor Vehicles' }
];
export const TELEMATICS_TYPES = [
  { id: 'GPS', name: 'GPS' },
  { id: 'Bluetooth', name: 'Bluetooth' },
  { id: 'OEM', name: 'OEM' },
  { id: 'OBD', name: 'OBD' }
];
export const GROUP_TYPES = [
  { id: 'group', name: 'Vehicle Group' },
  { id: 'department', name: 'Departments' }
];
export const TRANSACTION_TYPES = [
  { id: 'iot', name: 'Issued' },
  { id: 'inward', name: 'Inward' },
  { id: 'transfer', name: 'Transfer' }
];
export const TANK_TYPE = [
  { id: 'Underground', name: 'Underground' },
  { id: 'Movable', name: 'Movable' }
];
export const ARTICLE_TYPE = [
  { id: 'Petrol', name: 'Petrol' },
  { id: 'Diesel', name: 'Diesel' },
  { id: 'Bio-Disel', name: 'Bio-Disel' },
  { id: 'AdBlue', name: 'AdBlue' },
  { id: 'Lube', name: 'Lube' }
];
export const REPORT_TYPES = [
  // { id: 'breakdownReport', name: 'Vehicle Breakdown Report' },
  { id: 'vehicleSummaryReport', name: 'Vehicle Summary Report' },
  { id: 'vehicleUtilizationReport', name: 'Vehicle Utilization Report' },
  { id: 'sensorTransactionReport', name: 'Wailon Transaction Report' },
  { id: 'vehicleGroupWiseReport', name: 'Vehicle Group-wise Consumption Report' },
  { id: 'groupWiseComparisonReport', name: 'Group-wise Comparison summary Report' },
  // { id: 'milageReport', name: 'Milage Report' },
];
export const BREAKDOWN_TYPES = [
  { id: 'engine', name: 'Engine' },
  { id: 'gear', name: 'Gear' },
  { id: 'tyre', name: 'Tyre' },
  { id: 'chassis', name: 'Chassis' },
  { id: 'Bonnet', name: 'Bonnet' },
  { id: 'Door', name: 'Door' },
  { id: 'other', name: 'Other' },
];
export const NUMBER_LIST = [
  { id: '0', name: '0' },
  { id: '1', name: '1' },
  { id: '2', name: '2' },
  { id: '3', name: '3' },
  { id: '4', name: '4' },
  { id: '5', name: '5' },
  { id: '6', name: '6' },
  { id: '7', name: '7' },
  { id: '8', name: '8' },
  { id: '9', name: '9' }
];
// form validation regex
export const ADDITONAL_ENTRY_REGEX = /^[1-9]/;
export const PINCODE_REGEX = /^[0-9]{6}$/;
export const NAME_REGEX = /^[a-zA-Z]+$/;
export const USER_NAME_REGEX = /^[a-zA-Z]{2,}\d*$/;
export const ENTITY_NAME_REGEX = /^[a-zA-Z][a-zA-Z0-9 ]+$/;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,).('-]*$/;
export const CODE_REGEX = /^[a-zA-Z0-9]+$/;
export const INVOICE_NUM_REGEX = /^[a-zA-Z0-9\s)/_(-]*$/;
// export const VEHICLE_NUMBER_REGEX = /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{2}[0-9]{4}$/;
export const VEHICLE_NUMBER_REGEX = /^[a-zA-Z0-9\s,)_(&-]*$/;
export const LATITUDE_REGEX = /^((-?|\+?)?\d+(\.\d+)?)$/;
export const LONGITUDE_REGEX = /^s*((-?|\+?)?\d+(\.\d+)?)$/;
export const PHONE_NUMBER_REGEX = /^\d{10}$/;
export const PIN_NUMBER_REGEX = /^\d{4}$/;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!*%^#@$&])[a-zA-Z0-9!*%^#@$&]{8,}$/;
// common error msg
export const NUMBER_TYPE_ERROR_MSG = 'Must be a number';
export const DATE_TYPE_ERROR_MSG = 'Invalid Date';

export const REPORT_FILTER_OPTIONS = {
  site: true,
  tank: false,
  dispenser: true,
  vehicle: true,
  vehicleGroup: true,
  assetType: false,
  requestStatus: false
};

export const TABLE_ROWS_PER_PAGE = [10, 25, 50];

export const DATE_FORMAT = {
  date: 'DD/MM/YYYY',
  dateTime: 'DD/MM/YYYY HH:mm',
  dateTimeString: 'DD/MM/YYYY HH:mm A',
  unixDate: 'YYYY-MM-DD',
  unixDateTime: 'YYYY-MM-DDTHH:mm:ss',
  timeStamp: 'YYYY-MM-DDTHH:mm',
  reportDateTime: 'DD-MM-YYYY HH:mm:ss',
};
