import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useState } from 'react';
import { addNewSite, updateSiteDetails } from 'src/redux/siteSlice';
import { useDispatch } from 'react-redux';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import FMSModal from 'src/common/FMSModal';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import {
  siteFormInitialValues,
  siteFormModel,
  siteValidationSchema
} from './siteFormModel';

const NewSite = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const formInitValues = initialValues || siteFormInitialValues;
  const {
    formId,
    formField: {
      siteName,
      siteCode,
      addressLine,
      city,
      state,
      country,
      pinCode,
      latitude,
      longitude
    }
  } = siteFormModel;

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateSiteDetails(values)).unwrap();
      } else {
        await dispatch(addNewSite(values)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'site details updated successfully!'
            : 'New site has been created successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        {initialValues ? 'Update Site Details' : 'Add New Site'}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={siteValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={siteName.name}
                        label={siteName.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={siteCode.name}
                        label={siteCode.label}
                        upperCase
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={addressLine.name}
                        label={addressLine.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={city.name}
                        label={city.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={state.name}
                        label={state.label}
                        data={state.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={country.name}
                        label={country.label}
                        data={country.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={pinCode.name}
                        label={pinCode.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={latitude.name}
                        label={latitude.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={longitude.name}
                        label={longitude.label}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {initialValues ? 'Update Site' : 'Create Site'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

NewSite.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewSite;
