export default {
  formId: 'transactionConfig',
  formField: {
    orderDate: {
      name: 'order_date',
      label: 'Order Date',
      requiredErrorMsg: 'Order Date is required',
      type: 'date',
    },
    deliveryDate: {
      name: 'delivery_date',
      label: 'Delivery Date',
      requiredErrorMsg: 'Delivery Date is required',
      invalidErrorMsg: 'Delivery date must be later than Ordered date',
      type: 'date',
    },
    quantity: {
      name: 'quantity',
      label: 'Fuel Quantity',
      requiredErrorMsg: 'Fuel Quantity is required',
      type: 'text',
    },
    deliveredDipQuantity: {
      name: 'delivered_dip_quantity',
      label: 'Delivered Dip Quantity',
      requiredErrorMsg: 'Delivered Dip Quantity is required',
      type: 'text',
    },
    deliveredMeasuredQuantityFLS: {
      name: 'delivered_measured_quantity_FLS',
      label: 'Delivered Measured Quantity FLS',
      requiredErrorMsg: 'Delivered Measured Quantity FLS is required',
      type: 'text',
    },
    deliveredMeasuredQuantityMeter: {
      name: 'delivered_measured_quantity_meter',
      label: 'Delivered Measured Quantity Meter',
      requiredErrorMsg: 'Delivered Measured Quantity Meter is required',
      type: 'text',
    },
    temperatureDuringLoading: {
      name: 'temperature_during_loading',
      label: 'Temperature During Loading',
      requiredErrorMsg: 'Temperature During Loading is required',
      type: 'text',
    },
    temperatureDuringUnloading: {
      name: 'temperature_during_unloading',
      label: 'Temperature During Unloading',
      requiredErrorMsg: 'Temperature During Unloading is required',
      type: 'text',
    },
    pricePerLitre: {
      name: 'price_per_litre',
      label: 'Price Per Litre',
      requiredErrorMsg: 'Price Per Litre is required',
      type: 'text',
    },
    supplier: {
      name: 'supplier',
      label: 'Select Supplier',
      requiredErrorMsg: 'supplier is required',
      type: 'select',
      data: [
        { id: 'IOCL', name: 'IOCL', },
        { id: 'IFF LLP', name: 'IFF LLP', },
        { id: 'Ocean Waves', name: 'Ocean Waves', },
      ]
    },
    invoiceNumber: {
      name: 'invoice_number',
      label: 'Invoice Number',
      requiredErrorMsg: 'Invoice Number is required',
      invalidErrorMsg: 'Invoice Number is invalid',
      type: 'text',
    },
    remarks: {
      name: 'remarks',
      label: 'Remarks',
      requiredErrorMsg: 'Remarks is required',
      invalidErrorMsg: 'Remarks is invalid',
      type: 'text',
    },
    spillageQuantity: {
      name: 'spillage_quantity',
      label: 'Spillage Quantity',
      requiredErrorMsg: 'Spillage Quantity is required',
      type: 'text',
    },
    shortageQuantity: {
      name: 'shortage_quantity',
      label: 'Shortage Quantity',
      requiredErrorMsg: 'Shortage Quantity is required',
      type: 'text',
    },
    deliveryVehicleNumber: {
      name: 'delivery_vehicle_number',
      label: 'Delivery Vehicle Number',
      requiredErrorMsg: 'Delivery Vehicle Number is required',
      invalidErrorMsg: 'Invalid Vehicle Number',
      type: 'text',
    },
    densityDuringLoading: {
      name: 'density_during_loading',
      label: 'Density During Loading',
      requiredErrorMsg: 'Density During Loading is required',
      type: 'text',
    },
    densityDuringUnloading: {
      name: 'density_during_unloading',
      label: 'Density During Unloading',
      requiredErrorMsg: 'Density During Unloading is required',
      type: 'text',
    },
    siteId: {
      name: 'site',
      label: 'Select Site',
      requiredErrorMsg: 'Site is required',
      type: 'select',
      data: []
    },
    sourceTank: {
      name: 'tank',
      label: 'Select Source Tank',
      requiredErrorMsg: 'Tank is required',
      type: 'select',
      data: []
    },
    destTank: {
      name: 'received_tank',
      label: 'Select Output Tank',
      requiredErrorMsg: 'Tank is required',
      type: 'select',
      data: []
    },
  }
};
