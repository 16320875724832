import React, { useEffect, useState } from 'react';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  DialogContent,
  Divider,
  DialogActions
} from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Loader from 'src/common/Loader';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import theme from 'src/theme';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTanks } from 'src/redux/tankSlice';
import FMSModal, { FMSModalTitle } from 'src/common/FMSModal';
import { getAllSites } from 'src/redux/siteSlice';
import {
  addNewTransaction,
  updateTransactionDetails
} from 'src/redux/transactionSlice';
import TransactionDetailsForm from './forms/TransactionDetailsForm';
import AdditionalDetailsForm from './forms/AdditionalDetailsForm';
import validationSchema from './formModel/transactionValidation';
import transactionFormModel from './formModel/transactionFormModel';
import formInitialValues from './formModel/transactionInitialValues';

const { formId, formField } = transactionFormModel;

function renderStepContent(step, initialValues) {
  switch (step) {
    case 0:
      return <TransactionDetailsForm formField={formField} initialValues={initialValues} />;
    case 1:
      return <AdditionalDetailsForm formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

const NewTransaction = ({ open, handleClose, initialValues }) => {
  const steps = ['Transaction Details', 'Additional Details'];
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const formInitValues = initialValues || formInitialValues;
  const { transactionType } = useSelector((state) => state.transactions);

  useEffect(() => {
    if (open) {
      dispatch(getAllTanks());
      dispatch(getAllSites());
    }
    setActiveStep(0); // reset form
  }, [open]);

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const submitForm = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(
          updateTransactionDetails({ transactionType, values })
        ).unwrap();
      } else {
        const finalValue = values.quantity - values.shortage_quantity;
        const payload = { ...values, actual_filled: finalValue };
        await dispatch(addNewTransaction({ transactionType, values: payload })).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Transaction details updated successfully!'
            : 'New Transaction has been created successfully!'
        })
      );
    } catch (error) {
      console.log('transaction error', error);
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const handleSubmit = (values, actions) => {
    console.log(values, actions);
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getActionButton = () => {
    let actionBtn = 'Next';
    if (isLastStep) {
      if (initialValues) {
        actionBtn = 'Update Transaction';
      } else {
        actionBtn = 'Create Transaction';
      }
    }
    return actionBtn;
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <FMSModalTitle
        title={
          initialValues ? 'Update Transaction details' : 'Add new Transaction'
        }
        onCancel={handleClose}
      />
      <Divider />
      <DialogContent>
        {!loading && (
          <Stepper
            activeStep={activeStep}
            sx={{
              padding: theme.spacing(2, 0, 4),
              backgroundColor: 'rgba(256, 256, 256, 0.3)',
              borderRadius: 5
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Formik
          initialValues={formInitValues}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              {loading ? (
                <Loader />
              ) : (
                <Form
                  id={formId}
                  sx={{
                    padding: theme.spacing(3),
                    background: 'transparent',
                    transition: 'all 0.5s ease'
                  }}
                >
                  {renderStepContent(activeStep, initialValues)}

                  <DialogActions sx={{ mt: 2 }}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={handleBack}
                    >
                      {activeStep === 0 ? 'Close' : 'Back'}
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      size="large"
                      variant="contained"
                    >
                      {getActionButton()}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </>
          )}
        </Formik>
      </DialogContent>
    </FMSModal>
  );
};

NewTransaction.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewTransaction;
