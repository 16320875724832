import { ARTICLE_TYPE } from 'src/utils/constants';

export default {
  formId: 'cardConfig',
  formField: {
    driverId: {
      name: 'vehicle_driver_id',
      label: 'Select Vehicle Driver',
      requiredErrorMsg: 'Driver is required',
      type: 'select',
      data: []
    },
    operatorId: {
      name: 'operator_id',
      label: 'Select Operator',
      requiredErrorMsg: 'Operator is required',
      type: 'select',
      data: []
    },
    vehicleId: {
      name: 'vehicle_id',
      label: 'Select Vehicle',
      requiredErrorMsg: 'Vehicle is required',
      type: 'select',
      data: []
    },
    dispenserId: {
      name: 'dispenser_ids',
      label: 'Select Dispenser',
      requiredErrorMsg: 'Dispenser is required',
      type: 'select',
      data: []
    },
    cardType: {
      name: 'card_type',
      label: 'Card Type',
      requiredErrorMsg: 'Card Type is required',
      type: 'select',
      data: [
        { id: 'Positive', name: 'Positive' },
        { id: 'Negative', name: 'Negative' }
      ]
    },
    cardInterface: {
      name: 'interface',
      label: 'Card Interface',
      requiredErrorMsg: 'Card Interface is required',
      type: 'select',
      data: [
        { id: 'LF', name: 'LF' },
        { id: 'HF', name: 'HF' },
        { id: 'WLF', name: 'WLF' },
        { id: 'Barcode', name: 'Barcode' }
      ]
    },
    cardUID: {
      name: 'uid',
      label: 'Card UID',
      requiredErrorMsg: 'Card UID is required',
      invalidErrorMsg: 'Card UID is invalid',
      type: 'text'
    },
    fuelType: {
      name: 'fuel_type',
      label: 'Fuel Type',
      requiredErrorMsg: 'Fuel Type is required',
      type: 'select',
      data: ARTICLE_TYPE
    },
    cardCategory: {
      name: 'card_category',
      label: 'Card Category',
      requiredErrorMsg: 'Card Category is required',
      type: 'select',
      data: [
        { id: 'Vehicle Card', name: 'Vehicle Card' },
        { id: 'Driver Card', name: 'Driver Card' },
        { id: 'Menu Card', name: 'Menu Card' },
        { id: 'Vehicle 2 Card', name: 'Vehicle 2 Card' },
        { id: 'Driver 2 Card', name: 'Driver 2 Card' }
      ]
    },
    additionInfo: {
      name: 'addition_info',
      label: 'Additional info',
      invalidErrorMsg: 'Value must be 1 to 9',
      type: 'select'
    },
    odometerInfo: {
      name: 'odometer_info',
      label: 'Odometer info',
      type: 'select',
      data: [
        { id: 'Disable', name: 'Disable' },
        { id: 'Odometer Entry', name: 'Odometer Entry' },
        { id: 'Hour Meter Entry', name: 'Hour Meter Entry' }
      ]
    },
    vehicleRegNum: {
      name: 'vehicle_reg_num',
      label: 'Vehicle Reg number',
      invalidErrorMsg: 'Value must be 1 to 9',
      type: 'select'
    },
    pinSelect: {
      name: 'pin_select',
      label: 'PIN',
      invalidErrorMsg: 'Value must be 1 to 9',
      type: 'checkbox'
    },
    manualInputs: {
      name: 'manual_inputs',
      label: 'Manual Inputs',
      requiredErrorMsg: 'Manual Inputs is required',
      type: 'checkbox',
      data: [
        { id: '0', name: 'Aditional Info', checked: false },
        { id: '1', name: 'Odometer Info', checked: false },
        { id: '2', name: 'Vehcile Reg Number', checked: false },
        { id: '3', name: 'PIN', checked: false }
      ]
    },
    fillLimitType: {
      name: 'fill_limit_type',
      label: 'Fill Limit Type',
      requiredErrorMsg: 'Fill Limit Type is required',
      type: 'select',
      data: [
        { id: 'No Limit', name: 'No Limit' },
        { id: 'Per Filling', name: 'Per Filling' },
        // { id: 'Per Shift', name: 'Per Shift' },
        { id: 'Per Day', name: 'Per Day' },
        { id: 'Per Duration', name: 'Per Duration' },
        // { id: 'Per Month', name: 'Per Month' },
        { id: 'One Time Limit', name: 'One Time Limit' },
      ]
    },
    perFillingLimit: {
      name: 'per_filling_limit',
      label: 'Per Filling Limit',
      requiredErrorMsg: 'Per Filling Limit is required',
      type: 'text'
    },
    perDayLimit: {
      name: 'per_day_limit',
      label: 'Per Day Limit',
      requiredErrorMsg: 'Per Day Limit is required',
      type: 'text'
    },
    limitQuantity: {
      name: 'limit_quantity',
      label: 'Limit Quantity',
      requiredErrorMsg: 'Limit Quantity is required',
      type: 'text'
    },
    limitStartDate: {
      name: 'limit_start_date',
      label: 'Limit Start Date',
      requiredErrorMsg: 'Limit Start Date is required',
      type: 'date'
    },
    limitEndDate: {
      name: 'limit_end_date',
      label: 'Limit End Date',
      requiredErrorMsg: 'Limit End Date is required',
      invalidErrorMsg: 'Limit End Date must be greater than Limit Start date',
      type: 'date'
    },
    attached: {
      name: 'attached',
      label: 'Attached',
      requiredErrorMsg: 'Attached is required',
      type: 'text'
    },
    productTable: {
      name: 'product_table',
      label: 'Product Table',
      requiredErrorMsg: 'Product Table is required',
      type: 'checkbox',
      data: [
        { id: '0', name: 'Petrol', checked: false },
        { id: '1', name: 'Diesel', checked: false },
        { id: '2', name: 'Bio-Diesel', checked: false },
        { id: '3', name: 'Adblue', checked: false },
        { id: '4', name: 'Lube', checked: false }
      ]
    },
    pinNumber: {
      name: 'pin',
      label: 'Pin Number',
      requiredErrorMsg: 'Pin Number is required',
      invalidErrorMsg: 'Invalid Pin Number',
      type: 'text'
    },
    pinErrorCounter: {
      name: 'wrong_pin_count',
      label: 'Pin Error Counter',
      requiredErrorMsg: 'Pin Error Counter is required',
      type: 'text'
    }
  }
};
