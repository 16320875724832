import * as Yup from 'yup';
import {
  ARTICLE_TYPE, CODE_REGEX, ENTITY_NAME_REGEX, NUMBER_TYPE_ERROR_MSG, TANK_TYPE,
} from 'src/utils/constants';

// tank form model
export const tankFormModel = {
  formId: 'tankConfig',
  formField: {
    siteName: {
      name: 'site',
      label: 'Select Site',
      requiredErrorMsg: 'Site is required',
      type: 'select',
      data: []
    },
    tankType: {
      name: 'type',
      label: 'Tank Type',
      requiredErrorMsg: 'Tank Type is required',
      type: 'select',
      data: TANK_TYPE
    },
    article: {
      name: 'article',
      label: 'Fuel Type',
      requiredErrorMsg: 'Fuel Type is required',
      type: 'select',
      data: ARTICLE_TYPE
    },
    tankName: {
      name: 'name',
      label: 'Tank Name',
      requiredErrorMsg: 'Tank Name is required',
      invalidErrorMsg: 'Tank name is invalid',
      type: 'text',
    },
    tankCode: {
      name: 'code',
      label: 'Tank Code',
      requiredErrorMsg: 'Tank Code is required',
      invalidErrorMsg: 'Tank Code name is invalid',
      type: 'text',
    },
    sensorId: {
      name: 'sensor_id',
      label: 'Sensor Id',
      requiredErrorMsg: 'Sensor Id is required',
      invalidErrorMsg: 'Sensor Id is Invalid',
      type: 'text',
    },
    maxVolume: {
      name: 'max_volume',
      label: 'Max Volume',
      requiredErrorMsg: 'Max Volume is required',
      invalidErrorMsg: 'Max Volume is Invalid',
      type: 'text',
    },
    minVolume: {
      name: 'min_volume',
      label: 'Min Volume',
      requiredErrorMsg: 'Min Volume is required',
      invalidErrorMsg: 'Min Volume is Invalid',
      type: 'text',
    },
    maxLevel: {
      name: 'max_level',
      label: 'Max Level',
      requiredErrorMsg: 'Max Level is required',
      invalidErrorMsg: 'Max Level is invalid',
      type: 'text',
    },
    minLevel: {
      name: 'min_level',
      label: 'Min Level',
      requiredErrorMsg: 'Min Level is required',
      invalidErrorMsg: 'Min Level is invalid',
      type: 'text',
    },
    automation: {
      name: 'automation',
      label: 'Enable Automation',
      requiredErrorMsg: 'automation is required',
      type: 'switch',
    },
  }
};

// Initial values
const {
  formField: {
    siteName,
    tankType,
    article,
    tankName,
    tankCode,
    sensorId,
    maxVolume,
    minVolume,
    maxLevel,
    minLevel,
    automation
  }
} = tankFormModel;

export const tankFormInitialValues = {
  [siteName.name]: '',
  [tankType.name]: '',
  [article.name]: '',
  [tankName.name]: '',
  [tankCode.name]: '',
  [sensorId.name]: '',
  [maxVolume.name]: 0,
  [minVolume.name]: 0,
  [maxLevel.name]: 0,
  [minLevel.name]: 0,
  [automation.name]: false
};

// validation schema
export const tankValidationSchema = Yup.object().shape({
  [tankName.name]: Yup.string().max(50).matches(ENTITY_NAME_REGEX, tankName.invalidErrorMsg).required(siteName.requiredErrorMsg),
  [tankCode.name]: Yup.string().min(4).max(20).matches(CODE_REGEX, tankCode.invalidErrorMsg)
    .required(tankCode.requiredErrorMsg),
  [sensorId.name]: Yup.string().min(4).max(20).matches(CODE_REGEX, sensorId.invalidErrorMsg)
    .required(sensorId.requiredErrorMsg),
  [article.name]: Yup.string().required(article.requiredErrorMsg),
  [tankType.name]: Yup.string().required(tankType.requiredErrorMsg),
  [siteName.name]: Yup.string().required(siteName.requiredErrorMsg),
  [maxVolume.name]: Yup.number().max(99999).typeError(NUMBER_TYPE_ERROR_MSG).required(maxVolume.requiredErrorMsg),
  [minVolume.name]: Yup.number().max(99999).typeError(NUMBER_TYPE_ERROR_MSG).required(minVolume.invalidErrorMsg),
  [maxLevel.name]: Yup.number().max(99999).typeError(NUMBER_TYPE_ERROR_MSG).required(maxLevel.invalidErrorMsg),
  [minLevel.name]: Yup.number().max(99999).typeError(NUMBER_TYPE_ERROR_MSG).required(minLevel.invalidErrorMsg),
});
