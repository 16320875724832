import PropTypes from 'prop-types';
import {
  Card, CardContent, Grid, Typography
} from '@mui/material';
import { getFuelQuantity } from 'src/utils/getInitials';

const TotalProfit = ({ salesCount, ...rest }) => (
  <Card {...rest}>
    <CardContent>
      <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color="textSecondary" gutterBottom variant="subtitle1">
            TODAY SALE
          </Typography>
          <Typography sx={{ pt: 1 }} color="textPrimary" variant="h2">
            {getFuelQuantity(salesCount)}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

TotalProfit.propTypes = {
  salesCount: PropTypes.number,
};

export default TotalProfit;
