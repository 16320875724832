import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';

const initialState = {
  totalCount: 0,
  transactionList: [],
  transactionDetails: {},
  loading: false,
};

export const getSensorTransactionList = createAsyncThunk(
  'sensor-transaction/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getWailonTransactions(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreSensorTransactionList = createAsyncThunk(
  'sensor-transaction/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getWailonTransactions(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getSensorTransactionDetails = createAsyncThunk(
  'sensor-transaction/details', async (req, { rejectWithValue }) => {
    const { transactionId } = req;
    try {
      const res = await APIService.getTransactionDetails(transactionId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const sensorTransactionSlice = createSlice({
  name: 'sensorTransactions',
  initialState,
  reducers: {
    setTransactionType: (state, action) => ({ ...state, transactionList: [], transactionType: action.payload }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getSensorTransactionList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        transactionList: action.payload.results.sort((a, b) => a.id - b.id),
        totalCount: action.payload.count
      }))
      .addCase(getSensorTransactionList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSensorTransactionList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreSensorTransactionList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        transactionList: action.payload.results.sort((a, b) => a.id - b.id),
        totalCount: action.payload.count
      }))
      .addCase(loadMoreSensorTransactionList.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreSensorTransactionList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getSensorTransactionDetails.fulfilled, (state, action) => ({ ...state, loading: false, transactionDetails: action.payload }))
      .addCase(getSensorTransactionDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getSensorTransactionDetails.rejected, (state) => ({ ...state, loading: false }));
  }
});

export default sensorTransactionSlice.reducer;
