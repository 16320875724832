import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allRequestors: [],
  totalCount: 0,
  requestorList: [],
  requestorDetails: {},
  loading: false,
};

export const getRequestorList = createAsyncThunk(
  'requestor/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getRequestorList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreRequestors = createAsyncThunk(
  'requestor/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getRequestorList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllRequestors = createAsyncThunk(
  'requestor/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllRequestors();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getRequestorDetails = createAsyncThunk(
  'requestor/details', async (siteId, { rejectWithValue }) => {
    try {
      const res = await APIService.getRequestorDetails(siteId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateRequestor = createAsyncThunk(
  'requestor/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateRequestorDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addRequestor = createAsyncThunk(
  'requestor/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createRequestor(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatRequestorData = (data) => {
  const formattedList = data.map((user) => ({ ...user, name: `${user.first_name} ${user.last_name}` }));
  return formattedList;
};

export const requestorSlice = createSlice({
  name: 'requestors',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllRequestors.fulfilled, (state, action) => ({ ...state, allRequestors: formatRequestorData(action.payload) }))
      .addCase(getRequestorList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        requestorList: formatRequestorData(action.payload.results)
      }))
      .addCase(getRequestorList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getRequestorList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreRequestors.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        requestorList: [...state.requestorList, ...formatRequestorData(action.payload.results)]
      }))
      .addCase(loadMoreRequestors.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreRequestors.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getRequestorDetails.fulfilled, (state, action) => ({ ...state, loading: false, requestorDetails: action.payload }))
      .addCase(getRequestorDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getRequestorDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateRequestor.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        requestorDetails: action.payload,
        requestorList: updateItemInArray(state.requestorList, action.payload)
      }))
      .addCase(addRequestor.fulfilled, (state, action) => {
        state.requestorList.push(action.payload);
      });
  }
});

export default requestorSlice.reducer;
