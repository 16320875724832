import PropTypes from 'prop-types';
import {
  IconButton,
  Avatar,
  Badge,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  SvgIcon,
  Tooltip,
  useMediaQuery,
} from '@mui/material';
import useAuth from 'src/context/AuthContext';
import FMSModal from 'src/common/FMSModal';
import { alpha } from '@mui/material/styles';
import NotificationsIcon from '@mui/icons-material/NotificationsNone';
import PersonIcon from '@mui/icons-material/Person';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import usePopover from 'src/hooks/usePopover';
import AccountPopover from 'src/common/AccountPopover';
import { useSelector } from 'react-redux';

export const SIDE_NAV_WIDTH = 260;
const TOP_NAV_HEIGHT = 64;

const DashboardNavbar = (props) => {
  const { onNavOpen } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const accountPopover = usePopover();
  const { pendingApprovalsCount } = useSelector((state) => state.cardApprovals);

  return (
    <>
      <Box
        component="header"
        sx={{
          backdropFilter: 'blur(6px)',
          backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
          position: 'sticky',
          left: {
            lg: `${SIDE_NAV_WIDTH}px`
          },
          top: 0,
          width: {
            lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
          },
          zIndex: (theme) => theme.zIndex.appBar
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{
            minHeight: TOP_NAV_HEIGHT,
            px: 2
          }}
        >
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            {!lgUp && (
              <IconButton onClick={onNavOpen}>
                <SvgIcon fontSize="small">
                  <MenuOpenIcon />
                </SvgIcon>
              </IconButton>
            )}
          </Stack>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
          >
            <Tooltip title="Notifications">
              <IconButton>
                <Badge
                  badgeContent={pendingApprovalsCount}
                  color="success"
                  variant="dot"
                >
                  <SvgIcon fontSize="small">
                    <NotificationsIcon />
                  </SvgIcon>
                </Badge>
              </IconButton>
            </Tooltip>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                onClick={accountPopover?.handleOpen}
                ref={accountPopover?.anchorRef}
                sx={{
                  cursor: 'pointer',
                  height: 40,
                  width: 40,
                }}
              >
                <PersonIcon />
              </Avatar>
            </Box>
          </Stack>
        </Stack>
      </Box>
      <AccountPopover
        anchorEl={accountPopover.anchorRef.current}
        open={accountPopover.open}
        onClose={accountPopover.handleClose}
      />
    </>
  );
};

DashboardNavbar.propTypes = {
  onNavOpen: PropTypes.func
};

export default DashboardNavbar;

export const LogoutModal = ({ open, onClose }) => {
  const { signout } = useAuth();

  return (
    <FMSModal
      open={open}
      maxWidth="sm"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle sx={{ fontSize: 16 }} id="logout-dialog-title">
        Logout
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="logout-dialog-description">
          Are you sure, you want to logout?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={signout} autoFocus>
          Logout
        </Button>
      </DialogActions>
    </FMSModal>
  );
};

LogoutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
