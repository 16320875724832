import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormHelperText,
  Grid,
} from '@mui/material';
import FMSModal from 'src/common/FMSModal';
import Loader from 'src/common/Loader';
import { Form, Formik } from 'formik';
import { PASSWORD_REGEX } from 'src/utils/constants';
import { APIService } from 'src/api/api';
import { setSuccessInfo } from 'src/redux/modalSlice';
import { useDispatch } from 'react-redux';
import theme from 'src/theme';
import InputField from './formFields/InputField';

const resetPasswordFormModel = {
  email: {
    name: 'email',
    label: 'Email',
    requiredErrorMsg: 'Email is required',
    invalidErrorMsg: 'Email is invalid',
    type: 'text',
  },
  password: {
    name: 'new_password',
    label: 'Password',
    requiredErrorMsg: 'Password is required',
    invalidErrorMsg: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special Character',
    type: 'password',
  },
  confirmPassword: {
    name: 'confirm_password',
    label: 'Confirm Password',
    requiredErrorMsg: 'Confirm Password is required',
    invalidErrorMsg: 'Passwords must match',
    type: 'password',
  },
};

// Initial values
const {
  email,
  password,
  confirmPassword,
} = resetPasswordFormModel;

// validation schema
export const resetPasswordValidationSchema = Yup.object().shape({
  [password.name]: Yup.string().matches(PASSWORD_REGEX, password.invalidErrorMsg)
    .required(password.requiredErrorMsg),
  [confirmPassword.name]: Yup.string().required(confirmPassword.requiredErrorMsg)
    .oneOf([Yup.ref(password.name), null], confirmPassword.invalidErrorMsg)
});

function PasswordResetModal({
  open, onClose, userEmail
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);

  const initialValues = {
    [email.name]: userEmail,
    [password.name]: '',
    [confirmPassword.name]: ''
  };

  useEffect(() => {
    if (open) {
      setErrorMsg(null);
    }
  }, [open]);

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const res = await APIService.resetPassword(values);
      if (!res.success) throw res;
      onClose();
      dispatch(
        setSuccessInfo({ successMsg: 'Password reset successful!' })
      );
    } catch (error) {
      actions.setSubmitting(false);
      setErrorMsg('Unable to reset password, Please try again!');
    } finally {
      setLoading(false);
    }
  };

  return (
    <FMSModal
      open={open}
      maxWidth="md"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Reset Password
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id="reset-password-form">
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={email.name}
                        label={email.label}
                        disabled
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} />
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={password.name}
                        label={password.label}
                        type={password.type}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={confirmPassword.name}
                        label={confirmPassword.label}
                        type={confirmPassword.type}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <FormHelperText error>{errorMsg}</FormHelperText>
                <DialogActions>
                  <Button variant="outlined" size="large" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    Reset Password
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
}

PasswordResetModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
};

export default PasswordResetModal;
