import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { getAllVehicles } from 'src/redux/vehicleSlice';
import { getAllSites } from 'src/redux/siteSlice';
import InputField from 'src/common/formFields/InputField';
import FMSModal from 'src/common/FMSModal';
import SelectField from 'src/common/formFields/SelectField';
import { createVehicleBreakDown } from 'src/redux/vehicleBreakDownSlice';
import AutocompleteField from 'src/common/formFields/AutocompleteField';
import {
  vehicleBreakDownFormModel,
  vehicleBreakDownFormInitialValues,
  vehicleBreakDownValidationSchema
} from './breakdownFormModel';

const BreakdownForm = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { allVehicles } = useSelector((state) => state.vehicles);
  const {
    formId,
    formField: {
      vehicleNumber,
      breakDownType,
      otherType,
      description,
      location,
    }
  } = vehicleBreakDownFormModel;

  useEffect(async () => {
    if (open) {
      dispatch(getAllSites());
      dispatch(getAllVehicles());
    }
  }, [open]);

  const onSubmit = async (values, actions) => {
    const formValues = { ...values };
    if (values.issue_type === 'other') {
      formValues.issue_type = values.other_type;
    }
    setLoading(true);
    try {
      await dispatch(createVehicleBreakDown(formValues)).unwrap();
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'New Vehicle Breakdown has been created successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Create Vehicle Breakdown
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={vehicleBreakDownFormInitialValues}
        validationSchema={vehicleBreakDownValidationSchema}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <AutocompleteField
                        name={vehicleNumber.name}
                        label={vehicleNumber.label}
                        options={allVehicles}
                        getOptionLabel={(option) => option.registration_num}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={location.name}
                        label={location.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={breakDownType.name}
                        label={breakDownType.label}
                        data={breakDownType.data}
                        fullWidth
                      />
                    </Grid>
                    {values.issue_type === 'other' && (
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={otherType.name}
                        label={otherType.label}
                        fullWidth
                      />
                    </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={description.name}
                        label={description.label}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    Create Breakdown
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

BreakdownForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default BreakdownForm;
