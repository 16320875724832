import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import ROUTE from 'src/utils/routeConstants';
import { useDispatch, useSelector } from 'react-redux';
import { TABLE_ROWS_PER_PAGE, TRANSACTION_TYPES, USER_ROLES } from 'src/utils/constants';
import {
  getTransactionList,
  loadMoreTransactionList
} from 'src/redux/transactionSlice';
import Loader from 'src/common/Loader';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { transactionTableHeaders } from 'src/utils/tableHeaders';
import {
  getFormattedDate, getFuelPrice, getFuelQuantity, getTransactionQueryParams, isAdminUser, removeLeadingZeros
} from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';

const TransactionListTable = ({
  isSales, refreshData, searchText, dateFilter
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isApprover = user?.role === USER_ROLES.approver;
  const {
    transactionList, transactionType, totalCount, loading
  } = useSelector(
    (state) => state.transactions
  );
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('order_id');

  useEffect(() => {
    if (transactionList.length === 0 || refreshData) {
      dispatch(getTransactionList({ transactionType, limit }));
    }
    // Reset table config on group change
    const orderById = transactionType === TRANSACTION_TYPES[0].id ? 'order_id' : 'id';
    setOrderBy(orderById);
    setPage(0);
  }, [transactionType]);

  useEffect(() => {
    if (mounted.current) {
      dispatch(
        getTransactionList({
          transactionType,
          limit,
          params: getTransactionQueryParams(isSales, searchText, dateFilter),
        })
      );
    }
    mounted.current = true;
  }, [searchText, dateFilter]);

  const loadMoreTransactions = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > transactionList.length) {
      dispatch(loadMoreTransactionList({
        transactionType,
        limit: newLimit,
        pageNo: newPage,
        params: getTransactionQueryParams(isSales, searchText, dateFilter)
      }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getTransactionList({
      transactionType,
      limit: newLimit,
      params: getTransactionQueryParams(isSales, searchText, dateFilter)
    }));
  };

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
    loadMoreTransactions(newPage, limit);
  };

  const viewTransactionInfo = (transactionId) => {
    history.push(`${ROUTE.transactions}/${transactionId}`);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const isDetailsDisabled = () => {
    let disableDetails = !isAdmin;
    if (transactionType === TRANSACTION_TYPES[1].id && isApprover) {
      disableDetails = false;
    }
    return disableDetails;
  };

  const renderTableData = (transaction) => {
    // Inward transaction details
    if (transactionType === TRANSACTION_TYPES[1].id) {
      return (
        <>
          <TableCell>{transaction.id}</TableCell>
          <TableCell>
            {getFormattedDate(transaction.order_date)}
          </TableCell>
          <TableCell>
            {getFormattedDate(transaction.delivery_date)}
          </TableCell>
          <TableCell>{transaction.site_name}</TableCell>
          <TableCell>{transaction.invoice_number}</TableCell>
          <TableCell>
            <Box sx={{ alignItems: 'center', display: 'flex' }}>
              <Typography color="textPrimary" variant="body1">
                {getFuelQuantity(transaction.actual_filled || transaction.quantity)}
              </Typography>
            </Box>
          </TableCell>
          <TableCell>{getFuelPrice(transaction.price_per_litre)}</TableCell>
          <TableCell>{transaction.supplier}</TableCell>
          <TableCell>{transaction.received_tank_name || 'NA'}</TableCell>
        </>
      );
    }
    // Sales transaction details
    return (
      <>
        <TableCell>{transaction.order_id}</TableCell>
        <TableCell>{getFormattedDate(transaction.start_time)}</TableCell>
        <TableCell>{transaction.site_name}</TableCell>
        <TableCell>{transaction.address}</TableCell>
        <TableCell>{transaction.nozzle_num}</TableCell>
        <TableCell>{removeLeadingZeros(transaction.card_num_1)}</TableCell>
        <TableCell>{removeLeadingZeros(transaction.card_num_2)}</TableCell>
        <TableCell>{transaction.vehicle_number}</TableCell>
        <TableCell>
          <Box sx={{ alignItems: 'center', display: 'flex' }}>
            <Typography color="textPrimary" variant="body1">
              {getFuelQuantity(transaction.quantity)}
            </Typography>
          </Box>
        </TableCell>
        {/* <TableCell>{getFuelPrice(transaction.unit_price)}</TableCell> */}
        {/* <TableCell>{getFuelPrice(transaction.amount)}</TableCell> */}
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={transactionTableHeaders[transactionType]}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {transactionList
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .map((transaction) => (
                      <TableRow hover key={transaction.id}>
                        {renderTableData(transaction)}
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => viewTransactionInfo(transaction.id)}
                            size="small"
                            disabled={isDetailsDisabled()}
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            showFirstButton
            showLastButton
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </Card>
      )}
    </>
  );
};

TransactionListTable.propTypes = {
  refreshData: PropTypes.bool,
  isSales: PropTypes.bool,
  searchText: PropTypes.string,
  dateFilter: PropTypes.string,
};

export default TransactionListTable;
