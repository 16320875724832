import { useState } from 'react';
import PropTypes from 'prop-types';
import { matchPath, useLocation, useHistory } from 'react-router-dom';
import {
  Badge,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useSelector } from 'react-redux';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

const NavItem = ({ item }) => {
  const {
    href, icon: Icon, title, badge
  } = item;
  const history = useHistory();
  const location = useLocation();
  const { pendingApprovalsCount } = useSelector((state) => state.cardApprovals);
  const [open, setOpen] = useState(false);

  const isActivePath = (path = href) => {
    let isActive = false;
    if (path) {
      isActive = !!matchPath(location.pathname, {
        path,
        end: false
      });
    }
    return isActive;
  };

  const navigateToPath = (path = href) => {
    const pathInfo = {
      pathname: path,
      state: { fromDashboard: true }
    };
    history.push(pathInfo);
  };

  const handleMenuClick = () => {
    if (item.subMenu) {
      setOpen(!open);
    } else {
      navigateToPath();
    }
  };

  if (item.hidden) {
    return <></>;
  }

  return (
    <>
      <ListItemButton
        disableGutters
        onClick={handleMenuClick}
      >
        {Icon && (
        <ListItemIcon
          sx={{
            color: 'rgb(157, 164, 174)',
            minWidth: 44,
            ...(isActivePath() && { color: 'primary.main' })
          }}
        >
          <Icon size={22} sx={{ fontWeight: 600 }} />
        </ListItemIcon>
        )}
        <ListItemText
          primary={title}
          primaryTypographyProps={{ sx: { fontWeight: 500 } }}
          sx={{
            color: 'rgb(157, 164, 174)',
            ...(isActivePath() && { color: '#f5f5f5' })
          }}
        />
        {badge && <Badge badgeContent={pendingApprovalsCount} color="error" />}
        {item.subMenu && (open ? <ExpandLess /> : (<ExpandMore />))}
      </ListItemButton>
      {item.subMenu && (
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.subMenu.map((subMenu) => (
            subMenu.hidden ? <></>
              : (
                <ListItemButton key={subMenu.href} sx={{ pl: 5, py: 0.5 }} onClick={() => navigateToPath(subMenu.href)}>
                  <ListItemText
                    primary={subMenu.title}
                    primaryTypographyProps={{ sx: { fontWeight: 500 } }}
                    sx={{
                      color: 'rgb(157, 164, 174)',
                      ...(isActivePath(subMenu.href) && { color: '#f5f5f5' })
                    }}
                  />
                </ListItemButton>
              )))}
        </List>
      </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.object.isRequired
};

export default NavItem;
