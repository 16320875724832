import 'react-perfect-scrollbar/dist/css/styles.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider } from 'react-redux';
import 'src/components/GlobalStyles.css';
import theme from 'src/theme';
import MainRoute from 'src/routes';
import { AuthProvider } from 'src/context/AuthContext';
import store from 'src/redux/store';
import { injectStore } from './api/api';
import SessionTimeoutModal from './common/SessionTimeoutModal';

injectStore(store);

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <SessionTimeoutModal />
        <MainRoute />
      </AuthProvider>
    </ThemeProvider>
  </Provider>
);

export default App;
