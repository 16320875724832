import React from 'react';
import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { renderHelperText } from 'src/utils/getInitials';

export default function CheckboxField(props) {
  const { label, name, ...rest } = props;
  const formikProps = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    formikProps.setFieldValue(name, event.target.checked);
  };

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={field.value}
        checked={field.value}
        control={<Checkbox {...field} />}
        onChange={handleChange}
        label={label}
      />
      {renderHelperText(meta)}
    </FormControl>
  );
}

CheckboxField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string
};
