import * as Yup from 'yup';

// limit rquest report form model
export const limitRequestReportFormModel = {
  formId: 'approvalsReportConfig',
  formField: {
    cardNumber: {
      name: 'card__uid',
      label: 'Select Card',
      type: 'select',
      data: [{ id: 'all', uid: 'All' }]
    },
    vehicleNumber: {
      name: 'vehicle__registration_num',
      label: 'Select Vehicle',
      type: 'select',
      data: [{ id: 'all', registration_num: 'All' }]
    },
    vehicleGroup: {
      name: 'vehicle__group',
      label: 'Select Vehicle Group',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    requestStatus: {
      name: 'status',
      label: 'Select Request Status',
      type: 'select',
      data: [
        { id: 'all', name: 'All' },
        { id: 'pending', name: 'Pending' },
        { id: 'approved', name: 'Approved' },
        { id: 'rejected', name: 'Rejected' },
        { id: 'expired', name: 'Expired' },
        { id: 'cancelled', name: 'Cancelled' },
        { id: 'completed', name: 'Completed' },
      ]
    },
    startTime: {
      name: 'requested_on__gte',
      label: 'Start Date',
      type: 'date',
      errorMsg: 'Start date is required!'
    },
    endTime: {
      name: 'requested_on__lte',
      label: 'End Date',
      type: 'date',
      errorMsg: 'End date is required!'

    }
  }
};

// Initial values
const {
  formField: {
    requestStatus,
    cardNumber,
    vehicleGroup,
    vehicleNumber,
    startTime,
    endTime
  }
} = limitRequestReportFormModel;

export const limitRequestReportFormInitialValues = {
  [requestStatus.name]: 'all',
  [cardNumber.name]: 'all',
  [vehicleGroup.name]: 'all',
  [vehicleNumber.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const limitRequestReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(startTime.errorMsg)
});
