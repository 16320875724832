import * as Yup from 'yup';

// report form model
export const reconciliationReportFormModel = {
  formId: 'reconciliationReportConfig',
  formField: {
    site: {
      name: 'dispenser__site',
      label: 'Select Site',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    address: {
      name: 'dispenser__address',
      label: 'Select Dispenser',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    startTime: {
      name: 'date__gte',
      label: 'Start Date',
      errorMsg: 'Start date is required!',
      type: 'date'
    },
    endTime: {
      name: 'date__lte',
      label: 'End Date',
      errorMsg: 'End date is required!',
      type: 'date'
    }
  }
};

// Initial values
const {
  formField: {
    site,
    address,
    startTime,
    endTime
  }
} = reconciliationReportFormModel;

export const reconciliationReportFormInitialValues = {
  [site.name]: 'all',
  [address.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const reconciliationReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});
