import { common } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#f5f5f5',
      paper: common.white
    },
    primary: {
      contrastText: '#FEFEFE',
      main: '#EE621C'
    },
    secondary: {
      main: '#2b2d42'
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c'
    },
    success: {
      main: '#16CB77',
      secondary: '#14C990'
    }
  },
  shadows,
  typography,
});

export default theme;
