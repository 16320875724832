import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import NewDriver from 'src/components/drivers/NewDriver';
import DriverListTable from 'src/components/drivers/DriverListTable';
import ScreenHeader from 'src/common/ScreenHeader';

const Drivers = ({ location }) => {
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (str) => setSearchText(str);

  return (
    <>
      <ScreenHeader title="Drivers" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Driver Name"
            actionName="Add Driver"
            onSearch={handleSearch}
            onAction={() => setOpenModal(true)}
          />
          <NewDriver open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <DriverListTable refreshData={location.state?.fromDashboard} searchText={searchText} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

Drivers.propTypes = {
  location: PropTypes.object
};

export default Drivers;
