import PropTypes from 'prop-types';
import { Form, useFormik, FormikProvider } from 'formik';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { approveRejectCardLimit, getApprovalsCount, getCardApprovalsList } from 'src/redux/cardApprovalSlice';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import FMSModal from 'src/common/FMSModal';
import InputField from 'src/common/formFields/InputField';
import DatePickerField from 'src/common/formFields/DatePickerField';
import {
  cardApprovalActionFormModel,
  cardApprovalActionFormInitialValues,
  cardApprovalActionValidationSchema
} from './approvalActionFormModel';

const ApprovalActionForm = ({ open, handleClose, data }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    formId,
    formField: {
      vehicleNumber, cardName, requestedQuantity,
      tankCapacity, approvedQuantity, requestDate, remarks
    }
  } = cardApprovalActionFormModel;

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      const payload = {
        ...values,
        approved_quantity: Number(values.approved_quantity)
      };
      await dispatch(approveRejectCardLimit(payload)).unwrap();
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Card Limit approval successfully!'
        })
      );
      // reload approvals list
      dispatch(getCardApprovalsList({ limit: 10 }));
      // refresh approval count
      dispatch(getApprovalsCount());
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const formikProps = useFormik({
    initialValues: data || cardApprovalActionFormInitialValues,
    validationSchema: cardApprovalActionValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  useEffect(() => {
    if (open && data) {
      formikProps.setFieldValue(approvedQuantity.name, data.requested_quantity);
    }
  }, [open]);

  const handleFormSubmit = (jsEvent) => {
    // This is a bit of a hack to copy the name and value of
    // the submitted action name into the Formik form
    const { name } = jsEvent.nativeEvent.submitter;
    formikProps.setFieldValue('status', name);
    formikProps.setFieldValue('request_id', data.id);
    return formikProps.handleSubmit(jsEvent);
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Card Limit Approval
      </DialogTitle>
      <Divider />
      <FormikProvider value={formikProps}>
        {loading ? (
          <Loader />
        ) : (
          <Form id={formId} onSubmit={handleFormSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={vehicleNumber.name}
                    label={vehicleNumber.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={cardName.name}
                    label={cardName.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={tankCapacity.name}
                    label={tankCapacity.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerField
                    name={requestDate.name}
                    label={requestDate.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={requestedQuantity.name}
                    label={requestedQuantity.label}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={approvedQuantity.name}
                    label={approvedQuantity.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={remarks.name}
                    label={remarks.label}
                    multiline
                    rows={2}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button variant="outlined" size="large" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                id="rejected"
                name="rejected"
                color="secondary"
                disabled={formikProps.isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Reject
              </Button>
              <Button
                id="approved"
                name="approved"
                color="primary"
                disabled={formikProps.isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Approve
              </Button>
            </DialogActions>
          </Form>
        )}
      </FormikProvider>
    </FMSModal>
  );
};

ApprovalActionForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object
};

export default ApprovalActionForm;
