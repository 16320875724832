import { BREAKDOWN_TYPES } from 'src/utils/constants';
import * as Yup from 'yup';

// vehicle breakdown form model
export const vehicleBreakDownFormModel = {
  formId: 'vehicleBreakdownConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_id',
      label: 'Vehicle Number',
      requiredErrorMsg: 'Vehicle is required!',
      type: 'select',
      data: []
    },
    breakDownType: {
      name: 'issue_type',
      label: 'BreakDown Type',
      requiredErrorMsg: 'BreakDown Type is required!',
      type: 'select',
      data: BREAKDOWN_TYPES
    },
    otherType: {
      name: 'other_type',
      label: 'Other Type',
      requiredErrorMsg: 'Other Type is required!',
      type: 'text',
    },
    description: {
      name: 'description',
      label: 'Description',
      requiredErrorMsg: 'BreakDown Description is required!',
      type: 'text',
    },
    location: {
      name: 'location',
      label: 'Breakdown Location',
      requiredErrorMsg: 'BreakDown Location is required!',
      type: 'text',
    },
    repairCost: {
      name: 'repair_cost',
      label: 'Repair Cost',
      requiredErrorMsg: 'Repair Cost is required!',
      type: 'text',
    },
    remarks: {
      name: 'remarks',
      label: 'Remarks',
      requiredErrorMsg: 'Remarks is required!',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    vehicleNumber,
    breakDownType,
    otherType,
    description,
    location,
    repairCost,
    remarks
  }
} = vehicleBreakDownFormModel;

export const vehicleBreakDownFormInitialValues = {
  [vehicleNumber.name]: '',
  [breakDownType.name]: '',
  [otherType.name]: '',
  [description.name]: '',
  [location.name]: '',
  [repairCost.name]: '',
  [remarks.name]: '',
};

export const vehicleBreakDownValidationSchema = Yup.object().shape({
  [vehicleNumber.name]: Yup.string().required(vehicleNumber.requiredErrorMsg),
  [breakDownType.name]: Yup.string().required(breakDownType.requiredErrorMsg),
  [otherType.name]: Yup.string().when(breakDownType.name, (type, schema) => (type === 'other' ? schema.required(breakDownType.requiredErrorMsg) : schema)),
  [description.name]: Yup.string(),
  [location.name]: Yup.string().required(location.requiredErrorMsg),
});
