import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material';
import { saveAs } from 'file-saver';
import { useDispatch, useSelector } from 'react-redux';
import FMSModal, { FMSModalTitle } from 'src/common/FMSModal';
import SelectField from 'src/common/formFields/SelectField';
import DatePickerField from 'src/common/formFields/DatePickerField';
import { Form, Formik } from 'formik';
import { BASEAPI } from 'src/api/api';
import Loader from 'src/common/Loader';
import { getAllSites } from 'src/redux/siteSlice';
import { filterVehiclesBySite, getAllVehicles } from 'src/redux/vehicleSlice';
import {
  filterDispenserBySite,
  getAllDispensers
} from 'src/redux/dispenserSlice';
import { getAllVehicleGroupList } from 'src/redux/groupSlice';
import { DATE_FORMAT, REPORT_FILTER_OPTIONS } from 'src/utils/constants';
import { getAllTanks, filterTanksBySite } from 'src/redux/tankSlice';
import AutocompleteField from 'src/common/formFields/AutocompleteField';
import { getAllCards } from 'src/redux/cardSlice';

function ReportFilterModal({
  open,
  title,
  downloadUrl,
  handleClose,
  dateFormat,
  filterConfig = REPORT_FILTER_OPTIONS,
  formModel,
  onlyDate = false,
  formValidation,
  formInitValues
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { allSites } = useSelector((state) => state.sites);
  const { vehicleRegNumListBySite } = useSelector((state) => state.vehicles);
  const { dispenserListBySite } = useSelector((state) => state.dispensers);
  const { allVehicleGroups } = useSelector((state) => state.groups);
  const { tankListBySite } = useSelector((state) => state.tanks);
  const { allCards } = useSelector((state) => state.cards);

  const {
    formId,
    formField: {
      site,
      tank,
      address,
      assetType,
      requestStatus,
      cardNumber,
      vehicleGroup,
      vehicleNumber,
      startTime,
      endTime
    }
  } = formModel;

  useEffect(() => {
    if (open) {
      setError(false);
      if (filterConfig.site) {
        dispatch(getAllSites());
      }
      if (filterConfig.vehicle) {
        dispatch(getAllVehicles());
      }
      if (filterConfig.dispenser) {
        dispatch(getAllDispensers());
      }
      if (filterConfig.vehicleGroup) {
        dispatch(getAllVehicleGroupList());
      }
      if (filterConfig.tank) {
        dispatch(getAllTanks());
      }
      if (filterConfig.card) {
        dispatch(getAllCards());
      }
    }
  }, [open]);

  const downloadReport = async (values) => {
    setLoading(true);
    setError(false);
    const keyList = Object.keys(values);
    let queryParams = '';
    keyList.forEach((key) => {
      if (values[key] && values[key] !== 'all') {
        queryParams += `&${key}=${values[key]}`;
      }
    });
    const apiUrl = downloadUrl + queryParams;
    BASEAPI.get(apiUrl, { responseType: 'blob', timeout: 20000 })
      .then((res) => {
        if (res.status !== 200) {
          throw res;
        }
        const fileName = `${title.toLowerCase()} ${moment().format(DATE_FORMAT.reportDateTime)}.xlsx`;
        saveAs(res.data, fileName);
        handleClose();
      })
      .catch((err) => {
        const errMsg = err.status === 204 ? 'No data available! Try with different filters.'
          : 'Error downloading the report. Try again later!';
        setError(errMsg);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // filter tank list based on site id on site change
  const onSiteChange = (e, fn, setValue) => {
    const selectedSiteId = e.target.value;
    if (filterConfig.vehicle) {
      dispatch(filterVehiclesBySite(selectedSiteId));
      setValue(vehicleNumber.name, 'all');
    }
    if (filterConfig.dispenser) {
      dispatch(filterDispenserBySite(selectedSiteId));
      setValue(address.name, 'all');
    }
    if (filterConfig.tank) {
      dispatch(filterTanksBySite(selectedSiteId));
      setValue(tank.name, 'all');
    }
    fn(e);
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <FMSModalTitle title={`Download ${title}`} onCancel={handleClose} />
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={formValidation}
        onSubmit={downloadReport}
      >
        {({ isSubmitting, handleChange, setFieldValue }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    {filterConfig.site && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        {...site}
                        onChange={(e) => onSiteChange(e, handleChange, setFieldValue)}
                        data={[...site.data, ...allSites]}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.dispenser && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        {...address}
                        data={[...address.data, ...dispenserListBySite]}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.tank && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        {...tank}
                        data={[...tank.data, ...tankListBySite]}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.card && (
                    <Grid item xs={12} sm={6}>
                      <AutocompleteField
                        name={cardNumber.name}
                        label={cardNumber.label}
                        options={[...cardNumber.data, ...allCards]}
                        getOptionLabel={(option) => option.uid}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.vehicle && (
                    <Grid item xs={12} sm={6}>
                      <AutocompleteField
                        name={vehicleNumber.name}
                        label={vehicleNumber.label}
                        options={[...vehicleNumber.data, ...vehicleRegNumListBySite]}
                        getOptionLabel={(option) => option.registration_num}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.vehicleGroup && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        {...vehicleGroup}
                        data={[...vehicleGroup.data, ...allVehicleGroups]}
                        fullWidth
                      />
                    </Grid>
                    )}
                    {filterConfig.assetType && (
                      <Grid item xs={12} sm={6}>
                        <SelectField
                          {...assetType}
                          fullWidth
                        />
                      </Grid>
                    )}
                    {filterConfig.requestStatus && (
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        {...requestStatus}
                        fullWidth
                      />
                    </Grid>
                    )}
                    <Grid item xs={12} sm={6}>
                      <DatePickerField dateFormat={dateFormat} onlyDate={onlyDate} {...startTime} disableFuture />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePickerField dateFormat={dateFormat} onlyDate={onlyDate} {...endTime} disableFuture />
                    </Grid>
                  </Grid>
                  {error && (
                  <Typography
                    variant="subtitle1"
                    sx={{ color: 'red', pt: 2, textAlign: 'center' }}
                  >
                    {error || ''}
                  </Typography>
                  )}
                </DialogContent>
                <DialogActions sx={{ m: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Close
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    Download
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
}

ReportFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  hideDisp: PropTypes.bool,
  onlyDate: PropTypes.bool,
  filterConfig: PropTypes.object,
  formModel: PropTypes.object.isRequired,
  formValidation: PropTypes.object.isRequired,
  formInitValues: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  dateFormat: PropTypes.string,
  downloadUrl: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ReportFilterModal;
