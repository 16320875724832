import theme from 'src/theme';

export const DATE_FILTER_LIST = [
  { id: '1', name: 'Today' },
  { id: '2', name: '2 Days' },
  { id: '7', name: '7 Days' },
  { id: '30', name: '30 Days' },
];

export const chartInputStyles = {
  mr: 2,
  color: 'white',
  '& .MuiSelect-standard': {
    color: 'white'
  },
  '& .MuiSelect-icon': {
    color: 'white'
  }
};

export const chartOptions = {
  animation: true,
  responsive: true,
  cornerRadius: 20,
  layout: { padding: 0 },
  legend: { display: false },
  maintainAspectRatio: false,
  tooltips: {
    backgroundColor: theme.palette.background.paper,
    bodyFontColor: theme.palette.text.secondary,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    enabled: true,
    footerFontColor: theme.palette.text.secondary,
    intersect: false,
    mode: 'index',
    titleFontColor: theme.palette.text.secondary
  }
};

export const echartOptions = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  legend: {},
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true
  },
  xAxis: {
    type: 'value',
    boundaryGap: [0, 0.01]
  },
};

export const getVehicleUsageChartConfig = (data) => {
  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
      position: [10, 10]
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    color: [
      '#4dd0e1',
      '#ff7043',
      '#ffca28',
      '#536dfe',
      '#8bc34a',
    ],
    series: [
      {
        name: 'Fuel Consumption',
        type: 'pie',
        radius: '80%',
        label: {
          position: 'inside',
          formatter: '{c}'
        },
        itemStyle: {
          borderRadius: 4,
          borderColor: '#eee',
          borderWidth: 1
        },
        bottom: 50,
        data,
        emphasis: {
          label: {
            show: true,
            fontSize: 30,
            fontWeight: 'bold'
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
      }
    ]
  };
  return pieChartOptions;
};

export const getVehicleStatusChartConfig = (data) => {
  const pieChartOptions = {
    tooltip: {
      trigger: 'item',
      position: [10, 10]
    },
    legend: {
      bottom: 0,
      left: 'center',
    },
    series: [
      {
        name: 'Vehicle Status',
        type: 'pie',
        radius: ['40%', '90%'],
        label: {
          position: 'inside',
          formatter: '{c}'
        },
        itemStyle: {
          borderRadius: 4,
          borderColor: '#eee',
          borderWidth: 1
        },
        bottom: 30,
        color: [
          '#e53935',
          '#8bc34a',
          '#ffca28'
        ],
        data,
        emphasis: {
          label: {
            show: true,
            fontSize: 30,
            fontWeight: 'bold'
          },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          },
        },
      }
    ]
  };
  return pieChartOptions;
};

export const getSalesChartData = (data) => {
  const { datasets, labels } = data;
  const chartDataSet = [];
  labels.forEach((label, i) => {
    const dataSet = [label, datasets[0].data[i], datasets[1].data[i], datasets[2].data[i]];
    chartDataSet.push(dataSet);
  });
  const salesChartOptions = {
    legend: {},
    tooltip: {},
    color: [
      '#536dfe',
      '#8bc34a',
      '#4dd0e1',
      '#ffca28',
      '#ff7043',
    ],
    dataset: {
      source: [
        ['product', 'Inward', 'Issued', 'Transfer'],
        ...chartDataSet
      ]
    },
    xAxis: { type: 'category' },
    yAxis: {},
    series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }]
  };
  return salesChartOptions;
};

export const getVehicleUtilizationChartData = (data) => {
  const dataSets = Object.keys(data);
  const chartData = [];
  dataSets.forEach((category) => {
    chartData.push(data[category]?.length || 0);
  });
  const vehicleUtilizationChartOptions = {
    legend: {},
    tooltip: {
      formatter: (params) => `Vehicle Utilization<br/>${params.marker}${params.name}&nbsp;&nbsp;&nbsp;&nbsp;<strong>${params.value}</strong>`
    },
    color: [
      '#4dd0e1',
      '#536dfe',
      '#ff7043',
      '#ffca28',
      '#8bc34a',
    ],
    xAxis: {
      type: 'category',
      data: ['0 - 25%', '25 - 50%', '50 - 75%', '75 - 100%', 'Above 100%']
    },
    yAxis: {
      type: 'value'
    },
    series: [
      {
        data: chartData,
        type: 'bar'
      }
    ]
  };
  return vehicleUtilizationChartOptions;
};
