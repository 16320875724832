import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import Loader from 'src/common/Loader';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import { addApprover, updateApprover } from 'src/redux/approverSlice';
import FMSModal from 'src/common/FMSModal';
import { getAllSites } from 'src/redux/siteSlice';
import theme from 'src/theme';
import {
  getApproverValidationSchema,
  approverFormModel,
  approverFormInitialValues
} from './approverFormModel';

const NewApprover = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const { allSites } = useSelector((state) => state.sites);
  const [loading, setLoading] = useState(false);
  const formInitValues = initialValues || approverFormInitialValues;
  const {
    formId,
    formField: {
      siteId,
      firstName,
      lastName,
      username,
      password,
      confirmPassword,
      email,
      mobileNum,
      empId,
      addressLine,
      city,
      state,
      country,
      pinCode
    }
  } = approverFormModel;

  useEffect(() => {
    if (open) {
      dispatch(getAllSites());
    }
  }, [open]);

  const onSubmit = async (values, actions) => {
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateApprover(values)).unwrap();
      } else {
        await dispatch(addApprover(values)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Approver details updated successfully!'
            : 'New Approver has been created successfully!'
        })
      );
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        {initialValues ? 'Update Approver Details' : 'Add New Approver'}
      </DialogTitle>
      <Divider />
      <Formik
        initialValues={formInitValues}
        validationSchema={getApproverValidationSchema(initialValues)}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <>
            {loading ? (
              <Loader />
            ) : (
              <Form id={formId}>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={siteId.name}
                        label={siteId.label}
                        data={allSites}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={empId.name}
                        label={empId.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={firstName.name}
                        label={firstName.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={lastName.name}
                        label={lastName.label}
                        fullWidth
                      />
                    </Grid>
                    {!initialValues && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <InputField
                          name={password.name}
                          label={password.label}
                          type={password.type}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputField
                          name={confirmPassword.name}
                          label={confirmPassword.label}
                          type={confirmPassword.type}
                          fullWidth
                        />
                      </Grid>
                    </>
                    )}
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={username.name}
                        label={username.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={email.name}
                        label={email.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={mobileNum.name}
                        label={mobileNum.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={addressLine.name}
                        label={addressLine.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={city.name}
                        label={city.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputField
                        name={pinCode.name}
                        label={pinCode.label}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={state.name}
                        label={state.label}
                        data={state.data}
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectField
                        name={country.name}
                        label={country.label}
                        data={country.data}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                  <Button variant="outlined" size="large" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                    size="large"
                    variant="contained"
                  >
                    {initialValues ? 'Update Approver' : 'Add Approver'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </>
        )}
      </Formik>
    </FMSModal>
  );
};

NewApprover.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewApprover;
