import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FMSModal from './FMSModal';

function ConfirmationDialog({
  open, info, actionName, disable, onClose, onSubmit
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const actionBtnName = disable ? 'Disable' : 'Enable';

  return (
    <FMSModal
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
    >
      <DialogTitle sx={{ fontSize: 18 }}>
        Confirmation
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {info}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ pb: 2 }}>
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          {actionName || actionBtnName}
        </Button>
      </DialogActions>
    </FMSModal>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  actionName: PropTypes.string,
  info: PropTypes.string.isRequired,
  onSubmit: PropTypes.func,
  disable: PropTypes.bool
};

export default ConfirmationDialog;
