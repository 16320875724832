import { useEffect, useState } from 'react';
import { Box, Container, Grid } from '@mui/material';
import { green, indigo, red } from '@mui/material/colors';
import { APIService } from 'src/api/api';
import DashboardCard from 'src/components/dashboard/DashboardCard';
import SalesChart from 'src/components/dashboard/SalesChart';
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import CommuteRoundedIcon from '@mui/icons-material/CommuteRounded';
import LocalGasStationRoundedIcon from '@mui/icons-material/LocalGasStationRounded';
import TotalProfit from 'src/components/dashboard//TotalProfit';
import StockInfo from 'src/components/dashboard/StockInfo';
import ConsumptionChart from 'src/components/dashboard/ConsumptionChart';
import VehicleGroupConsumptionChart from 'src/components/dashboard/VehicleGroupConsumptionChart';
import VehicleStatusChart from 'src/components/dashboard/VehicleStatusChart';
import ScreenHeader from 'src/common/ScreenHeader';
import VehicleUtilizationChart from 'src/components/dashboard/VehicleUtilizationChart';

const Dashboard = () => {
  const [cardData, setCardData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await APIService.getDashboardCount();
        if (!res.success) throw res;
        setCardData(res.result);
      } catch (error) {
        console.log('dashboard count error', error.message);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <ScreenHeader title="Dashboard" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 4
        }}
      >
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <DashboardCard
                    title="SITES"
                    data={cardData.sites}
                    icon={<LocationOnRoundedIcon />}
                    iconColor={indigo[600]}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <DashboardCard
                    title="TANKS"
                    data={cardData.dispensers}
                    icon={<LocalGasStationRoundedIcon />}
                    iconColor={green[700]}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <DashboardCard
                    title="VEHICLES"
                    data={cardData.vehicles}
                    icon={<CommuteRoundedIcon />}
                    iconColor={red[600]}
                  />
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <TotalProfit salesCount={cardData.today_sale} sx={{ height: '100%' }} />
                </Grid>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <SalesChart />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={6} sm={12} xs={12}>
                  <StockInfo />
                </Grid>
                <Grid item lg={12} md={6} sm={12} xs={12}>
                  <VehicleStatusChart />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={8} md={7} sm={12} xs={12}>
              <VehicleUtilizationChart />
            </Grid>
            <Grid item lg={4} md={5} sm={12} xs={12}>
              <VehicleGroupConsumptionChart />
            </Grid>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <ConsumptionChart />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
