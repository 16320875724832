import { memo, useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  MenuItem,
  Select,
} from '@mui/material';
import { useHistory } from 'react-router';
import { APIService } from 'src/api/api';
import { getAllSites } from 'src/redux/siteSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ReactECharts from 'src/common/ReactECharts';
import ROUTE from 'src/utils/routeConstants';
import { chartInputStyles, getVehicleUtilizationChartData } from './chartConfig';
import { getVehicleUtilizationList } from './chartService';

const VehicleUtilizationChart = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { allSites } = useSelector((state) => state.sites);
  const [loading, setLoading] = useState(true);
  const [salesData, setSalesData] = useState(null);
  const [selectedSite, setSelectedSite] = useState('all');
  const [vehicleList, setVehicleList] = useState(null);
  const siteData = [{ id: 'all', name: 'All Sites' }, ...allSites];

  const fetchChartData = async (id) => {
    try {
      const siteId = id === 'all' ? '' : id;
      const res = await APIService.getVehicleUtilization(siteId);
      if (!res.success) throw res;
      setVehicleList(res.result);
      const chartOptions = getVehicleUtilizationChartData(res.result);
      setSalesData(chartOptions);
    } catch (error) {
      console.log('dashboard sales error', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData(selectedSite);
    dispatch(getAllSites());
  }, []);

  const handleSiteChange = (e) => {
    const siteId = e.target.value;
    setSelectedSite(siteId);
    setLoading(true);
    fetchChartData(siteId);
  };

  const onChartClick = (chartData) => {
    const vehicleData = getVehicleUtilizationList(vehicleList, chartData.dataIndex);
    history.push(ROUTE.vehicleStatusList, vehicleData);
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <Card {...props}>
      <CardHeader
        title="Vehicle Utilization"
        sx={{
          background: 'linear-gradient(180deg, rgb(66, 66, 74), rgb(25, 25, 25))',
          color: '#fff'
        }}
        action={(
          <Select
            name="site"
            variant="standard"
            onChange={handleSiteChange}
            value={selectedSite}
            sx={chartInputStyles}
          >
            {siteData.map((item) => (
              <MenuItem value={item.id} key={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      <Divider />
      <CardContent sx={{ px: 0 }}>
        <Box sx={{ height: 400, position: 'relative' }}>
          {loading || !salesData ? (
            <Loader />
          ) : (
            <ReactECharts options={salesData} onEvents={onEvents} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(VehicleUtilizationChart);
