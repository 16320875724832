import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from '@mui/material';
import { useHistory } from 'react-router';
import { memo, useEffect, useState } from 'react';
import Loader from 'src/common/Loader';
import { APIService } from 'src/api/api';
import ReactECharts from 'src/common/ReactECharts';
import ROUTE from 'src/utils/routeConstants';
import { getVehicleStatusChartConfig } from './chartConfig';
import { getVehicleStatusList } from './chartService';

const VehicleStatusChart = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [usageData, setUsageData] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);

  const formatToEChartData = (status) => {
    const data = [
      { value: status.breakdown?.length, name: 'Breakdown' },
      { value: status.active?.length, name: 'Engage' },
      { value: status.inactive?.length, name: 'Idle' },
    ];
    const chartData = getVehicleStatusChartConfig(data);
    setUsageData(chartData);
  };

  const fetchChartData = async () => {
    try {
      const res = await APIService.getVehicleStatus();
      if (!res.success) throw res;
      setVehicleList(res.result);
      formatToEChartData(res.result);
    } catch (error) {
      console.log('vehicle status error', error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const onChartClick = (chartData) => {
    console.log(chartData);
    const vehicleData = getVehicleStatusList(vehicleList, chartData);
    history.push(ROUTE.vehicleStatusList, vehicleData);
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <Card {...props}>
      <CardHeader
        title="Vehicle Running Status"
        sx={{
          background: 'linear-gradient(180deg, rgb(66, 66, 74), rgb(25, 25, 25))',
          color: '#fff'
        }}
      />
      <Divider />
      <CardContent sx={{ px: 0 }}>
        <Box sx={{ height: 250, position: 'relative' }}>
          {loading || !usageData ? (
            <Loader />
          ) : (
            <ReactECharts options={usageData} onEvents={onEvents} />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default memo(VehicleStatusChart);
