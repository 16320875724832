import { ARTICLE_TYPE, NUMBER_LIST } from 'src/utils/constants';

export default {
  formId: 'dispenserConfig',
  formField: {
    dispenserMode: {
      name: 'mode',
      label: 'Dispenser Mode',
      requiredErrorMsg: 'Dispenser Mode is required',
      type: 'select',
      data: [
        { id: 'Subscription Close', name: 'Subscription Close' },
        { id: 'Manual Mode', name: 'Manual Mode' },
        { id: 'Online Transactions Uploading', name: 'Online Transactions Uploading' },
        { id: 'Online Card Authorisation', name: 'Online Card Authorisation' },
        { id: 'Offline Cards Management', name: 'Offline Cards Management' },
      ]
    },
    serialNumber: {
      name: 'address',
      label: 'Serial Number',
      requiredErrorMsg: 'Serial Number is required',
      invalidErrorMsg: 'Serial Number is invalid',
      type: 'text',
    },
    kfactor: {
      name: 'kfactor',
      label: 'Meter Factor',
      requiredErrorMsg: 'Kfactor is required',
      type: 'text',
    },
    articleUnit: {
      name: 'article_unit',
      label: 'Article Unit',
      requiredErrorMsg: 'Article Unit is required',
      invalidErrorMsg: 'Article Unit is invalid',
      type: 'text',
    },
    articlePrice: {
      name: 'price',
      label: 'Article Price',
      requiredErrorMsg: 'Article Price is required',
      type: 'text',
    },
    article: {
      name: 'article',
      label: 'Article',
      requiredErrorMsg: 'Article type is required',
      type: 'select',
      data: ARTICLE_TYPE
    },
    pulseType: {
      name: 'pulse_type',
      label: 'Pulse Type',
      requiredErrorMsg: 'Pulse type is required',
      type: 'select',
      data: [
        { id: 'Single', name: 'Single' },
        { id: 'Dual', name: 'Dual' },
        { id: 'RS485', name: 'RS485' },
      ]
    },
    pulseDirection: {
      name: 'pulse_direction',
      label: 'Pulse Direction',
      requiredErrorMsg: 'Pulse Direction is required',
      type: 'select',
      data: [
        { id: 'Any Direction', name: 'Any Direction' },
        { id: 'Unidirection', name: 'Unidirection' },
      ]
    },
    throttleVolume: {
      name: 'throttle_volume',
      label: 'Throttle Volume',
      requiredErrorMsg: 'Throttle Volume is required',
      type: 'text',
    },
    throttleStage: {
      name: 'throttle_stage',
      label: 'Throttle Stage',
      requiredErrorMsg: 'Throttle Stage is required',
      type: 'select',
      data: [
        { id: 'Single Stage', name: 'Single Stage' },
        { id: 'Dual Stage', name: 'Dual Stage' },
        { id: 'Multistage', name: 'Multistage' },
      ]
    },
    minFlowRate: {
      name: 'min_flow_rate',
      label: 'Min Flow Rate',
      requiredErrorMsg: 'Min Flow Rate is required',
      type: 'text',
    },
    maxFlowRate: {
      name: 'max_flow_rate',
      label: 'Max Flow Rate',
      requiredErrorMsg: 'Max Flow Rate is required',
      type: 'text',
    },
    maxFuelingTime: {
      name: 'max_fueling_time',
      label: 'Max Fuelling Time',
      requiredErrorMsg: 'Max Flow Rate is required',
      type: 'text',
    },
    fuelBreakTime: {
      name: 'fuel_break_time',
      label: 'Fuel Break Time',
      requiredErrorMsg: 'Fuel Break Time is required',
      type: 'text',
    },
    fuelStartTime: {
      name: 'fuel_start_time',
      label: 'Fuel Start Time',
      requiredErrorMsg: 'Fuel Start Time is required',
      type: 'text',
    },
    odometerTimeout: {
      name: 'odometer_timeout',
      label: 'Odometer Timeout',
      requiredErrorMsg: 'Odometer Timeout is required',
      type: 'text',
    },
    addInputTimeout: {
      name: 'add_input_timeout',
      label: 'Add Input Timeout',
      requiredErrorMsg: 'Add Input Timeout is required',
      type: 'text',
    },
    pinEntryTimeout: {
      name: 'pin_entry_timeout',
      label: 'Pin Entry Timeout',
      requiredErrorMsg: 'Pin Entry Timeout is required',
      type: 'text',
    },
    nozzleTimeout: {
      name: 'nozzle_timeout',
      label: 'Nozzle Timeout',
      requiredErrorMsg: 'Nozzle Timeout is required',
      type: 'text',
    },
    nozzleId: {
      name: 'nozzle_id',
      label: 'Nozzle ID',
      requiredErrorMsg: 'Nozzle ID is required',
      invalidErrorMsg: 'Nozzle ID is invalid',
      type: 'text',
    },
    totalizerStartReading: {
      name: 'totalizer_start_reading',
      label: 'Totalizer Start Reading',
      requiredErrorMsg: 'Totalizer Start Reading is required',
      type: 'text',
    },
    densityStartReading: {
      name: 'density_start_reading',
      label: 'Density start reading',
      requiredErrorMsg: 'Density start reading is required',
      type: 'text',
    },
    volumeDecimal: {
      name: 'volume_decimal',
      label: 'Volume Decimal',
      requiredErrorMsg: 'volume_decimal is required',
      type: 'text',
    },
    amountDecimal: {
      name: 'amount_decimal',
      label: 'Amount Decimal',
      requiredErrorMsg: 'amount_decimal is required',
      type: 'text',
    },
    unitPriceDecimal: {
      name: 'unit_price_decimal',
      label: 'Unit price decimal',
      requiredErrorMsg: 'Unit price decimal is required',
      type: 'text',
    },
    atgConnection: {
      name: 'atg_connection',
      label: 'Atg connection',
      requiredErrorMsg: 'Atg connection is required',
      type: 'switch',
    },
    atgType: {
      name: 'atg_type',
      label: 'ATG Type',
      requiredErrorMsg: 'ATG Type is required',
      type: 'select',
      data: [
        { id: 'No ATG', name: 'No ATG' },
        { id: 'Analog', name: 'Analog' },
        { id: 'RS232', name: 'RS232' },
        { id: 'RS485', name: 'RS485' },
      ]
    },
    taxPercent: {
      name: 'tax_percent',
      label: 'Tax Percent decimal',
      requiredErrorMsg: 'Tax Percent decimal is required',
      type: 'text',
    },
    printerField: {
      name: 'printer',
      label: 'Enable Printer',
      requiredErrorMsg: 'Printer Field is required',
      type: 'switch',
    },
    locationField: {
      name: 'location',
      label: 'Enable Location',
      requiredErrorMsg: 'Location Field is required',
      type: 'switch',
    },
    cardReaderField: {
      name: 'card_reader',
      label: 'Enable Card Reader',
      requiredErrorMsg: 'Card Reader Field is required',
      type: 'switch',
    },
    fuellingWithIDInput: {
      name: 'fueling_with_id_input',
      label: 'Fuelling with ID Input',
      requiredErrorMsg: 'Fuelling with ID Input is required',
      type: 'switch',
    },
    backlight: {
      name: 'back_light',
      label: 'Enable Backlight',
      requiredErrorMsg: 'Backlight is required',
      type: 'switch',
    },
    dateTimeSyncField: {
      name: 'date_time_sync',
      label: 'Date/Time Sync',
      requiredErrorMsg: 'Date/Time Sync Field is required',
      type: 'switch',
    },
    dateTime: {
      name: 'date_time',
      label: 'Date/Time',
      requiredErrorMsg: 'Date/Time Field is required',
      type: 'date',
    },
    password1: {
      name: 'password1',
      label: 'Password 1',
      requiredErrorMsg: '',
      invalidErrorMsg: 'Password must contains only alphanumeric characters',
      type: 'text',
    },
    password2: {
      name: 'password2',
      label: 'Password 2',
      requiredErrorMsg: '',
      invalidErrorMsg: 'Password must contains only alphanumeric characters',
      type: 'text',
    },
    alarms: {
      name: 'alarm',
      label: 'Alarms',
      requiredErrorMsg: 'Alarms is required',
      type: 'text',
    },
    stationName: {
      name: 'station_name',
      label: 'Dispenser Name',
      requiredErrorMsg: 'Dispenser name is required',
      invalidErrorMsg: 'Dispenser name is invalid',
      type: 'text',
    },
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Vehicle Number',
      requiredErrorMsg: 'Vehicle Number is required',
      invalidErrorMsg: 'Vehicle Number is invalid',
      type: 'text',
    },
    updatedField: {
      name: 'updated_field',
      label: 'Updated Field',
      requiredErrorMsg: '',
      type: 'text',
    },
    shiftStartTime: {
      name: 'shift_start_time',
      label: 'Shift Start Time',
      requiredErrorMsg: '',
      type: 'time',
    },
    shiftDuration: {
      name: 'shift_duration',
      label: 'Shift Duration',
      requiredErrorMsg: '',
      type: 'text',
    },
    emptyFillingAllowed: {
      name: 'empty_fill_allowed',
      label: 'Empty Filling Allowed',
      requiredErrorMsg: '',
      type: 'text',
    },
    dispenserMount: {
      name: 'dispenser_mount',
      label: 'Dispenser Mount',
      requiredErrorMsg: '',
      type: 'select',
      data: [
        { id: 'Mobile', name: 'Mobile' },
        { id: 'Stationary', name: 'Stationary' },
        { id: 'Decant', name: 'Decant' },
      ]
    },
    dpNo: {
      name: 'dp_no',
      label: 'DP No/Nozzle No',
      requiredErrorMsg: '',
      type: 'text',
    },
    dpInterface: {
      name: 'dp_interface',
      label: 'DP Interface',
      requiredErrorMsg: '',
      type: 'select',
      data: [
        { id: 'Tokheim', name: 'Tokheim' },
        { id: 'GVR', name: 'GVR' },
        { id: 'Pulse', name: 'Pulse' },
      ]
    },
    dpAddress: {
      name: 'dp_address',
      label: 'DP Address',
      requiredErrorMsg: '',
      type: 'text',
    },
    sequentialDIspenserID: {
      name: 'sequential_dispenser_id',
      label: 'Sequential DIspenser ID',
      requiredErrorMsg: '',
      type: 'text',
    },
    onlineConnectionTimeout: {
      name: 'online_connection_timeout',
      label: 'Online connection timeout',
      requiredErrorMsg: 'Online connection timeout is required',
      type: 'text',
    },
    maxQuantityPerTransaction: {
      name: 'max_quantity_per_transaction',
      label: 'Max quantity per transaction',
      requiredErrorMsg: 'Max quantity per transaction is required',
      type: 'text',
    },
    kilometerDifference: {
      name: 'kilometer_difference',
      label: 'Kilometer Difference',
      requiredErrorMsg: 'Kilometer Difference is required',
      type: 'text',
    },
    minDigitsEntry: {
      name: 'max_digits_of_entry',
      label: 'Minimum Digits of Entry',
      requiredErrorMsg: '',
      data: NUMBER_LIST,
      type: 'select',
    },
    site: {
      name: 'site',
      label: 'Select Site',
      requiredErrorMsg: 'Site is required',
      type: 'select',
      data: []
    },
    tankId: {
      name: 'tank_id',
      label: 'Select Tank',
      requiredErrorMsg: 'Tank is required',
      type: 'select',
      data: []
    },
  }
};
