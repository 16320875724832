import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';

const initialState = {
  shiftDetails: {},
  configuration: {},
  loading: false,
};

export const getShiftDetails = createAsyncThunk(
  'settings/shift/details', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getShiftDetails();
      if (!res.success) throw res;
      return res.result.results;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateShiftDetails = createAsyncThunk(
  'settings/update/shift', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateShift(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addShiftDetails = createAsyncThunk(
  'settings/create/shift', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createShift(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getShiftDetails.fulfilled, (state, action) => ({
        ...state, shiftDetails: action.payload[0]
      }))
      .addCase(updateShiftDetails.fulfilled, (state, action) => ({
        ...state, loading: false, shiftDetails: action.payload
      }))
      .addCase(updateShiftDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(updateShiftDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(addShiftDetails.fulfilled, (state, action) => ({
        ...state, loading: false, shiftDetails: action.payload
      }))
      .addCase(addShiftDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(addShiftDetails.rejected, (state) => ({ ...state, loading: false }));
  }
});

export default settingSlice.reducer;
