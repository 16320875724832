import * as Yup from 'yup';
import STATES_LIST from 'src/utils/stateList.json';
import COUNTRY_LIST from 'src/utils/countryList.json';
import {
  ADDRESS_REGEX,
  CODE_REGEX, ENTITY_NAME_REGEX, LATITUDE_REGEX, LONGITUDE_REGEX, NAME_REGEX, PINCODE_REGEX
} from 'src/utils/constants';

// site form model
export const siteFormModel = {
  formId: 'siteConfig',
  formField: {
    siteName: {
      name: 'name',
      label: 'Site Name',
      requiredErrorMsg: 'Site Name is required',
      invalidErrorMsg: 'Site Name is invalid',
      type: 'text',
    },
    siteCode: {
      name: 'code',
      label: 'Site Code',
      requiredErrorMsg: 'Site Code is required',
      invalidErrorMsg: 'Site Code is invalid',
      type: 'text',
    },
    addressLine: {
      name: 'address_line1',
      label: 'Address',
      requiredErrorMsg: 'Address is required',
      invalidErrorMsg: 'Invalid address',
      type: 'text',
    },
    city: {
      name: 'city',
      label: 'City',
      requiredErrorMsg: 'City is required',
      invalidErrorMsg: 'City name is invalid',
      type: 'text',
    },
    state: {
      name: 'state',
      label: 'Select State',
      requiredErrorMsg: 'State is required',
      type: 'select',
      data: STATES_LIST
    },
    country: {
      name: 'country',
      label: 'Country',
      requiredErrorMsg: 'Country is required',
      type: 'select',
      data: COUNTRY_LIST
    },
    pinCode: {
      name: 'pin_code',
      label: 'Pincode',
      requiredErrorMsg: 'Pincode is required',
      invalidErrorMsg: 'Pincode is Invalid',
      type: 'text',
    },
    latitude: {
      name: 'latitude',
      label: 'Latitude',
      requiredErrorMsg: 'Latitude is required',
      invalidErrorMsg: 'Latitude is invalid',
      type: 'text',
    },
    longitude: {
      name: 'longitude',
      label: 'Longitude',
      requiredErrorMsg: 'Longitude is required',
      invalidErrorMsg: 'Longitude is invalid',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    siteName,
    siteCode,
    addressLine,
    city,
    state,
    country,
    pinCode,
    latitude,
    longitude,
  }
} = siteFormModel;

export const siteFormInitialValues = {
  [siteName.name]: '',
  [siteCode.name]: '',
  [addressLine.name]: '',
  [city.name]: '',
  [state.name]: '',
  [country.name]: 'IN',
  [pinCode.name]: '',
  [latitude.name]: '',
  [longitude.name]: '',
};

// validation schema
export const siteValidationSchema = Yup.object().shape({
  [siteName.name]: Yup.string().max(50).matches(ENTITY_NAME_REGEX, siteName.invalidErrorMsg).required(siteName.requiredErrorMsg),
  [siteCode.name]: Yup.string().min(4).max(20).matches(CODE_REGEX, siteCode.invalidErrorMsg)
    .required(siteCode.requiredErrorMsg),
  [addressLine.name]: Yup.string().max(150).required(addressLine.requiredErrorMsg).matches(ADDRESS_REGEX, addressLine.invalidErrorMsg),
  [city.name]: Yup.string().matches(NAME_REGEX, city.invalidErrorMsg).required(city.requiredErrorMsg),
  [state.name]: Yup.string().required(state.requiredErrorMsg),
  [country.name]: Yup.string().required(country.requiredErrorMsg),
  [pinCode.name]: Yup.string().matches(PINCODE_REGEX, pinCode.invalidErrorMsg).required(pinCode.requiredErrorMsg),
  [latitude.name]: Yup.string().matches(LATITUDE_REGEX, latitude.invalidErrorMsg),
  [longitude.name]: Yup.string().matches(LONGITUDE_REGEX, longitude.invalidErrorMsg),
});
