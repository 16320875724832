import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, FormikProvider, useFormik
} from 'formik';
import {
  Grid,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@mui/material';
import theme from 'src/theme';
import Loader from 'src/common/Loader';
import { useDispatch } from 'react-redux';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import InputField from 'src/common/formFields/InputField';
import FMSModal from 'src/common/FMSModal';
import { reviewVehicleBreakDown } from 'src/redux/vehicleBreakDownSlice';
import {
  breakDownReviewFormModel,
  breakDownReviewValidationSchema
} from 'src/components/breakdown/breakdownReviewFormModel';

const BreakdownApprovalForm = ({ open, handleClose, initialValues }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    formId,
    formField: {
      vehicleNumber,
      breakDownType,
      description,
      repairCost,
      remarks
    }
  } = breakDownReviewFormModel;

  const onSubmit = async (values, actions) => {
    const payload = { ...values, request_id: values.id, expense: Number(values.expense) };
    setLoading(true);
    try {
      await dispatch(reviewVehicleBreakDown(payload)).unwrap();
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Breakdown review successful!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const formikProps = useFormik({
    initialValues,
    validationSchema: breakDownReviewValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleFormSubmit = (jsEvent) => {
    // This is a bit of a hack to copy the name and value of
    // the submitted action name into the Formik form
    const { name } = jsEvent.nativeEvent.submitter;
    formikProps.setFieldValue('status', name);
    return formikProps.handleSubmit(jsEvent);
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          fontSize: 18,
          background: theme.palette.grey[800],
          color: theme.palette.common.white
        }}
      >
        Review Vehicle Breakdown
      </DialogTitle>
      <Divider />
      <FormikProvider value={formikProps}>
        {loading ? (
          <Loader />
        ) : (
          <Form id={formId} onSubmit={handleFormSubmit}>
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={vehicleNumber.name}
                    label={vehicleNumber.label}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={breakDownType.name}
                    label={breakDownType.label}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={description.name}
                    label={description.label}
                    disabled
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={repairCost.name}
                    label={repairCost.label}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputField
                    name={remarks.name}
                    label={remarks.label}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 2 }}>
              <Button variant="outlined" size="large" onClick={handleClose}>
                Close
              </Button>
              <Button
                name="rejected"
                color="secondary"
                disabled={formikProps.isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Reject
              </Button>
              <Button
                name="completed"
                color="primary"
                disabled={formikProps.isSubmitting}
                type="submit"
                size="large"
                variant="contained"
              >
                Complete
              </Button>
            </DialogActions>
          </Form>
        )}
      </FormikProvider>
    </FMSModal>
  );
};

BreakdownApprovalForm.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
};

export default BreakdownApprovalForm;
