/* eslint-disable prefer-destructuring */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getCardApprovalsList, loadMoreApprovals } from 'src/redux/cardApprovalSlice';
import Loader from 'src/common/Loader';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { cardApprovalTableHeaders } from 'src/utils/tableHeaders';
import { TABLE_ROWS_PER_PAGE, USER_ROLES } from 'src/utils/constants';
import {
  camelCaseString,
  getCardLimitRequestQueryParams, getFormattedDate, getFuelQuantity, isAdminUser
} from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';
import { getCardDetails } from 'src/redux/cardSlice';
import useDidUpdate from 'src/hooks/useDidUpdate';
import ApprovalActionForm from './ApprovalActionForm';
import CancelApprovalForm from './CancelApprovalForm';
import CardSyncStatusModal from '../CardSyncStatusModal';

const CardApprovalsTable = ({
  refreshData, searchText, dateFilter, ...rest
}) => {
  const dispatch = useDispatch();
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isApprover = user?.role === USER_ROLES.approver;
  const isRequestor = user?.role === USER_ROLES.requestor;
  const { cardApprovalsList, totalCount, loading } = useSelector((state) => state.cardApprovals);
  const cardDetailsSelector = useSelector((state) => state.cards);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showCancelRequestModal, setShowCancelRequestModal] = useState(false);
  const [cardData, setCardData] = useState(null);
  const [showCardSyncModal, setShowCardSyncModal] = useState(false);

  useEffect(() => {
    if (cardApprovalsList.length === 0 || refreshData) {
      dispatch(getCardApprovalsList({ limit }));
    }
  }, []);

  useDidUpdate(() => {
    dispatch(getCardApprovalsList({
      limit,
      pageNo: 1,
      params: getCardLimitRequestQueryParams(searchText, dateFilter)
    }));
  }, [searchText, dateFilter]);

  const loadMoreApprovalsList = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > cardApprovalsList.length) {
      dispatch(loadMoreApprovals({
        limit: newLimit,
        pageNo: newPage,
        params: getCardLimitRequestQueryParams(searchText, dateFilter)
      }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getCardApprovalsList({
      limit: newLimit,
      params: getCardLimitRequestQueryParams(searchText, dateFilter)
    }));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    loadMoreApprovalsList(newPage, limit);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const showSyncStatus = (cardId) => {
    setShowCardSyncModal(true);
    dispatch(getCardDetails(cardId)).unwrap();
  };

  const onCardSyncModalClose = () => setShowCardSyncModal(false);

  const getStatusColor = (card) => {
    let statusColor = 'info';
    if (card.status === 'approved' || card.status === 'completed') {
      statusColor = 'success';
    } else if (card.status === 'rejected') {
      statusColor = 'error';
    } else if (card.status === 'cancelled') {
      statusColor = 'secondary';
    } else if (card.status === 'expired') {
      statusColor = 'warning';
    }
    return statusColor;
  };

  const handleModalShow = (card, isApproval) => {
    setCardData(card);
    if (isApproval) {
      setShowApprovalModal(true);
    } else {
      setShowCancelRequestModal(true);
    }
  };

  const handleModalClose = (isApproval = true) => {
    if (isApproval) {
      setShowApprovalModal(false);
    } else {
      setShowCancelRequestModal(false);
    }
    setCardData(null);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <ApprovalActionForm open={showApprovalModal} handleClose={handleModalClose} data={cardData} />
          <CancelApprovalForm open={showCancelRequestModal} handleClose={() => handleModalClose(false)} data={cardData} />
          <CardSyncStatusModal
            open={showCardSyncModal}
            onClose={onCardSyncModalClose}
            loading={cardDetailsSelector.loading}
            data={cardDetailsSelector.cardDetails}
          />
          <Card {...rest}>
            <PerfectScrollbar>
              <Box sx={{ minWidth: 1050 }}>
                <Table>
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    headCells={cardApprovalTableHeaders}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {cardApprovalsList
                      .slice()
                      .sort(getComparator(order, orderBy))
                      .map((card) => (
                        <TableRow hover key={card.id}>
                          <TableCell>{card.id}</TableCell>
                          <TableCell>
                            {isAdmin
                              ? (
                                <Link component="button" variant="body2" onClick={() => showSyncStatus(card.card)}>
                                  {card.card_uid}
                                </Link>
                              ) : card.card_uid }
                          </TableCell>
                          <TableCell>
                            {card.vehicle_registration_number}
                          </TableCell>
                          {/* <TableCell>{getFuelQuantity(card.tank_capacity)}</TableCell> */}
                          <TableCell>{getFuelQuantity(card.requested_quantity)}</TableCell>
                          <TableCell>{getFormattedDate(card.requested_on)}</TableCell>
                          <TableCell>{getFuelQuantity(card.approved_quantity)}</TableCell>
                          <TableCell>{getFormattedDate(card.approved_or_rejected_on)}</TableCell>
                          <TableCell>{getFuelQuantity(card.filled_quantity)}</TableCell>
                          <TableCell>{getFormattedDate(card.filled_on)}</TableCell>
                          <TableCell>{card.remarks || 'NA'}</TableCell>
                          <TableCell>
                            <Chip
                              label={camelCaseString(card.status)}
                              color={getStatusColor(card)}
                              variant="outlined"
                            />
                          </TableCell>
                          <TableCell>
                            {(isAdmin || isApprover) && (
                            <Button
                              variant="contained"
                              disabled={card.status !== 'pending'}
                              onClick={() => handleModalShow(card, true)}
                              size="small"
                            >
                              Review
                            </Button>
                            )}
                            {isRequestor && (
                            <Button
                              variant="contained"
                              disabled={card.status !== 'pending'}
                              onClick={() => handleModalShow(card)}
                              size="small"
                            >
                              Cancel
                            </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
            <TablePagination
              component="div"
              showFirstButton
              showLastButton
              count={totalCount}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
              page={page}
              rowsPerPage={limit}
              rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          </Card>
        </>
      )}
    </>
  );
};

CardApprovalsTable.propTypes = {
  refreshData: PropTypes.bool,
  searchText: PropTypes.string,
  dateFilter: PropTypes.string,
};

export default CardApprovalsTable;
