import * as Yup from 'yup';
import { NUMBER_TYPE_ERROR_MSG } from 'src/utils/constants';
import { getMaxLimitErrorMsg } from 'src/utils/getInitials';

// card approvals form model
export const cardApprovalsFormModel = {
  formId: 'cardApprovalsConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_number',
      label: 'Select Vehicle',
      requiredErrorMsg: 'Vehicle seletion is required',
      type: 'select'
    },
    cardName: {
      name: 'card_id',
      label: 'Select Card',
      requiredErrorMsg: 'Card seletion is required',
      type: 'select'
    },
    tankCapacity: {
      name: 'tank_capacity',
      label: 'Vehicle Tank Capacity',
      requiredErrorMsg: 'Vehicle Tank Capacity is required',
      type: 'text'
    },
    tankFuelPercentage: {
      name: 'tank_fuel_percentage',
      label: 'Fuel left in Tank',
      requiredErrorMsg: 'Fuel left percentage is required',
      type: 'text'
    },
    requestedQuantity: {
      name: 'requested_quantity',
      label: 'Request Limit',
      requiredErrorMsg: 'Request Limit is required',
      invalidErrorMsg: 'Invalid Request Limit',
      type: 'text'
    }
  }
};

// Initial values
const {
  formField: {
    vehicleNumber, cardName, tankCapacity, tankFuelPercentage, requestedQuantity
  }
} = cardApprovalsFormModel;

export const cardApprovalFormInitialValues = {
  [vehicleNumber.name]: '',
  [cardName.name]: '',
  [tankCapacity.name]: '',
  [tankFuelPercentage.name]: '',
  [requestedQuantity.name]: '',
};

// validation schema
export const cardApprovalsValidationSchema = Yup.object().shape({
  [vehicleNumber.name]: Yup.string().required(vehicleNumber.requiredErrorMsg),
  [cardName.name]: Yup.string().required(cardName.requiredErrorMsg),
  [tankFuelPercentage.name]: Yup.number().required(tankFuelPercentage.requiredErrorMsg)
    .max(100, getMaxLimitErrorMsg(tankFuelPercentage, '100%')).typeError(NUMBER_TYPE_ERROR_MSG),
  [requestedQuantity.name]: Yup.number().required(requestedQuantity.requiredErrorMsg)
    .typeError(NUMBER_TYPE_ERROR_MSG)
    .when(tankFuelPercentage.name, (tankLevel, schema, config) => {
      if (config.parent?.requested_quantity && config.parent?.tank_capacity && tankLevel <= 100) {
        const getOnePercentValue = config.parent.tank_capacity / 100;
        const maxLimitAllowed = (getOnePercentValue * (100 - tankLevel)).toFixed(2);
        if (config.parent.requested_quantity > maxLimitAllowed) {
          return schema.max(maxLimitAllowed, getMaxLimitErrorMsg(requestedQuantity, maxLimitAllowed));
        }
      }
      return schema;
    }),
});
