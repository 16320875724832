import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { LogoutModal } from 'src/components/DashboardNavbar';
import useAuth from 'src/context/AuthContext';
import ROUTE from 'src/utils/routeConstants';

const AccountPopover = (props) => {
  const { anchorEl, onClose, open } = props;
  const history = useHistory();
  const { getUser } = useAuth();
  const user = getUser();
  const [showAlert, setShowAlert] = useState(false);
  const handleAlertClose = () => setShowAlert(false);
  const handleSignOut = () => setShowAlert(true);

  const gotoAccountPage = () => {
    history.push(ROUTE.profile);
    onClose();
  };

  const gotoSettingsPage = () => {
    history.push(ROUTE.settings);
    onClose();
  };

  return (
    <>
      <LogoutModal open={showAlert} onClose={handleAlertClose} />
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        onClose={onClose}
        open={open}
        slotProps={{ paper: { sx: { width: 200 } } }}
      >
        <Box sx={{ pt: 1.5, pb: 1, px: 2 }}>
          <Typography variant="subtitle2">
            {`${user?.first_name} ${user?.last_name}`}
          </Typography>
          <Typography variant="overline" color="text.secondary">
            {user?.role}
          </Typography>
        </Box>
        <Divider />
        <MenuList
          disablePadding
          dense
          sx={{
            p: '8px',
            '& > *': {
              borderRadius: 1
            }
          }}
        >
          <MenuItem sx={{ py: 1 }} onClick={gotoAccountPage}>
            <ListItemIcon>
              <ManageAccountsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Account</ListItemText>
          </MenuItem>
          <MenuItem sx={{ py: 1 }} onClick={gotoSettingsPage}>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Settings</ListItemText>
          </MenuItem>
          <MenuItem sx={{ py: 1 }} onClick={handleSignOut}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Sign out</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default AccountPopover;
