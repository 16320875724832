import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allDispensers: [],
  totalCount: 0,
  dispenserList: [],
  dispAddressList: [],
  dispenserListBySite: [],
  dispenserDetails: {},
  loading: false,
};

export const getDispenserList = createAsyncThunk(
  'dispensers/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getDispenserList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreDispensers = createAsyncThunk(
  'dispensers/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo } = req;
    try {
      const res = await APIService.getDispenserList(limit, pageNo);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllDispensers = createAsyncThunk(
  'dispensers/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllDispensers();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getDispenserDetails = createAsyncThunk(
  'dispensers/details', async (dispenserId, { rejectWithValue }) => {
    try {
      const res = await APIService.getDispenserDetails(dispenserId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateDispenserDetails = createAsyncThunk(
  'dispensers/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateDispenserDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewDispenser = createAsyncThunk(
  'dispensers/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createDispenser(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const clearDispenserCards = createAsyncThunk(
  'dispensers/reset/cards', async (dispenserId, { rejectWithValue },) => {
    try {
      const res = await APIService.resetDispenserCards(dispenserId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatDispenserData = (data) => {
  const formattedList = data.map((dispenser) => ({ ...dispenser, name: dispenser.station_name }));
  return formattedList;
};

// format data for address dropdown list
const getDispenserAddressList = (data) => {
  const formattedList = data.map((dispenser) => ({
    ...dispenser,
    name: dispenser.station_name,
    id: dispenser.address
  }));
  return formattedList;
};

export const dispenserSlice = createSlice({
  name: 'dispensers',
  initialState,
  reducers: {
    filterDispenserBySite: (state, action) => ({
      ...state,
      dispenserListBySite: action.payload === 'all' ? state.dispAddressList
        : state.dispAddressList.filter((dispenser) => dispenser.site === action.payload)
    }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllDispensers.fulfilled, (state, action) => ({
        ...state,
        allDispensers: formatDispenserData(action.payload),
        dispenserListBySite: getDispenserAddressList(action.payload),
        dispAddressList: getDispenserAddressList(action.payload)
      }))
      .addCase(getDispenserList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        dispenserList: formatDispenserData(action.payload.results)
      }))
      .addCase(getDispenserList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getDispenserList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreDispensers.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        dispenserList: [...state.dispenserList, ...formatDispenserData(action.payload.results)]
      }))
      .addCase(loadMoreDispensers.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreDispensers.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getDispenserDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        dispenserDetails: action.payload
      }))
      .addCase(getDispenserDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getDispenserDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateDispenserDetails.fulfilled, (state, action) => ({
        ...state,
        dispenserDetails: action.payload,
        dispenserList: updateItemInArray(state.dispenserList, action.payload)
      }))
      .addCase(clearDispenserCards.fulfilled, (state) => ({
        ...state,
        dispenserDetails: { ...state.dispenserDetails, reset_cards: true },
      }))
      .addCase(addNewDispenser.fulfilled, (state, action) => {
        state.dispenserList.push(action.payload);
      });
  }
});

// Action creators are generated for each case reducer function
export const { filterDispenserBySite } = dispenserSlice.actions;

export default dispenserSlice.reducer;
