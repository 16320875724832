import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import SiteListTable from 'src/components/sites/SiteListTable';
import FMSToolbar from 'src/common/FMSToolbar';
import NewSite from 'src/components/sites/NewSite';
import useAuth from 'src/context/AuthContext';
import { isAdminUser } from 'src/utils/getInitials';
import ScreenHeader from 'src/common/ScreenHeader';

const SiteList = ({ location }) => {
  const { getUser } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (str) => setSearchText(str);

  return (
    <>
      <ScreenHeader title="Sites" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Site Name"
            actionName="Add Site"
            hideAction={!isAdminUser(getUser)}
            onSearch={handleSearch}
            onAction={() => setOpenModal(true)}
          />
          <NewSite open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <SiteListTable refreshData={location.state?.fromDashboard} searchText={searchText} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

SiteList.propTypes = {
  location: PropTypes.object
};

export default SiteList;
