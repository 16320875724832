import React, { useEffect, useState } from 'react';
import {
  Button,
  Stepper,
  Step,
  StepLabel,
  DialogContent,
  Divider,
  DialogActions
} from '@mui/material';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import Loader from 'src/common/Loader';
import { setErrorInfo, setSuccessInfo } from 'src/redux/modalSlice';
import theme from 'src/theme';
import { useDispatch } from 'react-redux';
import { getAllDispensers } from 'src/redux/dispenserSlice';
import { addNewCard, updateCardDetails } from 'src/redux/cardSlice';
import { getAllVehicles } from 'src/redux/vehicleSlice';
import { getAllOperators } from 'src/redux/operatorSlice';
import FMSModal, { FMSModalTitle } from 'src/common/FMSModal';
import { getAllDrivers } from 'src/redux/driverSlice';
import CardDetailsForm from './forms/CardDetailsForm';
import AdditionalConfigForm from './forms/AdditionalConfigForm';
import CardConfigForm from './forms/CardConfigForm';
import validationSchema from './formModel/cardValidation';
import cardFormModel from './formModel/cardsFormModel';
import formInitialValues from './formModel/cardInitialValues';
import { getFormattedCardBody } from './formModel/cardFormUtils';

const { formId, formField } = cardFormModel;

function renderStepContent(step, initialValues) {
  switch (step) {
    case 0:
      return (
        <CardDetailsForm formField={formField} initValues={!!initialValues} />
      );
    case 1:
      return <CardConfigForm formField={formField} />;
    case 2:
      return <AdditionalConfigForm formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}

const NewCard = ({ open, handleClose, initialValues }) => {
  const steps = [
    'Card Details',
    'Card Configuration',
    'Additional Configuration'
  ];
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const dispatch = useDispatch();
  const isLastStep = activeStep === steps.length - 1;
  const formInitValues = initialValues || formInitialValues;

  useEffect(() => {
    if (open) {
      dispatch(getAllVehicles());
      dispatch(getAllDispensers());
      dispatch(getAllOperators());
      dispatch(getAllDrivers());
    }
    setActiveStep(0); // reset form
  }, [open]);

  const handleBack = () => {
    if (activeStep === 0) {
      handleClose();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const submitForm = async (values, actions) => {
    const cardReqBody = getFormattedCardBody(values);
    setLoading(true);
    try {
      if (initialValues) {
        await dispatch(updateCardDetails(cardReqBody)).unwrap();
      } else {
        await dispatch(addNewCard(cardReqBody)).unwrap();
      }
      handleClose();
      setLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: initialValues
            ? 'Card details updated successfully!'
            : 'New Card has been created successfully!'
        })
      );
    } catch (error) {
      console.log('card error', error);
      actions.setSubmitting(false);
      setLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const getActionButton = () => {
    let actionBtn = 'Next';
    if (isLastStep) {
      if (initialValues) {
        actionBtn = 'Update Card';
      } else {
        actionBtn = 'Create Card';
      }
    }
    return actionBtn;
  };

  return (
    <FMSModal open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <FMSModalTitle
        title={initialValues ? 'Update Card details' : 'Add new Card'}
        onCancel={handleClose}
      />
      <Divider />
      <DialogContent>
        {!loading && (
          <Stepper
            activeStep={activeStep}
            sx={{
              padding: theme.spacing(2, 0, 4),
              backgroundColor: 'rgba(256, 256, 256, 0.3)',
              borderRadius: 5
            }}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        <Formik
          initialValues={formInitValues}
          validationSchema={currentValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <>
              {loading ? (
                <Loader />
              ) : (
                <Form
                  id={formId}
                  sx={{
                    padding: theme.spacing(3),
                    background: 'transparent',
                    transition: 'all 0.5s ease'
                  }}
                >
                  {renderStepContent(activeStep, initialValues)}

                  <DialogActions sx={{ mt: 2 }}>
                    <Button variant="outlined" size="large" onClick={handleBack}>
                      {activeStep === 0 ? 'Close' : 'Back'}
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      type="submit"
                      size="large"
                      variant="contained"
                    >
                      {getActionButton()}
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </>
          )}
        </Formik>
      </DialogContent>
    </FMSModal>
  );
};

NewCard.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  initialValues: PropTypes.object
};

export default NewCard;
