import React from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react';

const ReactECharts = ({
  options, theme = 'light', ...chartProps
}) => {
  const chartOptions = {
    color: [
      '#536dfe',
      '#8bc34a',
      '#ffca28',
      '#ff7043',
      '#4dd0e1',
    ],
    ...options,
  };
  return (
    <ReactEChartsCore
      style={{ height: '100%' }}
      option={chartOptions}
      theme={theme}
      {...chartProps}
    />
  );
};

ReactECharts.propTypes = {
  theme: PropTypes.string,
  options: PropTypes.object.isRequired,
};

export default ReactECharts;
