import { useState, memo, useEffect } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';

const OtherReportsTable = ({ ...rest }) => {
  const { otherReportList } = useSelector((state) => state.reports);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [tableHeaders, setTableHeaders] = useState([]);

  useEffect(() => {
    if (otherReportList.length > 0) {
      const headerKeys = Object.keys(otherReportList[0]);
      const formattedTableHeaders = headerKeys.map((key) => ({ id: key, name: key.replaceAll('_', ' ') }));
      setTableHeaders(formattedTableHeaders);
      setOrderBy(headerKeys[0]);
    }
  }, [otherReportList]);

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  return (
    <Card {...rest}>
      <Box sx={{ px: 3, py: 2 }}>
        <Typography variant="h5" sx={{ pr: 0.5 }}>Report Data Table</Typography>
      </Box>
      <Divider />
      {otherReportList?.length > 0 && (
        <PerfectScrollbar>
          <Box sx={{ minWidth: 1050 }}>
            <Table>
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                headCells={tableHeaders}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {otherReportList
                  .slice()
                  .sort(getComparator(order, orderBy))
                  .slice(page * limit, page * limit + limit)
                  .map((report, i) => (
                    <TableRow key={`other-reports-${i.toString()}`}>
                      {tableHeaders.map((header) => <TableCell key={header.id}>{report[header.id]}</TableCell>)}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      )}
      <TablePagination
        component="div"
        count={otherReportList.length}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
      />
    </Card>
  );
};

export default memo(OtherReportsTable);
