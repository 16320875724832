import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';
import { useHistory } from 'react-router';
import { getStateName } from 'src/utils/getInitials';
import { useDispatch, useSelector } from 'react-redux';
import { getSiteList, loadMoreSiteList } from 'src/redux/siteSlice';
import Loader from 'src/common/Loader';
import ROUTE from 'src/utils/routeConstants';
import EnhancedTableHead, { getComparator } from 'src/common/EnhancedTableHead';
import { siteTableHeaders } from 'src/utils/tableHeaders';
import { TABLE_ROWS_PER_PAGE } from 'src/utils/constants';

const SiteListTable = ({ refreshData, searchText, ...rest }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const { loading, siteList, totalCount } = useSelector((state) => state.sites);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('name');

  useEffect(() => {
    if (siteList.length === 0 || refreshData) {
      dispatch(getSiteList({ limit }));
    }
  }, []);

  useEffect(() => {
    if (mounted.current) {
      dispatch(getSiteList({ limit, pageNo: 1, params: { name: searchText } }));
    }
    mounted.current = true;
  }, [searchText]);

  const loadMoreSites = (currentPage, newLimit) => {
    const newPage = currentPage + 1;
    if (totalCount > siteList.length) {
      dispatch(loadMoreSiteList({ limit: newLimit, pageNo: newPage }));
    }
  };

  const handleLimitChange = (event) => {
    const newLimit = event.target.value;
    setPage(0);
    setLimit(newLimit);
    dispatch(getSiteList(newLimit));
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    loadMoreSites(newPage, limit);
  };

  const viewSiteInfo = (siteId) => {
    history.push(`${ROUTE.sites}/${siteId}`);
  };

  const handleRequestSort = (e, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Card {...rest}>
          <PerfectScrollbar>
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  headCells={siteTableHeaders}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {siteList
                    .slice()
                    .sort(getComparator(order, orderBy))
                    .slice(page * limit, page * limit + limit)
                    .map((site) => (
                      <TableRow hover key={site.id}>
                        <TableCell>
                          <Box
                            sx={{
                              alignItems: 'center',
                              display: 'flex'
                            }}
                          >
                            <Typography color="textPrimary" variant="body1">
                              {site.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell>{site.code}</TableCell>
                        <TableCell>
                          {`${site.city}, ${getStateName(site.state)}`}
                        </TableCell>
                        <TableCell>{site.dispensers_count || 0}</TableCell>
                        <TableCell>{site.tanks_count || 0}</TableCell>
                        <TableCell>{site.vehicle_count || 0}</TableCell>
                        <TableCell>
                          <Chip
                            label={site.active ? 'Active' : 'Inactive'}
                            color={site.active ? 'success' : 'error'}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            onClick={() => viewSiteInfo(site.id)}
                            size="small"
                          >
                            View
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={totalCount}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />
        </Card>
      )}
    </>
  );
};

SiteListTable.propTypes = {
  refreshData: PropTypes.bool,
  searchText: PropTypes.string
};

export default SiteListTable;
