import * as Yup from 'yup';

// report form model
export const sensorTransactionReportFormModel = {
  formId: 'sensorTransactionReportConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_reg_num',
      label: 'Select Vehicle',
      type: 'select',
      data: [{ id: 'all', name: 'All', registration_num: 'All' }]
    },
    startTime: {
      name: 'filled_time__gte',
      label: 'Start Date',
      type: 'date',
      errorMsg: 'Start time is required!'
    },
    endTime: {
      name: 'filled_time__lte',
      label: 'End Date',
      type: 'date',
      errorMsg: 'End time is required!'
    }
  }
};

// Initial values
const {
  formField: {
    vehicleNumber,
    startTime,
    endTime
  }
} = sensorTransactionReportFormModel;

export const sensorTransactionReportFormInitialValues = {
  [vehicleNumber.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const sensorTransactionReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});

export const sensorTransactionReportFormConfig = {
  formModel: sensorTransactionReportFormModel,
  initialValues: sensorTransactionReportFormInitialValues,
  validation: sensorTransactionReportValidationSchema,
};
