import PropTypes from 'prop-types';
import { Box, Button, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TransactionListTable from 'src/components/transactions/TransactionListTable';
import { setTransactionType } from 'src/redux/transactionSlice';
import NewTransaction from 'src/components/transactions/NewTransaction';
import { DATE_FORMAT, TRANSACTION_TYPES, USER_ROLES } from 'src/utils/constants';
import ReportFilterModal from 'src/components/reports/ReportFilterModal';
import {
  reportFormModel,
  reportFormInitialValues,
  reportValidationSchema
} from 'src/components/reports/reportFormModel';
import DateFilterField from 'src/common/formFields/DateFilterField';
import {
  inwardReportFormModel,
  inwardReportFormInitialValues,
  inwardReportValidationSchema
} from 'src/components/reports/inwardReportFormModel';
import { isAdminUser } from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';
import {
  getFilterOptions, getReportDownloadURL, getReportFormTitle, getSearchPlaceholder
} from 'src/components/transactions/transactionService';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import ScreenHeader from 'src/common/ScreenHeader';

const Transactions = ({ location }) => {
  const { transactionType } = useSelector((state) => state.transactions);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [filterDate, setFilterDate] = useState(null);
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isApprover = user?.role === USER_ROLES.approver;

  const isSales = transactionType !== TRANSACTION_TYPES[1].id;
  const reportDownloadUrl = getReportDownloadURL(transactionType);
  const filterOptions = getFilterOptions(transactionType);
  const searchPlaceholder = getSearchPlaceholder(transactionType);

  const handleSearch = (str) => setSearchText(str);

  const onReportModalClose = () => setOpenReportModal(false);

  const handleGroupChange = (value) => {
    setFilterDate(null);
    dispatch(setTransactionType(value));
  };

  const downloadReport = () => setOpenReportModal(true);

  const addNewTransaction = () => setOpenModal(true);

  const filterByDate = (newValue) => {
    const dateString = newValue ? newValue.format(DATE_FORMAT.unixDate) : undefined;
    setFilterDate(dateString);
  };

  const clearDateFilter = () => setFilterDate(null);

  return (
    <>
      <ScreenHeader title="Transactions" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            showGroup
            placeholder={searchPlaceholder}
            actionName="Export"
            actionIcon={<DownloadIcon />}
            groupData={TRANSACTION_TYPES}
            groupType={transactionType}
            groupLabel="Transaction Category"
            onGroupChange={handleGroupChange}
            onSearch={handleSearch}
            onAction={downloadReport}
          >
            <DateFilterField
              value={filterDate}
              onDateChange={filterByDate}
              onClearFilter={clearDateFilter}
              sx={{ width: '18%' }}
            />
            {!isSales && (isAdmin || isApprover) && (
              <Button
                sx={{
                  ml: 'auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  mr: 1
                }}
                color="primary"
                size="large"
                variant="contained"
                onClick={addNewTransaction}
              >
                Add Transaction
              </Button>
            )}
          </FMSToolbar>
          <NewTransaction
            open={openModal}
            handleClose={() => setOpenModal(false)}
          />
          <ReportFilterModal
            open={openReportModal}
            title={getReportFormTitle(transactionType)}
            formModel={isSales ? reportFormModel : inwardReportFormModel}
            formValidation={isSales ? reportValidationSchema : inwardReportValidationSchema}
            formInitValues={
              isSales ? reportFormInitialValues : inwardReportFormInitialValues
            }
            filterConfig={filterOptions}
            downloadUrl={reportDownloadUrl}
            handleClose={onReportModalClose}
          />
          <Box sx={{ pt: 3 }}>
            <TransactionListTable
              refreshData={location.state?.fromDashboard}
              searchText={searchText}
              dateFilter={filterDate}
              isSales={isSales}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

Transactions.propTypes = {
  location: PropTypes.object
};

export default Transactions;
