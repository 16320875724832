import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import SelectField from 'src/common/formFields/SelectField';
import { useDispatch, useSelector } from 'react-redux';
import DatePickerField from 'src/common/formFields/DatePickerField';
import { useFormikContext } from 'formik';
import { filterTanksBySite } from 'src/redux/tankSlice';
import moment from 'moment';

export default function TransactionDetailsForm(props) {
  const {
    formField: {
      siteId,
      // sourceTank,
      destTank,
      orderDate,
      deliveryDate,
      quantity,
      pricePerLitre,
      supplier,
      invoiceNumber,
      remarks,
      deliveryVehicleNumber
    },
    initialValues
  } = props;
  const dispatch = useDispatch();
  const mounted = useRef(false);
  const formikProps = useFormikContext();
  const { allSites } = useSelector((state) => state.sites);
  const { tankListBySite } = useSelector((state) => state.tanks);
  const todayDate = moment();
  const startDate = moment().subtract(1, 'month');
  // filter tank list based on the selected site id
  useEffect(() => {
    if (mounted.current && formikProps.values.site) {
      formikProps.setFieldValue(destTank.name, ''); // reset selected tank
      dispatch(filterTanksBySite(formikProps.values.site));
    }
    mounted.current = true;
  }, [formikProps.values.site]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={siteId.name}
          label={siteId.label}
          data={allSites}
          disabled={!!initialValues}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={supplier.name}
          label={supplier.label}
          data={supplier.data}
          fullWidth
        />
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <SelectField
          name={sourceTank.name}
          label={sourceTank.label}
          data={tankListBySite}
          disabled={!formikProps.values.site}
          fullWidth
        />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <SelectField
          name={destTank.name}
          label={destTank.label}
          data={tankListBySite}
          disabled={!formikProps.values.site || !!initialValues}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={quantity.name}
          label={quantity.label}
          disabled={!!initialValues}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={pricePerLitre.name} label={pricePerLitre.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={invoiceNumber.name} label={invoiceNumber.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={orderDate.name}
          minDate={startDate}
          maxDate={todayDate}
          label={orderDate.label}
          disabled={!!initialValues}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DatePickerField
          name={deliveryDate.name}
          minDate={startDate}
          maxDate={todayDate}
          label={deliveryDate.label}
          disabled={!!initialValues}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={remarks.name} label={remarks.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={deliveryVehicleNumber.name} label={deliveryVehicleNumber.label} fullWidth />
      </Grid>
    </Grid>
  );
}

TransactionDetailsForm.propTypes = {
  formField: PropTypes.object.isRequired,
  initialValues: PropTypes.object
};
