import * as Yup from 'yup';
import { NUMBER_TYPE_ERROR_MSG } from 'src/utils/constants';
import { getMaxLimitErrorMsg } from 'src/utils/getInitials';

// card approvals form model
export const cardApprovalActionFormModel = {
  formId: 'cardApprovalActionConfig',
  formField: {
    vehicleNumber: {
      name: 'vehicle_registration_number',
      label: 'Vehicle Number',
      type: 'text'
    },
    cardName: {
      name: 'card_uid',
      label: 'Card UID',
      type: 'text'
    },
    tankCapacity: {
      name: 'tank_capacity',
      label: 'Tank Capacity',
      requiredErrorMsg: 'Tank Capacity is required',
      type: 'text'
    },
    requestedQuantity: {
      name: 'requested_quantity',
      label: 'Requested Quantity',
      requiredErrorMsg: 'Requested Quantity is required',
      type: 'text'
    },
    approvedQuantity: {
      name: 'approved_quantity',
      label: 'Approved Quantity',
      requiredErrorMsg: 'Approved Quantity is required',
      type: 'text'
    },
    requestDate: {
      name: 'requested_on',
      label: 'Requested Date',
      type: 'text'
    },
    remarks: {
      name: 'remarks',
      label: 'Remarks',
      requiredErrorMsg: 'New Limit is required',
      type: 'text'
    }
  }
};

// Initial values
const {
  formField: {
    vehicleNumber, cardName, requestedQuantity, tankCapacity,
    approvedQuantity, requestDate, remarks
  }
} = cardApprovalActionFormModel;

export const cardApprovalActionFormInitialValues = {
  [vehicleNumber.name]: '',
  [cardName.name]: '',
  [requestedQuantity.name]: '',
  [approvedQuantity.name]: '',
  [tankCapacity.name]: '',
  [requestDate.name]: [],
  [remarks.name]: '',
};

// validation schema
export const cardApprovalActionValidationSchema = Yup.object().shape({
  [approvedQuantity.name]: Yup.number().required(approvedQuantity.requiredErrorMsg)
    .typeError(NUMBER_TYPE_ERROR_MSG)
    .when(requestedQuantity.name, (requestedQty, schema, config) => {
      if (requestedQty && config.parent?.approved_quantity) {
        if (config.parent?.approved_quantity > requestedQty) {
          return schema.max(requestedQty, getMaxLimitErrorMsg(approvedQuantity, requestedQty));
        }
      }
      return schema;
    }),
});
