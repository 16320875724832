import axios from 'axios';
import { resetSession } from 'src/redux/modalSlice';
import { USER_ROLES } from 'src/utils/constants';
import API_URL from './apiConstants';

let appStore;

export const injectStore = (_store) => {
  appStore = _store;
};

export const BASEAPI = axios.create({
  baseURL: API_URL.baseUrl,
  timeout: 20000,
});

export const setAPIAuthHeader = (token) => {
  // Add auth token for all the upcoming API calls
  BASEAPI.defaults.headers.common.Authorization = `Token ${token}`;
};

BASEAPI.interceptors.response.use((response) => response,
  (error) => {
    const errorObj = error.response || error.request || error;
    console.log('global error', errorObj);
    const isFromLoginApi = errorObj?.config?.url === API_URL.login || errorObj?.config?.url.includes('sign-in');
    if (error.response.status === 401 && !isFromLoginApi) {
      appStore.dispatch(resetSession({ isSessionTimedOut: true }));
    }
    return Promise.reject(error);
  });

export const getAPIError = (errorObj) => {
  if (errorObj.response) return errorObj.response;
  if (errorObj.request) return errorObj.request;
  return errorObj;
};

const fetchData = (method = 'GET', url, data = null) => BASEAPI({
  method,
  url,
  data,
}).then((response) => response.data)
  .catch((error) => getAPIError(error));

export default fetchData;

const getLoginUrl = (role) => {
  let loginUrl = API_URL.login;
  if (role === USER_ROLES.approver) {
    loginUrl = API_URL.approverLogin;
  } else if (role === USER_ROLES.siteManager) {
    loginUrl = API_URL.siteManagerLogin;
  }
  return loginUrl;
};

// Generate API url with params
const generateAPIUrlWithParams = (baseUrl, limit = 10, pageNo = 1, queryParams = {}) => {
  let URL = `${baseUrl}?page=${pageNo}&page_size=${limit}`;
  const params = Object.keys(queryParams);
  params.forEach((key) => {
    if (queryParams[key]) {
      URL += `&${key}=${queryParams[key]}`;
    }
  });
  return URL;
};

// auth
const userLogin = (data) => fetchData('POST', getLoginUrl(data?.role), data);
const userLogout = (data) => fetchData('POST', API_URL.logout, data);
const resetPassword = (data) => fetchData('POST', API_URL.resetPassword, data);
// sites
const createSite = (data) => fetchData('POST', API_URL.createSite, data);
const getSiteList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.siteList, limit, pageNo, params));
const getSiteDetails = (siteId) => fetchData('GET', API_URL.siteDetails.replace('{siteId}', siteId));
const updateSiteDetails = (siteId, data) => fetchData('PUT', API_URL.updateSite.replace('{siteId}', siteId), data);
// requestors
const createRequestor = (data) => fetchData('POST', API_URL.createRequestor, data);
const getRequestorList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.requestorList, limit, pageNo, params));
const getRequestorDetails = (userId) => fetchData('GET', API_URL.requestorDetails.replace('{userId}', userId));
const updateRequestorDetails = (userId, data) => fetchData('PUT', API_URL.updateRequestor.replace('{userId}', userId), data);
// vehicles
const createVehicle = (data) => fetchData('POST', API_URL.createVehicle, data);
const getVehicleList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.vehicleList, limit, pageNo, params));
const getVehicleDetails = (vehicleId) => fetchData('GET', API_URL.vehicleDetails.replace('{vehicleId}', vehicleId));
const updateVehicleDetails = (vehicleId, data) => fetchData('PUT', API_URL.updateVehicle.replace('{vehicleId}', vehicleId), data);
// groups
const createGroup = (groupName, data) => fetchData('POST', API_URL.createGroup.replace('{groupName}', groupName), data);
const getGroupList = (groupName, params, limit = 100, pageNo) => {
  const URL = API_URL.groupList.replace('{groupName}', groupName);
  return fetchData('GET', generateAPIUrlWithParams(URL, limit, pageNo, params));
};
const updateGroupDetails = (groupName, groupId, data) => fetchData('PUT', API_URL.getGroupUrl(groupName, groupId), data);
const deleteGroup = (groupName, groupId) => fetchData('DELETE', API_URL.getGroupUrl(groupName, groupId));
// operators
const createOperator = (data) => fetchData('POST', API_URL.createOperator, data);
const getOperatorList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.operatorList, limit, pageNo, params));
const getOperatorDetails = (userId) => fetchData('GET', API_URL.operatorDetails.replace('{userId}', userId));
const updateOperatorDetails = (userId, data) => fetchData('PUT', API_URL.updateOperator.replace('{userId}', userId), data);
// tanks
const createTank = (data) => fetchData('POST', API_URL.createTank, data);
const getTankList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.tankList, limit, pageNo, params));
const getTankDetails = (tankId) => fetchData('GET', API_URL.tankDetails.replace('{tankId}', tankId));
const updateTankDetails = (tankId, data) => fetchData('PUT', API_URL.updateTank.replace('{tankId}', tankId), data);
// dispensers
const createDispenser = (data) => fetchData('POST', API_URL.createDispenser, data);
const getDispenserList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.dispenserList, limit, pageNo, params));
const getDispenserDetails = (dispenserId) => fetchData('GET', API_URL.dispenserDetails.replace('{dispenserId}', dispenserId));
const updateDispenserDetails = (dispenserId, data) => fetchData('PUT', API_URL.updateDispenser.replace('{dispenserId}', dispenserId), data);
const resetDispenserCards = (dispenserId) => fetchData('PUT', API_URL.resetDispenserCards.replace('{dispenserId}', dispenserId));
// cards
const createCard = (data) => fetchData('POST', API_URL.createCard, data);
const getCardList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.cardList, limit, pageNo, params));
const getCardDetails = (cardId) => fetchData('GET', API_URL.cardDetails.replace('{cardId}', cardId));
const updateCardDetails = (cardId, data) => fetchData('PUT', API_URL.updateCard.replace('{cardId}', cardId), data);
// vehicle driver
const createDriver = (data) => fetchData('POST', API_URL.createDriver, data);
const getDriverList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.driverList, limit, pageNo, params));
const getDriverDetails = (driverId) => fetchData('GET', API_URL.driverDetails.replace('{driverId}', driverId));
const updateDriverDetails = (driverId, data) => fetchData('PUT', API_URL.updateDriver.replace('{driverId}', driverId), data);
// Approvers
const createApprover = (data) => fetchData('POST', API_URL.createApprover, data);
const getApproverList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.approverList, limit, pageNo, params));
const getApproverDetails = (userId) => fetchData('GET', API_URL.approverDetails.replace('{userId}', userId));
const updateApproverDetails = (userId, data) => fetchData('PUT', API_URL.updateApprover.replace('{userId}', userId), data);
// site manager
const createSiteManager = (data) => fetchData('POST', API_URL.createSiteManager, data);
const getSiteManagerList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.siteManagerList, limit, pageNo, params));
const getSiteManagerDetails = (userId) => fetchData('GET', API_URL.siteManagerDetails.replace('{userId}', userId));
const updateSiteManagerDetails = (userId, data) => fetchData('PUT', API_URL.updateSiteManager.replace('{userId}', userId), data);
// transactions
const createTransaction = (transactionType, data) => fetchData('POST', API_URL.createTransaction.replace('{transactionType}', transactionType), data);
const getTransactionList = (transactionType, limit, pageNo, params) => {
  const URL = API_URL.transactionList.replace('{transactionType}', transactionType);
  return fetchData('GET', generateAPIUrlWithParams(URL, limit, pageNo, params));
};
const getTransactionDetails = (transactionType, transactionId) => fetchData('GET', API_URL.transactionDetails(transactionType, transactionId));
const updateTransactionDetails = (transactionType, transactionId, data) => fetchData('PUT', API_URL.updateTransaction(transactionType, transactionId), data);
// dashboard
const getDashboardCount = () => fetchData('GET', API_URL.dashboardCount);
const getDashboardSales = (siteId = '') => fetchData('GET', API_URL.transactionChart.replace('{siteId}', siteId));
const getTerminalWiseFuelDispensed = (siteId = '') => fetchData('GET', API_URL.terminalWiseDispenseChart.replace('{siteId}', siteId));
const getTopFuelConsumption = (siteId = '') => fetchData('GET', API_URL.topFuelConsumptionChart.replace('{days}', siteId));
// Dropdown List
const getAllVehicles = () => fetchData('GET', API_URL.allVehicles);
const getAllOperators = () => fetchData('GET', API_URL.allOperators);
const getAllDrivers = () => fetchData('GET', API_URL.allDrivers);
const getAllSites = () => fetchData('GET', API_URL.allSites);
const getAllTanks = () => fetchData('GET', API_URL.allTanks);
const getAllCards = () => fetchData('GET', API_URL.allCards);
const getAllRequestors = () => fetchData('GET', API_URL.allRequestors);
const getAllApprovers = () => fetchData('GET', API_URL.allApprovers);
const getAllSiteManagers = () => fetchData('GET', API_URL.allSiteManagers);
const getAllDispensers = () => fetchData('GET', API_URL.allDispensers);
// reports
const getMilageData = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.milageReport, limit, pageNo, params));
const getReconciliationData = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.reconciliationReport, limit, pageNo, params));
// card approval
const changeCardLimit = (data) => fetchData('POST', API_URL.changeCardLimit, data);
const getCardApprovalsList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.cardLimitHistory, limit, pageNo, params));
const approveRejectCardLimit = (data) => fetchData('POST', API_URL.approveCardLimit, data);
const cardLimitApprovalsCount = () => fetchData('GET', API_URL.cardLimitRequestCount);
const cancelCardLimitRequest = (requestId, payload) => fetchData('PATCH', API_URL.cancelCardLimitRequest.replace('{requestId}', requestId), payload);
const getAllVehicleGroups = () => fetchData('GET', API_URL.getAllVehicleGroup);
const getAllDepartmentGroups = () => fetchData('GET', API_URL.getAllDepartmentGroup);
// Wailon API's
const getVehicleFuelLevel = (vehicleId) => fetchData('GET', API_URL.getVehicleFuelLevel.replace('{vehicleID}', vehicleId));
const getWailonTransactions = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.getWailonTransactions, limit, pageNo, params));
const getVehicleSummary = (vehicleId) => fetchData('GET', API_URL.vehicleSummaryReport.replace('{vehicleID}', vehicleId));
const getVehicleUtilization = () => fetchData('GET', API_URL.vehicleUtilizationChart);
const getWailonVehicleList = () => fetchData('GET', API_URL.wailonVehicleList);
const getVehicleUtilizationReport = () => fetchData('GET', API_URL.vehicleUtilizationReport);
// vehicle breakdown
const getVehicleBreakDownList = (limit, pageNo, params) => fetchData('GET', generateAPIUrlWithParams(API_URL.breakDownList, limit, pageNo, params));
const createVehicleBreakDown = (data) => fetchData('POST', API_URL.createBreakDown, data);
const approveVehicleBreakDown = (data) => fetchData('POST', API_URL.approveBreakDown, data);
const getVehicleStatus = () => fetchData('GET', API_URL.vehicleStatus);
const createShift = (data) => fetchData('POST', API_URL.shift, data);
const updateShift = (shiftID, data) => fetchData('PUT', API_URL.updateShift.replace('{shiftID}', shiftID), data);
const getShiftDetails = () => fetchData('GET', API_URL.shift);

export const APIService = {
  userLogin,
  userLogout,
  resetPassword,
  createSite,
  getSiteList,
  getSiteDetails,
  updateSiteDetails,
  createRequestor,
  getRequestorList,
  getRequestorDetails,
  updateRequestorDetails,
  createVehicle,
  getVehicleList,
  getVehicleDetails,
  updateVehicleDetails,
  createGroup,
  getGroupList,
  updateGroupDetails,
  deleteGroup,
  getOperatorList,
  getOperatorDetails,
  updateOperatorDetails,
  createOperator,
  createTank,
  getTankList,
  getTankDetails,
  updateTankDetails,
  createDispenser,
  getDispenserList,
  getDispenserDetails,
  updateDispenserDetails,
  createCard,
  getCardList,
  getCardDetails,
  updateCardDetails,
  createDriver,
  getDriverList,
  getDriverDetails,
  updateDriverDetails,
  createApprover,
  getApproverList,
  getApproverDetails,
  updateApproverDetails,
  createSiteManager,
  getSiteManagerList,
  getSiteManagerDetails,
  updateSiteManagerDetails,
  createTransaction,
  getTransactionList,
  updateTransactionDetails,
  getTransactionDetails,
  getDashboardCount,
  getDashboardSales,
  getAllVehicles,
  getAllOperators,
  getAllDrivers,
  getAllSites,
  getAllTanks,
  getAllCards,
  getAllRequestors,
  getAllApprovers,
  getAllSiteManagers,
  getAllDispensers,
  resetDispenserCards,
  getMilageData,
  getReconciliationData,
  changeCardLimit,
  getCardApprovalsList,
  approveRejectCardLimit,
  cardLimitApprovalsCount,
  cancelCardLimitRequest,
  getAllVehicleGroups,
  getAllDepartmentGroups,
  getVehicleFuelLevel,
  getTerminalWiseFuelDispensed,
  getTopFuelConsumption,
  getVehicleBreakDownList,
  createVehicleBreakDown,
  approveVehicleBreakDown,
  getWailonTransactions,
  getVehicleStatus,
  getVehicleUtilization,
  getVehicleSummary,
  getWailonVehicleList,
  getVehicleUtilizationReport,
  createShift,
  updateShift,
  getShiftDetails
};
