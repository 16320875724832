import React from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import GppBadIcon from '@mui/icons-material/GppBad';
import { useDispatch, useSelector } from 'react-redux';
import { clearModalInfo } from 'src/redux/modalSlice';

function SuccessModal() {
  const {
    showModal, success, successMsg, errorMsg
  } = useSelector((state) => state.modals);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(clearModalInfo());
  };

  return (
    <>
      {showModal && (
        <Dialog
          open={showModal}
          fullWidth
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogContent sx={{ textAlign: 'center' }}>
            {success
              ? <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
              : (<GppBadIcon color="error" sx={{ fontSize: 80 }} />)}
            <Typography variant="h4" color="secondary" sx={{ mb: 2 }}>
              {success ? 'Success!' : 'Error!'}
            </Typography>
            <DialogContentText>
              {successMsg || errorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default SuccessModal;
