import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import { SwitchInput } from 'src/common/FormElements';

export default function CounterConfigForm(props) {
  const {
    formField: {
      totalizerStartReading,
      densityStartReading,
      volumeDecimal,
      amountDecimal,
      unitPriceDecimal,
      backlight,
      emptyFillingAllowed,
    }
  } = props;
  return (

    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <InputField name={totalizerStartReading.name} label={totalizerStartReading.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={densityStartReading.name} label={densityStartReading.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={volumeDecimal.name} label={volumeDecimal.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={amountDecimal.name} label={amountDecimal.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={unitPriceDecimal.name} label={unitPriceDecimal.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={emptyFillingAllowed.name} label={emptyFillingAllowed.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SwitchInput name={backlight.name} label={backlight.label} fullWidth />
      </Grid>
    </Grid>
  );
}

CounterConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
