import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { CONFIRMATION_MSG, DATE_FORMAT, USER_ROLES } from 'src/utils/constants';
import InfoField, { GridRow } from 'src/common/InfoField';
import ROUTE from 'src/utils/routeConstants';
import { getFormattedDate, getFuelQuantity, isAdminUser } from 'src/utils/getInitials';
import { getCardDetails, updateCardDetails } from 'src/redux/cardSlice';
import useAuth from 'src/context/AuthContext';
import NewCard from './NewCard';
import { getFormattedCardBody } from './formModel/cardFormUtils';
import CardSyncStatusModal from './CardSyncStatusModal';

function CardDetails() {
  const { cardId } = useParams();
  const history = useHistory();
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isSupervisor = user?.role === USER_ROLES.siteManager;
  const { loading, cardDetails } = useSelector((state) => state.cards);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCardSyncModal, setShowCardSyncModal] = useState(false);

  useEffect(() => {
    dispatch(getCardDetails(cardId)).unwrap();
  }, []);

  const onUpdateCardDetails = () => setOpenModal(true);
  const onCardModalClose = () => setOpenModal(false);

  const onStatusChange = () => {
    if (isAdmin || isSupervisor) {
      setShowConfirmModal(true);
    }
  };

  const onStatusModalClose = () => setShowConfirmModal(false);

  const showSyncStatus = () => setShowCardSyncModal(true);
  const onCardSyncModalClose = () => setShowCardSyncModal(false);

  const updateStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const cardBody = getFormattedCardBody(cardDetails);
      const values = { ...cardBody, active: !cardBody.active };
      await dispatch(updateCardDetails(values)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Card status updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <Container maxWidth="lg">
      <NewCard
        open={openModal}
        initialValues={cardDetails}
        handleClose={onCardModalClose}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading || isLoading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Card Details"
                action={(
                  <Button variant="contained" onClick={() => history.replace(ROUTE.cards)}>
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField name="Card UID" value={cardDetails.uid} />
                  <InfoField name="Card Category" value={cardDetails.card_category} />
                  <InfoField name="Card Type" value={cardDetails.card_type} />
                </Grid>
                <GridRow>
                  <InfoField
                    name="Created On"
                    value={getFormattedDate(cardDetails.created_at)}
                  />
                  <InfoField
                    name="Last Updated On"
                    value={getFormattedDate(cardDetails.updated_at)}
                  />
                  <Box sx={{ width: '35%' }}>
                    <Typography color="textSecondary" variant="body1">
                      Status
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={cardDetails.active}
                            value={cardDetails.active}
                            color={cardDetails.active ? 'success' : 'default'}
                          />
                        )}
                        onChange={onStatusChange}
                        label={cardDetails.active ? 'Active' : 'Inactive'}
                      />
                    </FormGroup>
                  </Box>
                </GridRow>
                <GridRow>
                  <InfoField name="Fuel Type" value={cardDetails.fuel_type} />
                  <InfoField name="Wrong pin count" value={cardDetails.wrong_pin_count} />
                  <InfoField name="Linked Dispensers" value={cardDetails.dispenser_ids?.length || 0} />
                </GridRow>
                <GridRow>
                  <InfoField name="Fill limit type" value={cardDetails.fill_limit_type} />
                  <InfoField name="Per day limit" value={getFuelQuantity(cardDetails.per_day_limit)} />
                  <InfoField name="Per filling limit" value={getFuelQuantity(cardDetails.per_filling_limit)} />
                </GridRow>
                <GridRow>
                  <InfoField name="Limit quantity" value={getFuelQuantity(cardDetails.limit_quantity)} />
                  <InfoField name="Limit start date" value={getFormattedDate(cardDetails.limit_start_date, DATE_FORMAT.date)} />
                  <InfoField name="Limit end date" value={getFormattedDate(cardDetails.limit_end_date, DATE_FORMAT.date)} />
                </GridRow>
                <GridRow>
                  <Button sx={{ ml: -1 }} onClick={showSyncStatus}>
                    Card Sync Status
                  </Button>
                </GridRow>
              </CardContent>
              <CardActions>
                {(isAdmin || isSupervisor) && (
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={onUpdateCardDetails}
                >
                  Update Details
                </Button>
                )}
              </CardActions>
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        info={cardDetails.active ? CONFIRMATION_MSG.disableCard : CONFIRMATION_MSG.enableCard}
        open={showConfirmModal}
        disable={cardDetails.active}
        onClose={onStatusModalClose}
        onSubmit={updateStatus}
      />
      <CardSyncStatusModal
        open={showCardSyncModal}
        onClose={onCardSyncModalClose}
        data={cardDetails}
      />
    </Container>
  );
}

export default CardDetails;
