export const transactionTableHeaders = {
  inward: [
    { id: 'id', name: 'Order ID' },
    { id: 'order_date', name: 'Order Date' },
    { id: 'delivery_date', name: 'Delivery Date' },
    { id: 'site_name', name: 'Site Name' },
    { id: 'invoice_number', name: 'Invoice Number' },
    { id: 'quantity', name: 'Fuel Quantity' },
    { id: 'price_per_litre', name: 'Unit Price' },
    { id: 'supplier', name: 'Supplier' },
    { id: 'received_tank_name', name: 'Received Tank' },
    { id: 'action', disabled: true, name: 'Action' }
  ],
  iot: [
    { id: 'order_id', name: 'Order ID' },
    { id: 'end_time', name: 'Delivery Time' },
    { id: 'site_name', name: 'Site Name' },
    { id: 'address', name: 'Dispenser Address' },
    { id: 'nozzle_num', name: 'Nozzle Number' },
    { id: 'card_num_1', name: 'Vehicle Card' },
    { id: 'card_num_2', name: 'Operator Card' },
    { id: 'vehicle_number', name: 'Vehicle Number' },
    { id: 'quantity', name: 'Fuel Quantity' },
    { id: 'action', disable: true, name: 'Action' }
  ],
  transfer: [
    { id: 'order_id', name: 'Order ID' },
    { id: 'end_time', name: 'Delivery Time' },
    { id: 'site_name', name: 'Site Name' },
    { id: 'address', name: 'Dispenser Address' },
    { id: 'nozzle_num', name: 'Nozzle Number' },
    { id: 'card_num_1', name: 'Vehicle Card' },
    { id: 'card_num_2', name: 'Operator Card' },
    { id: 'vehicle_number', name: 'Vehicle Number' },
    { id: 'quantity', name: 'Fuel Quantity' },
    { id: 'action', disable: true, name: 'Action' }
  ]
};

export const siteTableHeaders = [
  { id: 'name', name: 'Site Name' },
  { id: 'code', name: 'Site Code' },
  { id: 'city', name: 'Location' },
  { id: 'dispensers_count', name: 'Total Dispensers' },
  { id: 'tanks_count', name: 'Total Tanks' },
  { id: 'vehicle_count', name: 'Total Vehicles' },
  { id: 'active', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const groupTableHeaders = [
  { id: 'id', name: 'Group ID' },
  { id: 'name', name: 'Group Name' },
  { id: 'type', name: 'Group Type', disable: true },
  { id: 'created_at', name: 'Created On' },
  { id: 'action', disable: true, name: 'Action' }
];

export const tankTableHeaders = [
  { id: 'name', name: 'Tank Name' },
  { id: 'type', name: 'Tank Type' },
  { id: 'current_balance', name: 'Current Volume' },
  { id: 'automation', name: 'Automaton Enabled' },
  { id: 'action', disable: true, name: 'Action' }
];

export const dispenserTableHeaders = [
  { id: 'station_name', name: 'Dispenser Name' },
  { id: 'address', name: 'Dispenser Address' },
  { id: 'site_name', name: 'Site Name' },
  { id: 'mode', name: 'Dispenser Mode' },
  { id: 'article', name: 'Fuel Type' },
  { id: 'status', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const cardTableHeaders = [
  { id: 'id', name: 'SL No' },
  { id: 'uid', name: 'Card UID' },
  { id: 'vehicle_number', name: 'Vehicle Number' },
  { id: 'card_type', name: 'Card Type' },
  { id: 'card_category', name: 'Card Category' },
  { id: 'fill_limit_type', name: 'Fill Limit Type' },
  { id: 'limit_quantity', name: 'Limit Quantity' },
  { id: 'active', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const vehicleTableHeaders = [
  { id: 'registration_num', name: 'Registration Number' },
  { id: 'model', name: 'Vehicle Model' },
  { id: 'vehicle_group', name: 'Vehicle Group' },
  { id: 'site_name', name: 'Site name' },
  { id: 'asset_code', name: 'Asset code' },
  { id: 'type', name: 'Milage', disable: true },
  { id: 'active', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const vehicleUtilizationTableHeaders = [
  { id: 'registration_num', name: 'Registration Number' },
  { id: 'utilisation', name: 'Utilized Hours' },
  { id: 'monthly_fixed_engine_hours', name: 'Monthly Limit' },
  { id: 'range', name: 'Utilized Percentage' },
];

export const vehicleIgnitionTableHeaders = [
  { id: 'registration_num', name: 'Registration Number' },
  { id: 'status', name: 'Vehicle Status' },
];

export const userTableHeaders = [
  { id: 'first_name', name: 'User Name' },
  { id: 'emp_id', name: 'Employee ID' },
  { id: 'mobile_num', name: 'Phone' },
  { id: 'city', name: 'Address' },
  { id: 'is_active', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const milageReportTableHeaders = [
  { id: 'order_id', name: 'Order ID' },
  { id: 'start_time', name: 'Date' },
  { id: 'site_name', name: 'Site Name' },
  { id: 'group_name', name: 'Vehicle Group' },
  { id: 'registration_num', name: 'Vehicle No' },
  { id: 'uid', name: 'UID' },
  { id: 'fuel_type', name: 'Fuel Type' },
  { id: 'odometer_entry', name: 'Odometer entry' },
  { id: 'driven', name: 'Driven' },
  { id: 'quantity', name: 'Quantity' },
  { id: 'mileage', name: 'Mileage' }
];

export const reconciliationReportTableHeaders = [
  { id: 'id', name: 'Order ID' },
  { id: 'station_name', name: 'Dispenser Name' },
  { id: 'site_name', name: 'SIte Name' },
  { id: 'date', name: 'Date' },
  { id: 'opening_value', name: 'Opening Stock' },
  { id: 'inward', name: 'Inward' },
  { id: 'transfer', name: 'Transfer' },
  { id: 'sales', name: 'Issued' },
  { id: 'stock', name: 'Closing Stock' },
];

export const cardApprovalTableHeaders = [
  { id: 'id', name: 'SL No' },
  { id: 'card_uid', name: 'Card Number' },
  { id: 'vehicle_registration_number', name: 'Vehicle Number' },
  { id: 'requested_quantity', name: 'Requested Limit' },
  { id: 'requested_on', name: 'Requested Date' },
  { id: 'approved_quantity', name: 'Approved Limit' },
  { id: 'approved_or_rejected_on', name: 'Approved Date' },
  { id: 'filled_quantity', name: 'Filled Limit' },
  { id: 'filled_on', name: 'Filled Date' },
  { id: 'remarks', name: 'Remarks' },
  { id: 'status', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' },
];

export const vehicleBreakDownTableHeaders = [
  { id: 'id', name: 'SL No' },
  { id: 'vehicle_number', name: 'Vehicle Number' },
  { id: 'type', name: 'Breakdown Type' },
  { id: 'location', name: 'Breakdown Location' },
  { id: 'start_time', name: 'Created On' },
  { id: 'expense', name: 'Repair Cost' },
  { id: 'duration', name: 'Breakdown Duration' },
  { id: 'description', name: 'Description', disable: true },
  { id: 'remarks', name: 'Remarks', disable: true },
  { id: 'status', name: 'Status' },
  { id: 'action', disable: true, name: 'Action' }
];

export const sensorTransactionsTableHeaders = [
  { id: 'id', name: 'Order ID' },
  { id: 'vehicle_reg_num', name: 'Vehicle Number' },
  { id: 'filled_time', name: 'Filled Time' },
  { id: 'location', name: 'Location' },
  { id: 'quantity', name: 'Fuel Quantity' },
];
