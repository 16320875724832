import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import Register from 'src/pages/Register';
import useAuth from './context/AuthContext';
import ROUTE from './utils/routeConstants';
import DashboardLayout from './components/DashboardLayout';

const PrivateRoute = ({ component: Component, path, ...rest }) => {
  const auth = useAuth();
  const activeSession = auth.isAuth();
  if (!activeSession) {
    return <Redirect to="/login" />;
  }

  return <Route component={Component} path={path} {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string
};

const AuthRoute = ({ component: Component, path, ...rest }) => {
  const auth = useAuth();
  const activeSession = auth.isAuth();
  if (activeSession) {
    return <Redirect to="/app/dashboard" />;
  }

  return <Route component={Component} path={path} {...rest} />;
};

AuthRoute.propTypes = {
  component: PropTypes.func.isRequired,
  path: PropTypes.string
};

const MainRoute = () => (
  <Switch>
    <Route exact path="/" render={() => <Redirect to="/login" />} />
    <AuthRoute path={ROUTE.login} component={Login} />
    <AuthRoute path={ROUTE.register} component={Register} />
    <PrivateRoute path={ROUTE.app} component={DashboardLayout} />
    <Route path="*" component={NotFound} />
  </Switch>
);

export default MainRoute;
