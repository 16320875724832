import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import TankListTable from 'src/components/tanks/TankListTable';
import FMSToolbar from 'src/common/FMSToolbar';
import { useState } from 'react';
import NewTank from 'src/components/tanks/NewTank';
import useAuth from 'src/context/AuthContext';
import { isAdminUser } from 'src/utils/getInitials';
import ScreenHeader from 'src/common/ScreenHeader';

const Tanks = ({ location }) => {
  const { getUser } = useAuth();
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');

  const handleSearch = (str) => setSearchText(str);

  return (
    <>
      <ScreenHeader title="Tanks" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Tank Name"
            actionName="Add Tank"
            hideAction={!isAdminUser(getUser)}
            onSearch={handleSearch}
            onAction={() => setOpenModal(true)}
          />
          <NewTank open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <TankListTable refreshData={location.state?.fromDashboard} searchText={searchText} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

Tanks.propTypes = {
  location: PropTypes.object
};

export default Tanks;
