/* eslint-disable consistent-return */
import {
  ADDITONAL_ENTRY_REGEX,
  CODE_REGEX,
  DATE_TYPE_ERROR_MSG,
  NUMBER_TYPE_ERROR_MSG,
  PIN_NUMBER_REGEX
} from 'src/utils/constants';
import { getMaxLimitErrorMsg } from 'src/utils/getInitials';
import * as Yup from 'yup';
import cardsFormModel from './cardsFormModel';

const {
  formField: {
    cardType,
    cardInterface,
    cardUID,
    dispenserId,
    cardCategory,
    perFillingLimit,
    perDayLimit,
    fillLimitType,
    limitQuantity,
    limitStartDate,
    limitEndDate,
    pinNumber,
    pinErrorCounter,
    additionInfo,
    vehicleRegNum
  }
} = cardsFormModel;

export default [
  Yup.object().shape({
    [cardType.name]: Yup.string().required(cardType.requiredErrorMsg),
    [cardInterface.name]: Yup.string().required(cardInterface.requiredErrorMsg),
    [cardCategory.name]: Yup.string().required(cardCategory.requiredErrorMsg),
    [dispenserId.name]: Yup.string().required(dispenserId.requiredErrorMsg),
    [cardUID.name]: Yup.string()
      .max(20, getMaxLimitErrorMsg(cardUID, 20))
      .matches(CODE_REGEX, cardUID.invalidErrorMsg)
      .required(cardUID.requiredErrorMsg)
  }),
  Yup.object().shape({
    [perFillingLimit.name]: Yup.number()
      .max(9999, getMaxLimitErrorMsg(perFillingLimit, 9999))
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .required(perFillingLimit.requiredErrorMsg),
    [perDayLimit.name]: Yup.number()
      .max(9999, getMaxLimitErrorMsg(perDayLimit, 9999))
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .required(perDayLimit.requiredErrorMsg),
    [limitQuantity.name]: Yup.number()
      .max(999, getMaxLimitErrorMsg(limitQuantity, 999))
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .required(limitQuantity.requiredErrorMsg),
    [limitStartDate.name]: Yup.date()
      .nullable()
      .typeError(DATE_TYPE_ERROR_MSG)
      .when(fillLimitType.name, (limitType, schema) => (limitType === 'Per Duration' ? schema.required(limitStartDate.requiredErrorMsg) : schema)),
    [limitEndDate.name]: Yup.date()
      .nullable()
      .typeError(DATE_TYPE_ERROR_MSG)
      .min(Yup.ref(limitStartDate.name), limitEndDate.invalidErrorMsg)
      .when(fillLimitType.name, (limitType, schema) => (limitType === 'Per Duration' ? schema.required(limitEndDate.requiredErrorMsg) : schema))
  }),
  Yup.object().shape({
    [pinNumber.name]: Yup.string()
      .matches(PIN_NUMBER_REGEX, pinNumber.invalidErrorMsg)
      .required(pinNumber.requiredErrorMsg),
    [pinErrorCounter.name]: Yup.number()
      .max(99)
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .required(pinErrorCounter.requiredErrorMsg),
    [additionInfo.name]: Yup.string().when(`${additionInfo.name}_check`, {
      is: true,
      then: Yup.string().matches(
        ADDITONAL_ENTRY_REGEX,
        additionInfo.invalidErrorMsg
      )
    }),
    [vehicleRegNum.name]: Yup.string().when(`${vehicleRegNum.name}_check`, {
      is: true,
      then: Yup.string().matches(
        ADDITONAL_ENTRY_REGEX,
        vehicleRegNum.invalidErrorMsg
      )
    })
  })
];
