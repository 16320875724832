import {
  Avatar,
  Box,
  Card,
  CardContent,
  Divider,
  Typography
} from '@mui/material';
import useAuth from 'src/context/AuthContext';
import { getStateName, getUserRole } from 'src/utils/getInitials';

const AccountProfile = (props) => {
  const { getUser } = useAuth();
  const user = getUser();

  return (
    <Card {...props}>
      <CardContent sx={{ my: 2 }}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Avatar
            sx={{
              height: 100,
              width: 100
            }}
          />
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h3"
            sx={{ mt: 1 }}
          >
            {`${user?.first_name} ${user?.last_name}`}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {getUserRole(user?.role)}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body1"
          >
            {`${user?.city}, ${getStateName(user?.state)}`}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
    </Card>
  );
};

export default AccountProfile;
