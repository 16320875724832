// Format multipleCheckBox values to string
// ex: ['0','2'] to "1010"
const formatValuesToString = (data, length = 4) => {
  const multiFieldData = ['0', '0', '0', '0', '0'];
  multiFieldData.length = length;
  data.forEach((item) => {
    const position = Number(item);
    multiFieldData[position] = '1';
  });
  return multiFieldData.join('');
};

// Format string to multipleCheckBox values
// ex: "1010" to ['0','2']
const formatStringToValues = (data) => {
  const res = data.split('');
  const updatedRes = [];
  res.forEach((el, i) => {
    if (el === '1') {
      updatedRes.push(String(i));
    }
  });
  return updatedRes;
};

export const getFormattedCardBody = (data) => {
  let reqBody = {
    ...data,
    manual_inputs: formatValuesToString(data.manual_inputs),
    product_table: formatValuesToString(data.product_table, 5)
  };
  if (data.fill_limit_type === 'Per Duration') {
    reqBody = {
      ...reqBody,
      limit_start_date: `${data.limit_start_date}T00:00:00`,
      limit_end_date: `${data.limit_end_date}T23:59:59`,
    };
  }
  return reqBody;
};

export const formatCardDetails = (data) => {
  const reqBody = {
    ...data,
    addition_info_check: data.addition_info > 0,
    vehicle_reg_num_check: data.vehicle_reg_num > 0,
    manual_inputs: formatStringToValues(data.manual_inputs),
    product_table: formatStringToValues(data.product_table)
  };
  return reqBody;
};
