import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showModal: false,
  success: false,
  error: false,
  successMsg: '',
  errorMsg: '',
  isSessionTimedOut: false
};

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setSuccessInfo: (state, action) => ({
      ...state, showModal: true, success: true, ...action.payload
    }),
    setErrorInfo: (state, action) => ({
      ...state, showModal: true, error: true, ...action.payload
    }),
    clearModalInfo: () => ({ ...initialState }),
    resetSession: (state, action) => ({ ...state, ...action.payload })
  },
});

// Action creators are generated for each case reducer function
export const {
  setSuccessInfo, setErrorInfo, clearModalInfo, resetSession
} = modalSlice.actions;

export default modalSlice.reducer;
