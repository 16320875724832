import {
  CODE_REGEX,
  ENTITY_NAME_REGEX,
  NAME_REGEX,
  NUMBER_TYPE_ERROR_MSG,
  VEHICLE_NUMBER_REGEX
} from 'src/utils/constants';
import {
  getMaxLengthErrorMsg,
  getMaxLimitErrorMsg,
  getRangeErrorValidation
} from 'src/utils/getInitials';
import * as Yup from 'yup';
import dispenserFormModel from './dispenserFormModel';

const {
  formField: {
    dispenserMode,
    serialNumber,
    stationName,
    vehicleNumber,
    site,
    tankId,
    updatedField,
    password1,
    password2,
    kfactor,
    articleUnit,
    articlePrice,
    article,
    pulseType,
    pulseDirection,
    throttleVolume,
    throttleStage,
    minFlowRate,
    maxFlowRate,
    maxFuelingTime,
    fuelBreakTime,
    fuelStartTime,
    odometerTimeout,
    addInputTimeout,
    pinEntryTimeout,
    nozzleTimeout,
    nozzleId,
    totalizerStartReading,
    densityStartReading,
    volumeDecimal,
    amountDecimal,
    unitPriceDecimal,
    emptyFillingAllowed,
    atgType,
    taxPercent,
    dateTime,
    alarms,
    shiftStartTime,
    shiftDuration,
    dispenserMount,
    dpNo,
    dpInterface,
    dpAddress,
    sequentialDIspenserID,
    kilometerDifference,
    onlineConnectionTimeout,
    maxQuantityPerTransaction
  }
} = dispenserFormModel;

export default [
  Yup.object().shape({
    [site.name]: Yup.string().required(site.requiredErrorMsg),
    [tankId.name]: Yup.string().required(tankId.requiredErrorMsg),
    [stationName.name]: Yup.string()
      .matches(ENTITY_NAME_REGEX, stationName.invalidErrorMsg)
      .required(stationName.requiredErrorMsg),
    [serialNumber.name]: Yup.string()
      .matches(CODE_REGEX, serialNumber.invalidErrorMsg)
      .required(serialNumber.requiredErrorMsg),
    [dispenserMode.name]: Yup.string().required(dispenserMode.requiredErrorMsg),
    [vehicleNumber.name]: Yup.string()
      .nullable()
      .max(50, getMaxLengthErrorMsg(vehicleNumber, 50))
      .matches(VEHICLE_NUMBER_REGEX, vehicleNumber.invalidErrorMsg),
    [updatedField.name]: Yup.string()
      .nullable()
      .matches(CODE_REGEX, updatedField.updatedField)
      .max(4, getMaxLengthErrorMsg(updatedField, 4)),
    [password1.name]: Yup.string()
      .nullable()
      .matches(CODE_REGEX, password1.invalidErrorMsg),
    [password2.name]: Yup.string()
      .nullable()
      .matches(CODE_REGEX, password2.invalidErrorMsg)
  }),
  Yup.object().shape({
    [dispenserMount.name]: Yup.string().required(
      dispenserMount.requiredErrorMsg
    ),
    [dpInterface.name]: Yup.string().required(dpInterface.requiredErrorMsg),
    [dpNo.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99, getMaxLimitErrorMsg(dpNo, 99))
      .required(dpNo.requiredErrorMsg),
    [dpAddress.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99, getMaxLimitErrorMsg(dpAddress, 99))
      .required(dpAddress.requiredErrorMsg),
    [sequentialDIspenserID.name]: getRangeErrorValidation(sequentialDIspenserID, 1, 99),
    [kilometerDifference.name]: getRangeErrorValidation(kilometerDifference, 1, 9999),
    [onlineConnectionTimeout.name]: getRangeErrorValidation(kilometerDifference, 10, 9999),
    [maxQuantityPerTransaction.name]: getRangeErrorValidation(kilometerDifference, 10, 65000)
  }),
  Yup.object().shape({
    [totalizerStartReading.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99999999999, getMaxLimitErrorMsg(totalizerStartReading, 99999999999))
      .required(totalizerStartReading.requiredErrorMsg),
    [densityStartReading.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(9999, getMaxLimitErrorMsg(densityStartReading, 9999))
      .required(densityStartReading.requiredErrorMsg),
    [volumeDecimal.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(3, getMaxLimitErrorMsg(volumeDecimal, 3))
      .required(volumeDecimal.requiredErrorMsg),
    [amountDecimal.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(3, getMaxLimitErrorMsg(amountDecimal, 3))
      .required(amountDecimal.requiredErrorMsg),
    [unitPriceDecimal.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(3, getMaxLimitErrorMsg(unitPriceDecimal, 3))
      .required(unitPriceDecimal.requiredErrorMsg),
    [emptyFillingAllowed.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99, getMaxLimitErrorMsg(emptyFillingAllowed, 99))
      .required(emptyFillingAllowed.requiredErrorMsg)
  }),
  Yup.object().shape({
    [article.name]: Yup.string().required(article.requiredErrorMsg),
    [articleUnit.name]: Yup.string()
      .matches(NAME_REGEX, articleUnit.invalidErrorMsg)
      .required(articleUnit.requiredErrorMsg),
    [articlePrice.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(articlePrice, 999))
      .required(articlePrice.requiredErrorMsg),
    [pulseType.name]: Yup.string().required(pulseType.requiredErrorMsg),
    [pulseDirection.name]: Yup.string().required(
      pulseDirection.requiredErrorMsg
    ),
    [throttleStage.name]: Yup.string().required(throttleStage.requiredErrorMsg),
    [throttleVolume.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(9999, getMaxLimitErrorMsg(throttleVolume, 9999))
      .required(throttleVolume.requiredErrorMsg),
    [minFlowRate.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(9999, getMaxLimitErrorMsg(minFlowRate, 9999))
      .required(minFlowRate.requiredErrorMsg),
    [maxFlowRate.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(9999, getMaxLimitErrorMsg(maxFlowRate, 9999))
      .required(maxFlowRate.requiredErrorMsg),
    [kfactor.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999999, getMaxLimitErrorMsg(kfactor, 999999))
      .required(kfactor.requiredErrorMsg)
  }),
  Yup.object().shape({
    [maxFuelingTime.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99999, getMaxLimitErrorMsg(maxFuelingTime, 99999))
      .required(maxFuelingTime.requiredErrorMsg),
    [fuelBreakTime.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(fuelBreakTime, 999))
      .required(fuelBreakTime.requiredErrorMsg),
    [fuelStartTime.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(fuelStartTime, 999))
      .required(fuelStartTime.requiredErrorMsg),
    [odometerTimeout.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(odometerTimeout, 999))
      .required(odometerTimeout.requiredErrorMsg),
    [addInputTimeout.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(addInputTimeout, 999))
      .required(addInputTimeout.requiredErrorMsg),
    [pinEntryTimeout.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(pinEntryTimeout, 999))
      .required(pinEntryTimeout.requiredErrorMsg),
    [nozzleTimeout.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(999, getMaxLimitErrorMsg(nozzleTimeout, 999))
      .required(nozzleTimeout.requiredErrorMsg),
    [nozzleId.name]: Yup.string()
      .matches(CODE_REGEX, nozzleId.invalidErrorMsg)
      .required(nozzleId.requiredErrorMsg)
  }),
  Yup.object().shape({
    [atgType.name]: Yup.string().required(atgType.requiredErrorMsg),
    [dateTime.name]: Yup.date().nullable(),
    [taxPercent.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(100, getMaxLimitErrorMsg(taxPercent, 100))
      .required(taxPercent.requiredErrorMsg),
    [alarms.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(99, getMaxLimitErrorMsg(alarms, 99))
      .required(alarms.requiredErrorMsg),
    [shiftStartTime.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(24, getMaxLimitErrorMsg(shiftStartTime, 24)),
    [shiftDuration.name]: Yup.number()
      .typeError(NUMBER_TYPE_ERROR_MSG)
      .max(24, getMaxLimitErrorMsg(shiftDuration, 24))
  })
];
