import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';

const initialState = {
  milageList: [],
  reconciliationList: [],
  otherReportList: [],
  totalCount: 0,
  loading: false
};

export const getMilageList = createAsyncThunk(
  'reports/milage',
  async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getMilageData(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreMilageList = createAsyncThunk(
  'reports/milage/loadmore',
  async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getMilageData(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getReconciliationList = createAsyncThunk(
  'reports/reconciliation',
  async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getReconciliationData(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreReconciliationList = createAsyncThunk(
  'reports/reconciliation/loadmore',
  async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getReconciliationData(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

const formatReport = (data) => {
  const updatedData = data.map((item) => {
    const updatedItem = {
      ...item,
      ...item.mileage_info,
      ...item.card_info
    };
    return updatedItem;
  });
  return updatedData;
};

export const reportSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    addOtherReportData: (state, action) => ({
      ...state, otherReportList: action.payload
    }),
    setShiftTimings: (state, action) => ({
      ...state, shiftTimings: action.payload
    }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getMilageList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        milageList: formatReport(action.payload.results)
      }))
      .addCase(getMilageList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getMilageList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreMilageList.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreMilageList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreMilageList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        milageList: formatReport(action.payload.results),
      }))
      .addCase(getReconciliationList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        reconciliationList: action.payload.results
      }))
      .addCase(getReconciliationList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getReconciliationList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreReconciliationList.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreReconciliationList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreReconciliationList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        reconciliationList: action.payload.results,
      }));
  }
});

// Action creators are generated for each case reducer function
export const {
  setShiftTimings,
  addOtherReportData
} = reportSlice.actions;

export default reportSlice.reducer;
