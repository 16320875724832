import { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import NewCardApproval from 'src/components/cards/approvals/NewCardApproval';
import CardApprovalsTable from 'src/components/cards/approvals/CardApprovalsTable';
import useAuth from 'src/context/AuthContext';
import ReportFilterModal from 'src/components/reports/ReportFilterModal';
import {
  limitRequestReportFormModel,
  limitRequestReportFormInitialValues,
  limitRequestReportValidationSchema
} from 'src/components/reports/limitRequestReportFormModal';
import DownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import DateFilterField from 'src/common/formFields/DateFilterField';
import API_URL from 'src/api/apiConstants';
import { DATE_FORMAT, USER_ROLES } from 'src/utils/constants';
import ScreenHeader from 'src/common/ScreenHeader';

const CardApprovals = ({ location }) => {
  const { getUser } = useAuth();
  const user = getUser();
  const isRequestor = user?.role === USER_ROLES.requestor;
  const [openModal, setOpenModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openReportModal, setOpenReportModal] = useState(false);
  const [filterDate, setFilterDate] = useState(null);
  const downloadUrl = `${API_URL.cardLimitHistory}?export=true`;
  const filterOptions = {
    card: true, vehicle: true, vehicleGroup: true, requestStatus: true
  };

  const handleSearch = (str) => setSearchText(str);

  const handleExport = () => setOpenReportModal(true);
  const onReportModalClose = () => setOpenReportModal(false);

  const filterBydate = (newValue) => {
    const dateString = newValue ? newValue.format(DATE_FORMAT.unixDate) : undefined;
    setFilterDate(dateString);
  };

  const clearDateFilter = () => setFilterDate(null);

  return (
    <>
      <ScreenHeader title="Card Approvals" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Card/Vehicle"
            actionName="Export"
            actionIcon={<DownloadIcon />}
            onAction={handleExport}
            onSearch={handleSearch}
          >
            <DateFilterField
              value={filterDate}
              onDateChange={filterBydate}
              onClearFilter={clearDateFilter}
            />
            {isRequestor && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setOpenModal(true)}
            >
              Request Card Limit
            </Button>
            )}
          </FMSToolbar>
          <ReportFilterModal
            open={openReportModal}
            title="Card Limit Requests Report"
            filterConfig={filterOptions}
            formModel={limitRequestReportFormModel}
            formValidation={limitRequestReportValidationSchema}
            formInitValues={limitRequestReportFormInitialValues}
            downloadUrl={downloadUrl}
            handleClose={onReportModalClose}
          />
          <NewCardApproval open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            <CardApprovalsTable
              refreshData={location.state?.fromDashboard}
              searchText={searchText}
              dateFilter={filterDate}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

CardApprovals.propTypes = {
  location: PropTypes.object
};

export default CardApprovals;
