import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import InputField from 'src/common/formFields/InputField';
import MultiCheckboxField from 'src/common/formFields/MultiCheckboxField';
import CheckboxField from 'src/common/formFields/CheckboxField';
import SelectField from 'src/common/formFields/SelectField';
import { NUMBER_LIST } from 'src/utils/constants';
import { useFormikContext } from 'formik';

const CheckboxInputField = ({ name, label }) => {
  const formikProps = useFormikContext();
  return (
    <Grid display="flex" flexDirection="row" sx={{ mb: 2 }}>
      <CheckboxField name={`${name}_check`} label={label} fullWidth />
      <SelectField
        name={name}
        disabled={!formikProps.values[`${name}_check`]}
        data={NUMBER_LIST}
        fullWidth
      />
    </Grid>
  );
};

CheckboxInputField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default function AdditionalConfigForm(props) {
  const formikProps = useFormikContext();
  const {
    formField: {
      productTable,
      pinNumber,
      pinErrorCounter,
      additionInfo,
      odometerInfo,
      vehicleRegNum,
      pinSelect
    }
  } = props;

  // reset values if checkbox is disabled
  useEffect(() => {
    if (!formikProps.values.addition_info_check) {
      formikProps.setFieldValue(additionInfo.name, '0');
    }
    if (!formikProps.values.vehicle_reg_num_check) {
      formikProps.setFieldValue(vehicleRegNum.name, '0');
    }
  }, [
    formikProps.values.addition_info_check,
    formikProps.values.vehicle_reg_num_check
  ]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <MultiCheckboxField
          name={productTable.name}
          label={productTable.label}
          data={productTable.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <CheckboxInputField
          name={additionInfo.name}
          label={additionInfo.label}
          value={formikProps.values.addition_info}
        />
        <CheckboxInputField
          name={vehicleRegNum.name}
          label={vehicleRegNum.label}
          value={formikProps.values.vehicle_reg_num}
        />
        <CheckboxField name={pinSelect.name} label={pinSelect.label} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectField
          name={odometerInfo.name}
          label={odometerInfo.label}
          data={odometerInfo.data}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField name={pinNumber.name} label={pinNumber.label} fullWidth />
      </Grid>
      <Grid item xs={12} sm={6}>
        <InputField
          name={pinErrorCounter.name}
          label={pinErrorCounter.label}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

AdditionalConfigForm.propTypes = {
  formField: PropTypes.object.isRequired
};
