import {
  ARTICLE_TYPE, CODE_REGEX, CONSUMER_VEHICLE_TYPES, ENTITY_NAME_REGEX,
  NUMBER_TYPE_ERROR_MSG, TELEMATICS_TYPES,
  VEHICLE_CATEGORY, VEHICLE_NUMBER_REGEX, VEHICLE_TYPE
} from 'src/utils/constants';
import { getMaxLengthErrorMsg, getMaxLimitErrorMsg } from 'src/utils/getInitials';
import * as Yup from 'yup';

// vehicle form model
export const vehicleFormModel = {
  formId: 'vehicleConfig',
  formField: {
    vehicleGroup: {
      name: 'group',
      label: 'Vehicle Group',
      requiredErrorMsg: 'Vehicle Group is required',
      type: 'select',
      data: []
    },
    site: {
      name: 'site',
      label: 'Select Site',
      requiredErrorMsg: 'Site is required',
      type: 'select',
      data: []
    },
    department: {
      name: 'department',
      label: 'Select Department',
      requiredErrorMsg: 'Department is required',
      type: 'select',
      data: []
    },
    vehicleCategory: {
      name: 'category',
      label: 'Vehicle Category',
      requiredErrorMsg: 'Vehicle Category is required',
      type: 'select',
      data: VEHICLE_CATEGORY
    },
    transferTank: {
      name: 'tank',
      label: 'Select Transfer Tank',
      requiredErrorMsg: 'Tank selection is required',
      type: 'select',
      data: []
    },
    registrationNum: {
      name: 'registration_num',
      label: 'Registration Number',
      requiredErrorMsg: 'Registration Number is required',
      invalidErrorMsg: 'Registration Number is Invalid',
      type: 'text',
    },
    vehicleType: {
      name: 'type',
      label: 'Vehicle Type',
      requiredErrorMsg: 'Vehicle Type is required',
      type: 'select',
      data: VEHICLE_TYPE
    },
    assetCode: {
      name: 'asset_code',
      label: 'Asset Code',
      requiredErrorMsg: 'Asset Code is required',
      invalidErrorMsg: 'Asset Code is invalid',
      type: 'text',
    },
    assetType: {
      name: 'consumer_vehicle',
      label: 'Asset Type',
      requiredErrorMsg: 'Asset Type is required',
      type: 'select',
      data: CONSUMER_VEHICLE_TYPES
    },
    tankCapacity: {
      name: 'tank_capacity',
      label: 'Tank Capacity',
      requiredErrorMsg: 'Tank Capacity is required',
      type: 'text',
    },
    telemeticsEnabled: {
      name: 'telemetics_enabled',
      label: 'Telematics Enabled',
      requiredErrorMsg: 'Telematics Enabled is required',
      type: 'switch',
    },
    telemeticsCategory: {
      name: 'telemetics_category',
      label: 'Telemetics Category',
      requiredErrorMsg: 'Telemetics Category is required',
      type: 'select',
      data: TELEMATICS_TYPES,
    },
    serviceInterval: {
      name: 'service_interval',
      label: 'Service Interval',
      requiredErrorMsg: 'Service Interval is required',
      type: 'date',
    },
    vehicleModel: {
      name: 'model',
      label: 'Vehicle Model',
      invalidErrorMsg: 'Invalid Vehicle Model name',
      type: 'text',
    },
    fuelType: {
      name: 'fuel_type',
      label: 'Fuel Type',
      requiredErrorMsg: 'Fuel Type is required',
      type: 'select',
      data: ARTICLE_TYPE
    },
    maxDiffAllowed: {
      name: 'max_diff_allowed',
      label: 'Max Diff Allowed',
      requiredErrorMsg: 'Max Diff Allowed is required',
      type: 'text',
    },
    odometerReading: {
      name: 'odometer_reading',
      label: 'Odometer Reading',
      requiredErrorMsg: 'Odometer Reading is required',
      type: 'text',
    },
    monthlyFixedEngineHours: {
      name: 'monthly_fixed_engine_hours',
      label: 'Monthly Fixed Engine Hours',
      requiredErrorMsg: 'Fixed Engine Hours is required',
      type: 'text',
    },
  }
};

// Initial values
const {
  formField: {
    vehicleGroup,
    site,
    department,
    vehicleCategory,
    transferTank,
    registrationNum,
    vehicleType,
    assetCode,
    assetType,
    tankCapacity,
    telemeticsEnabled,
    telemeticsCategory,
    serviceInterval,
    vehicleModel,
    fuelType,
    maxDiffAllowed,
    odometerReading,
    monthlyFixedEngineHours
  }
} = vehicleFormModel;

export const vehicleFormInitialValues = {
  [vehicleGroup.name]: '',
  [site.name]: '',
  [department.name]: '',
  [vehicleCategory.name]: '',
  [transferTank.name]: '',
  [registrationNum.name]: '',
  [vehicleType.name]: '',
  [assetCode.name]: '',
  [assetType.name]: '',
  [tankCapacity.name]: '',
  [telemeticsEnabled.name]: false,
  [telemeticsCategory.name]: '',
  [serviceInterval.name]: 0,
  [vehicleModel.name]: '',
  [fuelType.name]: '',
  [maxDiffAllowed.name]: 0,
  [monthlyFixedEngineHours.name]: 0,
  [odometerReading.name]: '',
};

// validation schema
export const vehicleValidationSchema = Yup.object().shape({
  [site.name]: Yup.string().required(`${site.requiredErrorMsg}`),
  [registrationNum.name]: Yup.string().max(50, getMaxLengthErrorMsg(registrationNum, 50)).matches(VEHICLE_NUMBER_REGEX, registrationNum.invalidErrorMsg)
    .required(registrationNum.requiredErrorMsg),
  [assetCode.name]: Yup.string().matches(CODE_REGEX, assetCode.invalidErrorMsg)
    .required(assetCode.requiredErrorMsg),
  [vehicleType.name]: Yup.string().required(vehicleType.requiredErrorMsg),
  [tankCapacity.name]: Yup.number().typeError(NUMBER_TYPE_ERROR_MSG).max(99999, getMaxLimitErrorMsg(tankCapacity, 99999))
    .required(tankCapacity.requiredErrorMsg),
  [fuelType.name]: Yup.string().required(fuelType.requiredErrorMsg),
  [odometerReading.name]: Yup.number().typeError(NUMBER_TYPE_ERROR_MSG).max(999999, getMaxLimitErrorMsg(odometerReading, 999999))
    .required(odometerReading.requiredErrorMsg),
  [maxDiffAllowed.name]: Yup.number().typeError(NUMBER_TYPE_ERROR_MSG).max(999, getMaxLimitErrorMsg(maxDiffAllowed, 999)),
  [serviceInterval.name]: Yup.number().typeError(NUMBER_TYPE_ERROR_MSG).max(99999, getMaxLimitErrorMsg(serviceInterval, 99999)),
  [vehicleModel.name]: Yup.string().nullable().matches(ENTITY_NAME_REGEX, vehicleModel.invalidErrorMsg),
  [telemeticsCategory.name]: Yup
    .string()
    .when(telemeticsEnabled.name, {
      is: true,
      then: Yup.string().required(telemeticsCategory.requiredErrorMsg)
    }),
  [transferTank.name]: Yup
    .string()
    .nullable()
    .when(vehicleCategory.name, (category, schema) => {
      if (category === 'TRNSFR') {
        return schema.required(transferTank.requiredErrorMsg);
      }
      return schema;
    }),
  [monthlyFixedEngineHours.name]: Yup.number().typeError(NUMBER_TYPE_ERROR_MSG).max(99999, getMaxLimitErrorMsg(maxDiffAllowed, 99999)),
});
