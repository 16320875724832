import {
  Container,
  Grid,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Box,
  CardActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'src/common/Loader';
import ConfirmationDialog from 'src/common/ConfirmationDialog';
import { setSuccessInfo, setErrorInfo } from 'src/redux/modalSlice';
import { CONFIRMATION_MSG, USER_ROLES } from 'src/utils/constants';
import InfoField, { GridRow } from 'src/common/InfoField';
import NewVehicle from 'src/components/vehicles/NewVehicle';
import { getVehicleDetails, updateVehicleDetails } from 'src/redux/vehicleSlice';
import ROUTE from 'src/utils/routeConstants';
import {
  getFormattedDate, getFuelQuantity, getMilageType, isAdminUser
} from 'src/utils/getInitials';
import useAuth from 'src/context/AuthContext';

function VehicleDetails() {
  const { vehicleId } = useParams();
  const history = useHistory();
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isSupervisor = user?.role === USER_ROLES.siteManager;
  const { loading, vehicleDetails } = useSelector((state) => state.vehicles);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getVehicleDetails(vehicleId)).unwrap();
  }, []);

  const handleUpdateVehicleDetails = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setShowConfirmModal(false);
  };
  const onStatusChange = () => {
    if (isAdmin || isSupervisor) {
      setShowConfirmModal(true);
    }
  };

  const updateStatus = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);
    try {
      const values = { ...vehicleDetails, active: !vehicleDetails.active };
      await dispatch(updateVehicleDetails(values)).unwrap();
      setIsLoading(false);
      dispatch(
        setSuccessInfo({
          successMsg: 'Vehicle status updated successfully!'
        })
      );
      // handle result here
    } catch (error) {
      console.log('error', error);
      // handle error here
      setIsLoading(false);
      dispatch(setErrorInfo({ errorMsg: error.error_message }));
    }
  };

  return (
    <Container maxWidth="lg">
      <NewVehicle
        open={openModal}
        initialValues={vehicleDetails}
        handleClose={() => setOpenModal(false)}
      />
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          {loading || isLoading ? (
            <Loader />
          ) : (
            <Card>
              <CardHeader
                title="Vehicle Details"
                action={(
                  <Button variant="contained" onClick={() => history.replace(ROUTE.vehicles)}>
                    <ArrowBackIosIcon fontSize="inherit" />
                    Back
                  </Button>
                )}
              />
              <Divider />
              <CardContent>
                <Grid sx={{ display: 'flex', flexDirection: 'row' }}>
                  <InfoField name="Registration Number" value={vehicleDetails.registration_num} />
                  <InfoField name="Vehicle Model" value={vehicleDetails.model} />
                  <InfoField
                    name="Asset Code"
                    value={vehicleDetails.asset_code}
                  />
                </Grid>
                <GridRow>
                  <InfoField
                    name="Created On"
                    value={getFormattedDate(vehicleDetails.created_at)}
                  />
                  <InfoField
                    name="Last Updated On"
                    value={getFormattedDate(vehicleDetails.updated_at)}
                  />
                  <Box sx={{ width: '35%' }}>
                    <Typography color="textSecondary" variant="body1">
                      Status
                    </Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={(
                          <Switch
                            checked={vehicleDetails.active}
                            value={vehicleDetails.active}
                            color={vehicleDetails.active ? 'success' : 'default'}
                          />
                        )}
                        onChange={onStatusChange}
                        label={vehicleDetails.active ? 'Active' : 'Inactive'}
                      />
                    </FormGroup>
                  </Box>
                </GridRow>
                <GridRow>
                  <InfoField name="Vehicle Category" value={vehicleDetails.category} />
                  <InfoField name="Vehicle Type" value={vehicleDetails.type} />
                  <InfoField name="Asset Type" value={vehicleDetails.consumer_vehicle} />
                </GridRow>
                <GridRow>
                  <InfoField name="Tank Capacity" value={getFuelQuantity(vehicleDetails.tank_capacity)} />
                  <InfoField name="Milage" value={getMilageType(vehicleDetails.type)} />
                  <InfoField name="Fuel Type" value={vehicleDetails.fuel_type} />
                </GridRow>
                <GridRow>
                  <InfoField name="Site Name" value={vehicleDetails.site_name} />
                  <InfoField name="Vehicle Group" value={vehicleDetails.group_name} />
                  <InfoField name="Department" value={vehicleDetails.department_name} />
                </GridRow>
                <GridRow>
                  <InfoField name="Odometer Reading" value={`${vehicleDetails.odometer_reading} Km`} />
                  <InfoField name="Monthly Fixed Engine Hours" value={vehicleDetails.monthly_fixed_engine_hours || 0} />
                  <InfoField
                    name="Telematics"
                    value={vehicleDetails.telemetics_enabled ? vehicleDetails.telemetics_category : 'Disbled'}
                  />
                </GridRow>
              </CardContent>
              <CardActions>
                {(isAdmin || isSupervisor) && (
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  color="secondary"
                  onClick={handleUpdateVehicleDetails}
                >
                  Update Details
                </Button>
                )}
              </CardActions>
              <Divider />
            </Card>
          )}
        </Grid>
      </Grid>
      <ConfirmationDialog
        info={vehicleDetails.active ? CONFIRMATION_MSG.disableVehicle : CONFIRMATION_MSG.enableVehicle}
        open={showConfirmModal}
        disable={vehicleDetails.active}
        onClose={onModalClose}
        onSubmit={updateStatus}
      />
    </Container>
  );
}

export default VehicleDetails;
