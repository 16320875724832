export const VEHICLE_LIST_TYPES = {
  utilization: 'utilization-report',
  ignition: 'running-status-report'
};

export const getVehicleUtilizationList = (vehicleData, index) => {
  const utilizationKeys = Object.keys(vehicleData);
  const dataKey = utilizationKeys[index];
  const vehicleList = vehicleData[dataKey];
  return { chartName: VEHICLE_LIST_TYPES.utilization, vehicleList };
};

export const getVehicleStatusList = (vehicleData, chartData) => {
  const { dataIndex, data } = chartData;
  const utilizationKeys = Object.keys(vehicleData);
  const dataKey = utilizationKeys[dataIndex];
  const vehicleList = vehicleData[dataKey];
  const formattedVehicleList = vehicleList.map((vehicle) => ({
    ...vehicle,
    status: data.name
  }));
  return { chartName: VEHICLE_LIST_TYPES.ignition, vehicleList: formattedVehicleList };
};
