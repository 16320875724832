import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APIService } from 'src/api/api';
import { updateItemInArray } from 'src/utils/getInitials';

const initialState = {
  allVehicles: [],
  allVehiclesRegList: [],
  totalCount: 0,
  vehicleList: [],
  wailonVehicleList: [],
  vehicleListBySite: [],
  vehicleRegNumListBySite: [],
  vehicleDetails: {},
  loading: false,
};

export const getVehicleList = createAsyncThunk(
  'vehicles/list', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getVehicleList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const loadMoreVehicles = createAsyncThunk(
  'vehicle/list/loadmore', async (req, { rejectWithValue }) => {
    const { limit, pageNo, params } = req;
    try {
      const res = await APIService.getVehicleList(limit, pageNo, params);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getAllVehicles = createAsyncThunk(
  'vehicle/all', async (_, { rejectWithValue }) => {
    try {
      const res = await APIService.getAllVehicles();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getVehicleDetails = createAsyncThunk(
  'vehicles/details', async (vehicleId, { rejectWithValue }) => {
    try {
      const res = await APIService.getVehicleDetails(vehicleId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const updateVehicleDetails = createAsyncThunk(
  'vehicles/updateDetails', async (data, { rejectWithValue }) => {
    try {
      const res = await APIService.updateVehicleDetails(data.id, data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const addNewVehicle = createAsyncThunk(
  'vehicles/create', async (data, { rejectWithValue },) => {
    try {
      const res = await APIService.createVehicle(data);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getVehicleFuelLevel = createAsyncThunk(
  'vehicles/fuel-level', async (vehicleId, { rejectWithValue },) => {
    try {
      const res = await APIService.getVehicleFuelLevel(vehicleId);
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

export const getWailonVehicleList = createAsyncThunk(
  'vehicles/wailon-vehicle-list', async (req, { rejectWithValue }) => {
    try {
      const res = await APIService.getWailonVehicleList();
      if (!res.success) throw res;
      return res.result;
    } catch (error) {
      return rejectWithValue(error.data);
    }
  }
);

// format data for dropdown list
const formatVehicleData = (data) => {
  const formattedList = data.map((vehicle) => ({ ...vehicle, name: vehicle.registration_num }));
  return formattedList;
};

// format data for dropdown list
const formatVehicleRegData = (data) => {
  const formattedList = data.map((vehicle) => ({ ...vehicle, id: vehicle.registration_num, name: vehicle.registration_num }));
  return formattedList;
};

export const vehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    filterVehiclesBySite: (state, action) => ({
      ...state,
      vehicleListBySite: action.payload === 'all' ? state.allVehicles
        : state.allVehicles.filter((vehicle) => vehicle.site === action.payload),
      vehicleRegNumListBySite: action.payload === 'all' ? state.allVehiclesRegList
        : state.allVehiclesRegList.filter((vehicle) => vehicle.site === action.payload)
    }),
  },
  extraReducers: (builder) => {
    // add your async reducers here
    builder
      .addCase(getAllVehicles.fulfilled, (state, action) => ({
        ...state,
        allVehicles: formatVehicleData(action.payload),
        vehicleListBySite: formatVehicleData(action.payload),
        allVehiclesRegList: formatVehicleRegData(action.payload),
        vehicleRegNumListBySite: formatVehicleRegData(action.payload),
      }))
      .addCase(getVehicleList.pending, (state) => ({ ...state, loading: true }))
      .addCase(getVehicleList.rejected, (state) => ({ ...state, loading: false }))
      .addCase(getVehicleList.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        vehicleList: formatVehicleData(action.payload.results),
      }))
      .addCase(loadMoreVehicles.pending, (state) => ({ ...state, loading: true }))
      .addCase(loadMoreVehicles.rejected, (state) => ({ ...state, loading: false }))
      .addCase(loadMoreVehicles.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        totalCount: action.payload.count,
        vehicleList: formatVehicleData(action.payload.results),
      }))
      .addCase(getVehicleDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        vehicleDetails: action.payload
      }))
      .addCase(getVehicleDetails.pending, (state) => ({ ...state, loading: true }))
      .addCase(getVehicleDetails.rejected, (state) => ({ ...state, loading: false }))
      .addCase(updateVehicleDetails.fulfilled, (state, action) => ({
        ...state,
        loading: false,
        vehicleDetails: action.payload,
        vehicleList: updateItemInArray(state.vehicleList, action.payload)
      }))
      .addCase(addNewVehicle.fulfilled, (state, action) => {
        state.vehicleList.push(action.payload);
      })
      .addCase(getWailonVehicleList.fulfilled, (state, action) => ({
        ...state,
        wailonVehicleList: action.payload,
      }));
  }
});

// Action creators are generated for each case reducer function
export const { filterVehiclesBySite } = vehicleSlice.actions;

export default vehicleSlice.reducer;
