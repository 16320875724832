import React, { useEffect, useState } from 'react';
import {
  Button, Box, Dialog, DialogActions, DialogContent, DialogContentText
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReportIcon from '@mui/icons-material/Report';
import { resetSession } from 'src/redux/modalSlice';
import useAuth from 'src/context/AuthContext';

function SessionTimeoutModal() {
  const auth = useAuth();
  const { isSessionTimedOut } = useSelector((state) => state.modals);
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const activeSession = auth.isAuth();
    if (isSessionTimedOut) {
      if (activeSession) {
        setShowModal(true);
      }
      auth.signout();
    }
  }, [isSessionTimedOut]);

  const clearSession = () => {
    dispatch(resetSession({ isSessionTimedOut: false }));
    setShowModal(false);
  };

  return (
    <Dialog
      open={showModal}
      fullWidth
      onClose={clearSession}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogContent sx={{ textAlign: 'center' }}>
        <ReportIcon sx={{ fontSize: 80 }} color="warning" />
        <Box sx={{ mb: 2, fontSize: 20, fontWeight: 500 }}>Session Exprired</Box>
        <DialogContentText>
          You session has been expried. Please signin again to continue!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={clearSession}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SessionTimeoutModal;
