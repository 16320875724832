import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { useEffect, useState } from 'react';
import Loader from 'src/common/Loader';
import { useDispatch, useSelector } from 'react-redux';
import GroupList from 'src/components/groups/GroupList';
import { getGroupList, setGroup } from 'src/redux/groupSlice';
import NewGroup from 'src/components/groups/NewGroup';
import { GROUP_TYPES } from 'src/utils/constants';
import ScreenHeader from 'src/common/ScreenHeader';

const Groups = ({ location }) => {
  const dispatch = useDispatch();
  const { groupList, groupType, loading } = useSelector((state) => state.groups);
  const [openModal, setOpenModal] = useState(false);

  const handleSearch = (name) => {
    dispatch(getGroupList({ groupType, params: { name } }));
  };

  useEffect(() => {
    if (groupList.length === 0 || location.state?.fromDashboard) {
      dispatch(getGroupList({ groupType }));
    }
  }, [groupType]);

  const handleGroupChange = (value) => dispatch(setGroup(value));

  return (
    <>
      <ScreenHeader title="Groups" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Group"
            actionName="Add Group"
            showGroup
            groupData={GROUP_TYPES}
            groupType={groupType}
            groupLabel="Vehicle Category"
            onGroupChange={handleGroupChange}
            onSearch={handleSearch}
            onAction={() => setOpenModal(true)}
          />
          <NewGroup open={openModal} handleClose={() => setOpenModal(false)} />
          <Box sx={{ pt: 3 }}>
            {loading ? (
              <Loader />
            ) : (<GroupList groups={groupList} />)}
          </Box>
        </Container>
      </Box>
    </>
  );
};

Groups.propTypes = {
  location: PropTypes.object
};

export default Groups;
