import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';
import FMSToolbar from 'src/common/FMSToolbar';
import { useState } from 'react';
import { useLocation } from 'react-router';
import ScreenHeader from 'src/common/ScreenHeader';
import VehicleStatusListTable from 'src/components/vehicles/VehicleStatusListTable';

const VehicleStatusList = ({ location }) => {
  const [searchText, setSearchText] = useState('');
  const navData = useLocation();
  const handleSearch = (str) => setSearchText(str);

  return (
    <>
      <ScreenHeader title="Vehicle List" />
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <FMSToolbar
            placeholder="Search Vehicle Number"
            hideAction
            onSearch={handleSearch}
          />
          <Box sx={{ pt: 3 }}>
            <VehicleStatusListTable
              vehicleData={navData.state?.vehicleList || []}
              chartName={navData.state?.chartName || []}
              refreshData={location.state?.fromDashboard}
              searchText={searchText}
            />
          </Box>
        </Container>
      </Box>
    </>
  );
};

VehicleStatusList.propTypes = {
  location: PropTypes.object
};

export default VehicleStatusList;
