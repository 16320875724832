import { CONSUMER_VEHICLE_TYPES } from 'src/utils/constants';
import * as Yup from 'yup';

// report form model
export const vehicleUtilizationReportFormModel = {
  formId: 'vehicleUtilizationReportConfig',
  formField: {
    assetType: {
      name: 'asset_type',
      label: 'Select Asset Type',
      type: 'select',
      data: [{ id: 'all', name: 'All' }, ...CONSUMER_VEHICLE_TYPES]
    },
    startTime: {
      name: 'start_date',
      label: 'Start Date',
      type: 'date',
      errorMsg: 'Start time is required!'
    }
  }
};

// Initial values
const {
  formField: {
    assetType,
    startTime,
  }
} = vehicleUtilizationReportFormModel;

export const vehicleUtilizationReportFormInitialValues = {
  [assetType.name]: 'all',
  [startTime.name]: '',
};

// validation schema
export const vehicleUtilizationReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
});

export const vehicleUtilizationReportFormConfig = {
  formModel: vehicleUtilizationReportFormModel,
  initialValues: vehicleUtilizationReportFormInitialValues,
  validation: vehicleUtilizationReportValidationSchema,
};
