import * as Yup from 'yup';

// report form model
export const inwardReportFormModel = {
  formId: 'inwardReportConfig',
  formField: {
    site: {
      name: 'site',
      label: 'Select Site',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    tank: {
      name: 'received_tank',
      label: 'Select Tank',
      type: 'select',
      data: [{ id: 'all', name: 'All' }]
    },
    startTime: {
      name: 'order_date__gte',
      label: 'Start Date',
      type: 'date'
    },
    endTime: {
      name: 'order_date__lte',
      label: 'End Date',
      type: 'date'
    }
  }
};

// Initial values
const {
  formField: {
    site,
    tank,
    startTime,
    endTime
  }
} = inwardReportFormModel;

export const inwardReportFormInitialValues = {
  [site.name]: 'all',
  [tank.name]: 'all',
  [startTime.name]: '',
  [endTime.name]: '',
};

// validation schema
export const inwardReportValidationSchema = Yup.object().shape({
  [startTime.name]: Yup.date().nullable().required(startTime.errorMsg),
  [endTime.name]: Yup.date().nullable().required(endTime.errorMsg)
});
