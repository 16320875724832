import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const InfoField = ({ name, value }) => (
  <Box sx={{ width: '30%' }}>
    <Typography color="textSecondary" variant="body1">
      {name}
    </Typography>
    <Typography variant="subtitle1">
      {value || typeof value === 'number' ? value : 'NA'}
    </Typography>
  </Box>
);

InfoField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InfoField;

export const GridRow = ({ children, style }) => (
  <Grid sx={{
    display: 'flex', flexDirection: 'row', mt: 4, ...style
  }}
  >
    {children}
  </Grid>
);

GridRow.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.object])
};

export const InfoText = ({ name, value, ...rest }) => (
  <Typography color="textSecondary" variant="body1" {...rest}>
    {name}
    :&nbsp;&nbsp;
    <strong>{value}</strong>
  </Typography>
);

InfoText.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
