import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  List,
  Typography,
  useMediaQuery
} from '@mui/material';
import useAuth from 'src/context/AuthContext';
import { getAppRoutes } from 'src/utils/routeConstants';
import { USER_ROLES } from 'src/utils/constants';
import { useDispatch } from 'react-redux';
import { getApprovalsCount } from 'src/redux/cardApprovalSlice';
import { isAdminUser } from 'src/utils/getInitials';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import theme from 'src/theme';
import NavItem from './NavItem';
import { SIDE_NAV_WIDTH } from './DashboardNavbar';

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { getUser } = useAuth();
  const user = getUser();
  const isAdmin = isAdminUser(getUser);
  const isApprover = user?.role === USER_ROLES.approver;
  const lgUp = useMediaQuery((th) => th.breakpoints.up('lg'));

  const appRoutes = getAppRoutes(user);

  useEffect(() => {
    if ((isAdmin || isApprover) && !!location.state?.fromDashboard) {
      dispatch(getApprovalsCount());
    }
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          px: 2,
          py: 3
        }}
      >
        <AdminPanelSettingsIcon sx={{ fontSize: 40, pr: 1 }} />
        <Typography variant="h4" sx={{ color: '#f5f5f5', fontWeight: 800 }}>
          CALLCOMM FMS
        </Typography>
      </Box>
      <Divider sx={{ borderColor: 'gray' }} />
      <Box sx={{ p: 2 }}>
        <List>
          {appRoutes.map((item) => <NavItem key={item.title} item={item} />)}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  const sidebarStyle = {
    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem',
    background: 'linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25))',
    transform: 'translateX(0px)',
    transition: 'transform 200ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    color: theme.palette.common.white,
    width: SIDE_NAV_WIDTH
  };

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{ sx: sidebarStyle }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{ sx: sidebarStyle }}
      sx={{ zIndex: (th) => th.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default DashboardSidebar;
